// import ethiopianDate from 'ethiopian-date'
import { baseURL, ETHIOPIAN_MONTHS, TOKEN_KEY } from './constants'
import axios from 'axios'
import en from './assets/lang/en.json'
import am from './assets/lang/am.json'

export function convertEG(date, toGregorian=false){
    if(toGregorian){
        // gDate similar to [g_year, g_monthm, g_date]
        let gDate = require('ethiopian-date').toGregorian(date)    
        return gDate
    }
    let gdate = date.getDate(), month = date.getMonth() + 1, year = date.getFullYear()
    let eDate = require('ethiopian-date').toEthiopian(year, month, gdate)
    // eDate similar to [e_year, e_monthm, e_date]
    return eDate
}

export const api = axios.create({
    baseURL
})

api.interceptors.request.use(config => {
    let token = sessionStorage.getItem(TOKEN_KEY)
    if(token) {
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    response => response,
    error => {
        if(error.response && error.response.status == 403){
            sessionStorage.removeItem(TOKEN_KEY)
            window.location.href = '/'
        }
        return Promise.reject(error)
    }
)

export const getLang = (lang, word) => {
    const langs = {en, am}
    return langs[lang][word]
}

export const getByPage = (url, page, and=false) => url + `${and ? '&' : '?'}page=${page}`