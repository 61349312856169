import { useContext, useEffect, useState } from "react"
import { MAIN_COLOR, path } from "../constants"
import { appContext } from "../Context/appContext"
import { api } from "../helpers"
import { convertTo12HourFormat } from "../Pages/Events"
import Loader from "./Loader"

export default function EventEmployees(){
    const {showSelectEmployees, setShowSelectEmployees, toggleSEmployees, resetSIEmployees, addRmvSEmployee, showMessage} = useContext(appContext)
    const [ employees, setEmployees ] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchEmployees = () => {
        setLoading(true)
        api.get(path.employee).then(response => {
            const data = response.data
            if(data.success){
                setEmployees(data.employees)
                // setShowSelectEmployees({...showSelectEmployees, employees: showSelectEmployees.data?.employees})
            } else showMessage('Something went wrong', true)
        }).catch(e => showMessage('Something went wrong', true))
        .finally(() => setLoading(false))
    }

    const assignEmployees = () => {
        if(!showSelectEmployees.employees?.length && !showSelectEmployees.hadData) return setShowSelectEmployees({})
        setLoading(true)
        api.post(path.assignEmployee, {
            employees: showSelectEmployees.employees,
            eventId: showSelectEmployees.data?.id
        }).then(response => {
            const data = response.data
            if(data.success){
                showMessage('Data Saved!')
            } else showMessage('Something went wrong!', true)
        }).catch(e => showMessage('Something went wrong! ' + e, true))
        .finally(() => {
            setLoading(false)
            setShowSelectEmployees({})
        })
    }

    useEffect(() => {
        if(showSelectEmployees.visible) fetchEmployees()
    }, [showSelectEmployees.visible])

    if(!showSelectEmployees.visible) return null
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000090]'>
            <div className='flex flex-col gap-4 p-4 bg-white p-6 rounded-md'>
                <h2 className="self-center text-[25px] font-bold ">{`Assign Employees to Event | ${showSelectEmployees.data?.et_date} | ${convertTo12HourFormat(showSelectEmployees.data?.time)}`}</h2>
                <div className='grid  grid-cols-3 gap-6 feel-c1 bg-[#00000010] p-4'>
                    {employees.map(emp => (
                        <div className='flex items-center gap-2'>
                            <input checked={showSelectEmployees.employees?.some(s => s == emp.id)} onChange={addRmvSEmployee} value={emp.id} id={'item' + emp.id} type='checkbox' />
                            <label for={'item'+emp.id} className='text-[16px] select-none'>{emp.full_name} | {emp.phone_number}</label>
                        </div>
                    ))}
                </div>
                <Loader text={null} color={MAIN_COLOR} style={{alignSelf: 'center'}} loading={loading} />
                <div className='flex justify-center gap-6'>
                    <button onClick={assignEmployees} className='py-2 px-8 font-bold text-[20px] bg-main text-white  self-center' type='submit'>
                    <Loader text={`Assign (${showSelectEmployees.employees?.length || 0}) Employees`} loading={loading} />
                    </button>
                    <button onClick={resetSIEmployees} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Reset</button>
                    <button onClick={() => setShowSelectEmployees({})} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Cancel</button>
                </div>
            </div>
    </div>
    )
}