import { useContext, useEffect, useRef, useState } from "react"
import { appContext } from "../Context/appContext"
import {Camera, Repeat, Upload, File} from 'react-feather'
import { api } from "../helpers"
import { MAIN_COLOR, path } from "../constants"
import Loader from "./Loader"

export default function ImageTaker(){
    const {imageControl, setImageControl, showMessage, isMobileDevice} = useContext(appContext)
    const [image, setImage] = useState(null)
    const [canvaContext, setCanvaContext] = useState(null)
    const [stream, setStream] = useState(null)
    const [playing, setPlaying] = useState(false)
    const [uploading, setUploading] = useState(0)
    const canvaRef = useRef()
    const fileRef = useRef()
    const vidRef = useRef()
    useEffect(() => {
        if(!stream){
            navigator.mediaDevices.getUserMedia({ video: isMobileDevice() ? {facingMode: { exact: "environment" }} : true })
            .then((stream) => {
                setStream(stream)
                    vidRef.current.srcObject = stream;
            })
            .catch((error) => {
                alert("Error accessing camera: " +error);
            });
        }
        let context = canvaRef.current?.getContext('2d');
        setCanvaContext(context)
        return () => {
            if(stream) {
                stream.getTracks().forEach(track => track.stop())
            }
        }

    }, [stream])

    const takeImage = () => {
        setImage(true)
        setPlaying(true)
        canvaRef.current.width = 500;
        canvaRef.current.height = 400
        canvaContext.drawImage(vidRef.current, 0, 0, canvaRef.current?.width, canvaRef.current?.height);
    }

    const promptFOpener = () => {
        fileRef.current?.click()
    }

    const retakeImake = () => {
        setImage(false)
    }

    const useImage = () => {
        canvaRef.current.toBlob(async blob => {
            let form = new FormData()
            form.append('image', blob)
            let response = await api.post(path.uploadFile, form, {
                onUploadProgress: (e) => {
                    const percentCompleted = Math.round((e.loaded * 100) / e.total);
                    setUploading(percentCompleted)
                }
            })
            if(!response.data.success) return showMessage('Error occured while uploading file', true)
            showMessage('Image uploaded!')
            imageControl?.setImage({path: response.data.path, blob})
            setImageControl({visible: false})
            setUploading(0)
        })
        // stream.getTracks().forEach(track => track.stop())
    }

    const onChange = e => {
        const file = e.target.files[0]
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    canvaRef.current.width = img.width;
                    canvaRef.current.height = img.height;
                    canvaContext.drawImage(img, 0, 0);
                };
                setImage(true)
                img.src = e.target.result;
            };
            reader.readAsDataURL(file)
        }
        setPlaying(true)
    }

    return (
        <div className='fixed inset-0 bg-[#00000090] flex items-center justify-center z-[99999]'>
            <div className='bg-white rounded-md w-4/5 h-4/5 flex max-md:flex-col p-4'>
                <div className='flex-[2] flex'>
                    {!playing && <div className='flex-1 flex justify-center items-center flex-col'>
                        <p className='no-wrap'>Camera is Loading</p>
                        <Loader color={MAIN_COLOR} loading={true} />
                    </div> }
                    <div className={`${image ? 'block' : 'hidden'} w-[500px] h-[400px] max-md:h-[270px] border-[4px] p-2 border-main relative`}>
                        {!!uploading && <div className='absolute inset-0 flex bg-[#00000090] justify-center items-center flex-col gap-4'>
                            <p className={'text-white'}>Uploading... ({uploading}%)</p>
                            <Loader color={MAIN_COLOR} loading={true} />
                        </div>}
                        <canvas ref={canvaRef} className={`w-full h-full  object-contain`}></canvas>
                    </div>
                    <video onPlay={() => setPlaying(true)} className='flex-1' ref={vidRef} className={`${image ? 'hidden' : 'block'} ${!playing && 'h-0'}`} autoPlay playsInline></video>
                </div>
                <div className='flex-1 flex flex-col justify-center items-center gap-4 max-md:gap-2'>
                    <input onChange={onChange} type='file' className='h-0 opacity-0' ref={fileRef} />
                    <button onClick={image ? retakeImake : takeImage} className='bg-main px-6 py-2 rounded-sm border-none text-white flex items-center gap-2 font-bold w-[200px] justify-center max-md:text-[13px]'>
                        {image ? <Repeat color='#ffffff' size={17} /> : <Camera color='#ffffff' size={17} />}
                        {image ? 'Retake Image' : 'Take Picture'}
                    </button>
                    {image && <button onClick={useImage} className='bg-black px-6 py-2 rounded-sm border-none text-white flex items-center gap-2 font-bold w-[200px] justify-center max-md:text-[13px]'>
                        <File color='#ffffff' size={17} />
                        Use Image
                    </button> }
                    <button onClick={promptFOpener} className='bg-main px-6 py-2 rounded-sm border-none text-white flex items-center gap-2 font-bold w-[200px] justify-center max-md:text-[13px]'>
                        <Upload color='#ffffff' size={17} />
                        Upload Image
                    </button>
                    <button onClick={() => setImageControl({visible: false})} className='bg-main px-6 py-2 rounded-sm border-none text-white flex items-center gap-2 font-bold w-[200px] justify-center max-md:text-[13px]'>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}