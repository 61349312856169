import { Link } from "react-router-dom";
import {Check, X, Filter, Trash, ArrowUpCircle, ArrowDownCircle, Eye, EyeOff} from 'react-feather'
import { useContext, useEffect, useRef, useState } from "react";
import { appContext } from "../Context/appContext";
import { api, convertEG, getByPage } from "../helpers"
import Loader from "../Components/Loader"
import { access, baseURL, MAIN_COLOR, path } from "../constants"
import {
    TransitionGroup,
    CSSTransition
  } from "react-transition-group";
import date from 'date-and-time';
import ETDatePicker from "../Components/ETCalendar/ETDatePicker";
import { customStyles, getEtDate } from "../Components/ScheduleEvent";
import PettyCash from "../Components/PettyCash";
import { authContext } from "../Context/authContext";
import Select from 'react-select'
import InfiniteScroll from 'react-infinite-scroll-component';

export default function CashBook(){
    const [loading, setLoading] = useState(false)
    // const [fetching, setFetching] = useState(true)
    const {showMessage, settings, loadingSettings, setSettings, setConfirm} = useContext(appContext)
    const {isAllowed, user} = useContext(authContext)
    const tabs = []
    isAllowed(access.CASHBOOK_VIEW_RECORDS) && tabs.push('book')
    isAllowed(access.APPROVE_PENDING_TRANSACTIONS) && tabs.push('pending')
    isAllowed(access.SETTLE_ADVANCE_PAYMENT) && tabs.push('advance')
    const [selectedTab, setSelectedTab] = useState(!tabs.length ? 'pettycash' : tabs[0])
    const [transactions, setTransactions] = useState({
        pending: [], book: [], advance: [], pettycash: []
    })
    const [status, setStatus] = useState({balance: 0, outstanding: 0})
    const [showFilter, setShowFilter] = useState(false)
    const [txnBox, setTxnBox] = useState({})
    const [remark, setRemark] = useState(null)
    const [branches, setBranches] = useState([])
    const [branch, setBranch] = useState(null)
    const [showBalance, setShowBalance] = useState(false)
    const [page, setPage] = useState({pending: 0, book: 0, advance: 0, pettycash: 0})
    const [hasMore, setHasMore] = useState({pending: true, book: true, advance: true, pettycash: true})
    const brRef = useRef()
    const scrollRef = useRef()
    // const [settings, setSettings] = useState({})

    const fetchTransactions = (type, brChanged) => {
        if(!type) return
        const limit = 20
        // setLoading(true)
        switch(type){
            case 'book':
            case 'pending':
            case 'advance':
                api.get(getByPage(path.transactions + '?type=' + type + '&branchId=' + branch?.value, brChanged ? 1 : page[type], true)).then(response => {
                    if(!response.data.success) return showMessage('Something went wrong', true)
                    brChanged ? setPage({pending: 2, book: 2, advance: 2, pettycash: 2}) : setPage(p => ({...p, [type]: p[type] + 1}))
                    brChanged ? setTransactions(tr => ({...tr, [type]: response.data.data})) : setTransactions(tr => ({...tr, [type]: [...tr[type], ...response.data.data]}))
                    setHasMore(hm => ({...hm, [type]: !(response.data.data?.length < limit) }))
                    setStatus(response.data.status)
                    setLoading(false)
                })
        }
    }

    const getRemark = (txn) => {
        switch(selectedTab){
            case 'book':
                if(txn.advancetransact?.pendingtransact?.event){
                    return `${txn.record_type} for Event - ${txn.advancetransact?.pendingtransact.event?.client?.full_name || 'Unkown'} on ${txn.advancetransact?.pendingtransact?.event?.et_date} E.C`
                } else return `${txn.remark || ''}`
            case 'pending':
                return `${txn.type} for Event - ${txn.event?.client?.full_name || 'Unkown'} on ${txn.event?.et_date} E.C`
            case 'advance':
                if(txn.pendingtransact?.event){
                    return `advance for Event - ${txn.pendingtransact.event?.client?.full_name || 'Unkown'} on ${txn.pendingtransact?.event?.et_date} E.C`
                } else return `${txn.remark || ''}`

        }
    }
    const dateOptions = { month: 'short', day: '2-digit', year: 'numeric' };
    const rejectPending = (id) => {
        return function(){
            return api.patch(path.transactions, {
                action: 'reject',
                actionId: id,
                type: 'pending'
            }).then(r => {
                fetchTransactions('pending', true)
                showMessage('Transaction rejected', false)
            }).catch(e => showMessage('Something went wrong', true))
            .finally(() => setLoading(false))
        }
    }

    const fetchBranches = () => {
        api.get(path.branch).then(response => {
            let data = response.data
            if(data.success){
                let brOptions = data.branches.map(b => ({label: b.name, value: b.id}))
                setBranches(brOptions)
                setBranch(brOptions[0])
            }
        })
    }


    useEffect(() => {
        if(!branch) fetchBranches()
        if(user.role == 'admin' && !branch?.value) return
        tabs.forEach(type => fetchTransactions(type, true))
    }, [branch])

    useEffect(() => {
        scrollRef.current?.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [selectedTab, branch])


    return (
        <div className='flex flex-col flex-1 overflow-auto p-6 max-md:p-1 gap-4 relative'>
            {user.role == 'admin' && <div className='absolute max-md:relative max-md:right-0 top-[0px] right-[20px] flex items-center gap-2'>
                <p className='text-sm'>Branch:</p>
                <Select onChange={setBranch} options={branches} value={branch} styles={{}} className='text-sm' name="branch" required ref={brRef} />
            </div> }
        <div className='flex gap-4 items-center max-md:justify-center'>
            <p className='text-[16px] uppercase font-bold max-md:hidden'>CashBook</p>
            {isAllowed(access.CASHBOOK_VIEW_BALANCE) && <><div className='bg-white p-4 max-md:p-2 rounded-md flex items-center gap-4 max-md:gap-2'>
                <p className='p-1 text-black font-bold text-lg max-md:text-sm max-md:flex max-md:flex-col max-md:items-center'>
                    <span className='font-bold'>Balance</span> <span className='font-bold max-md:text-[15px] text-center'>{showBalance ? parseFloat(status.balance || 0)?.toLocaleString() + '  Br.' : '****'}</span>
                    <span className='text-red text-xs ml-4 text-center max-md:ml-0'>Est. <span className='font-bold text-[#000000]'>{showBalance ? (parseFloat(status.balance) - parseFloat(status.outstanding)).toLocaleString() : '***'}</span> (-{showBalance ? parseFloat(status.outstanding)?.toLocaleString() : '**'})</span>
                </p>
                <p className='p-1 text-[#00bd00] font-bold  max-md:text-sm text-lg max-md:flex max-md:flex-col max-md:items-center'>
                    <span className='font-bold'>In</span>  <span className='font-bold max-md:text-[15px] text-center'>{showBalance ? parseFloat(status.totalIn || 0)?.toLocaleString() : '****'} Br.</span>
                </p>
                <p className='p-1 text-[#ff0000] max-md:text-sm font-bold text-lg max-md:flex max-md:flex-col max-md:items-center'>
                <span className='font-bold'>Out</span>  <span className='font-bold max-md:text-[15px] text-center'>{showBalance ? parseFloat(status.totalOut || 0)?.toLocaleString() : '****'} Br.</span>
                </p>
            </div>
            <button onClick={() => setShowBalance(b => !b)} className='bg-[none] border-none'>
                {!showBalance ?  <EyeOff color={MAIN_COLOR} size={22} /> : <Eye color={MAIN_COLOR} size={22} />}
            </button>
            </>}
        </div>
        <div className='overflow-y-auto flex-1 flex flex-col gap-4 rounded-md bg-white p-6 max-md:p-0 relative'>
            {user?.role == 'admin' &&<p className='text-center text-xs bg-[#ff000020] text-[ff000080] p-1 rounded-sm'><b>Attention</b>: Since you are Admin, Every new record here will be assigned to the selected branch</p>}
            <div className='flex items-center justify-center self-center max-md:flex-col max-md:gap-4 max-md:pt-4'>
                <div className='flex rounded-[30px] bg-[#00000030] overflow-hidden shadow-md'>
                    {isAllowed(access.CASHBOOK_VIEW_RECORDS) && <div onClick={() => setSelectedTab('book')} className={'px-10 py-2 max-md:px-3 flex-1 items-center flex cursor-pointer hover:scale-[1.1] transition-all ' + (selectedTab == 'book' && 'bg-[#00000095]')}>
                        <h2 className={'text-black text-sm max-md:text-xs  whitespace-nowrap font-bold select-none ' + (selectedTab == 'book' && 'text-white')}>Book</h2>
                    </div> }
                    {isAllowed(access.APPROVE_PENDING_TRANSACTIONS) && <div onClick={() => setSelectedTab('pending')} className={'px-10 py-2 max-md:px-3 flex-1  cursor-pointer items-center flex hover:scale-[1.1] transition-all ' + (selectedTab == 'pending' && 'bg-[#00000095]')}>
                        <h2 className={'text-black flex items-center gap-2 text-sm max-md:text-xs  whitespace-nowrap select-none font-bold ' + (selectedTab == 'pending' && 'text-white')}>
                            Pending
                            <span className='bg-main rounded-full text-white font-bold inline-flex items-center justify-center w-[25px] h-[25px] max-md:w-[20px] max-md:h-[20px]'>{status.pendingCount || 0}</span>
                            </h2>
                    </div> }
                    {isAllowed(access.SETTLE_ADVANCE_PAYMENT) && <div onClick={() => setSelectedTab('advance')} className={'px-10 py-2 max-md:px-3 flex-1 items-center flex cursor-pointer hover:scale-[1.1] transition-all ' + (selectedTab == 'advance' && 'bg-[#00000095]')}>
                        <h2 className={'text-black text-sm max-md:text-xs flex items-center gap-2 whitespace-nowrap font-bold select-none ' + (selectedTab == 'advance' && 'text-white')}>
                            Advance
                            <span className='bg-main rounded-full text-white font-bold inline-flex items-center justify-center w-[25px] h-[25px] max-md:w-[20px] max-md:h-[20px]'>{status.advanceCount || 0}</span>
                            </h2>
                    </div> }
                    {isAllowed(access.CASHBOOK_SPEND_PETTY_CASH) && <div onClick={() => setSelectedTab('pettycash')} className={'px-10 py-2 max-md:px-3 flex-1 items-center flex cursor-pointer hover:scale-[1.1] transition-all ' + (selectedTab == 'pettycash' && 'bg-[#00000095]')}>
                        <h2 className={'text-black text-sm max-md:text-xs whitespace-nowrap font-bold select-none ' + (selectedTab == 'pettycash' && 'text-white')}>Petty Cash</h2>
                    </div> }
                </div>
                {isAllowed(access.CASHBOOK_IN_OUT) && <div className='flex items-center justify-center'>
                    <button onClick={() => setShowFilter(f => !f)} className='ml-[30px] max-md:ml-[0] flex items-center gap-1 border-[1px] border-[#00000030] p-2 rounded-md hover:bg-[#00000060]'>
                        <Filter color={MAIN_COLOR} size={18} />
                        Filter
                    </button>
                    <button onClick={() => setTxnBox({visible: true, type: 'in'})} className='shadow-md hover:opacity-[0.5] flex items-center gap-1 py-2 px-8 font-bold text-[15px] bg-[#009100] text-white self-center rounded-md ml-[30px]  max-md:ml-[15px]'>
                        <ArrowDownCircle color="#ffffff" size={18} />
                        In
                    </button>
                    <button onClick={() => setTxnBox({visible: true, type: 'out'})} className='shadow-md hover:opacity-[0.5] flex items-center gap-1 py-2 px-8 font-bold text-[15px] bg-[#ff0000] text-white self-center rounded-md ml-[30px]  max-md:ml-[15px]'>
                        <ArrowUpCircle color="#ffffff" size={16} />
                        Out
                    </button>
                </div> }
            </div>
            {showFilter && <div className='border-[1px] border-[#00000050] flex gap-3 p-3 max-md:flex-col'>
                <div className='flex items-center items-center flex-1 justify-center'>
                    <button className='border-[1px] border-black px-4 text-sm py-1 text-white bg-black'>All</button>
                    <button className='border-[1px] border-black px-4 text-sm py-1 text-black'>Today's</button>
                    <button className='border-[1px] border-black px-4 text-sm py-1 text-black'>Yesterday's</button>
                </div>
                <div className='h-full w-[1px] bg-[#00000050]'></div>
                <div className='flex-2 flex gap-3 max-md:justify-center'>
                    <div className='flex gap-2 items-center'>
                        <p className='text-sm max-md:text-xs'>Start Date</p>
                        <input type='date' className='border-[1px] border-black p-1 max-md:text-xs' />
                    </div>
                    <div className='flex gap-2 items-center'>
                        <p className='text-sm max-md:text-xs'>End Date</p>
                        <input type='date' className='border-[1px] border-black p-1 max-md:text-xs' />
                    </div>
                </div>
                <div className='h-full w-[1px] bg-[#00000050]'></div>
                <div className='flex-1'>
                    <input className='border-[#00000050] border-[2px] w-full rounded-[20px] p-2 px-6 placeholder:text-sm' placeholder='Search with event phone' type='text' />
                </div>
                <button className='bg-main text-white font-bold text-sm px-4 py-1 rounded-md'>Filter</button>
            </div> }
            <div id="scrollable" ref={scrollRef} className='flex-1 flex overflow-auto relative flex-col items-center cb-container'>
            <TransitionGroup>
                <CSSTransition
                    key={selectedTab}
                    classNames="fade"
                    timeout={500}
                    exit={false}
                >
                    {(selectedTab == 'pettycash' && (isAllowed(access.CASHBOOK_SPEND_PETTY_CASH) || isAllowed(access.CASHBOOK_CREATE_PETTY_CASH)) ) ? <PettyCash fetchTransactions={fetchTransactions} branch={branch} /> : (
                        <>
                        <InfiniteScroll
                            dataLength={transactions[selectedTab]?.length}
                            next={() => fetchTransactions(selectedTab)}
                            hasMore={hasMore[selectedTab]}
                            loader={<div className='w-full flex justify-center'><Loader text={null} loading={hasMore[selectedTab]} color={MAIN_COLOR} style={{marginTop: 20, alignSelf: 'center'}} /></div>}
                            scrollableTarget="scrollable"
                            endMessage={<p className='text-center m-4 text-sm'>No more data to load.</p>}
                        >
                        <table className='relative overflow-auto w-full'>
                            <thead>
                                <tr className='static top-0'>
                                    <th>Amount</th>
                                    <th>Time</th>
                                    <th className='max-md:hidden'>Type</th>
                                    {selectedTab == 'book' && <th>Txn ID</th>}
                                    <th className='max-md:hidden'>Remark</th>
                                    {selectedTab != 'book' && <th>Action</th>}
                                    {selectedTab != 'pending' && <th>Receipt</th>}
                                </tr>
                            </thead>
                            <tbody>
                                { transactions[selectedTab]?.map(txn => (
                                    <tr onClick={() => setRemark(getRemark(txn))}>
                                        <td className={'font-bold text-md ' + (txn.transact_type == 'in' ? 'text-[#009100]' : 'text-[#ff0000]')}>{txn.amount?.toLocaleString()} Br.</td>
                                        <td>{new Date(txn.createdAt).toLocaleDateString('en-US', dateOptions)} |<span className='max-md:block'> {date.format(new Date(txn.createdAt), 'hh:mm:ss A')}</span></td>
                                        <td className='p1 max-md:hidden'><span className={'font-bold py-1 px-2 text-white text-xs ' + (txn.transact_type == 'in' ? 'bg-[#009100]' : 'bg-[#ff0000]')}>{txn.transact_type?.toUpperCase()}</span></td>
                                        {selectedTab == 'book' && <td>{txn.transact_id}</td>}
                                        <td className='text-xs max-md:hidden'>{getRemark(txn)}</td>
                                        {selectedTab != 'book' && <td className=''>
                                            {selectedTab == 'pending' ? (
                                                    <div className='flex items-center justify-center gap-2'>
                                                        <button onClick={(e) =>  {e.stopPropagation(); setTxnBox({visible: true, type: 'pending', data: txn})}} className='border-none p-1'><Check color="#009100" size={18} /></button>
                                                        <button onClick={(e) => {e.stopPropagation(); setConfirm({visible: true, action: rejectPending(txn.id)})}} className='border-none p-1'><X color="#ff0000" size={18} /></button>
                                                    </div>
                                                ) : (
                                                    <button
                                                        onClick={(e) => {e.stopPropagation(); setTxnBox({visible: true, type: 'advance', data: txn})}}
                                                        className='px-4 py-1 rounded-md text-white bg-black font-bold'>Settle ({txn.settle_amount ? parseFloat(txn.settle_amount)?.toLocaleString() : 'Unkown'})
                                                    </button>
                                                )
                                            }
                                        </td>}
                                        {selectedTab != 'pending' && <td>{txn.proof && <a onClick={e => e.stopPropagation()} className='text-[#0000ff] underline' href={baseURL + path.uploads + txn.proof} target='_blank'>View</a>}</td>}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </InfiniteScroll>
                        {/* {(!loading && selectedTab != 'pettycash' && !transactions[selectedTab]?.length) && <h4 className='text-center mt-4'>No Data.</h4>}
                        <div className='flex justify-center'>
                            <Loader text={null} loading={loading} color={MAIN_COLOR} style={{marginTop: 20}} />
                        </div> */}
                        </>
                    )}
                </CSSTransition>
                </TransitionGroup>
            </div>
        </div>
        {txnBox.visible && <TxnPrompt type={txnBox.type} data={txnBox.data} setTxnBox={setTxnBox} fetchTransactions={fetchTransactions} user={user} branch={branch} />}
        {remark && <Remark remark={remark} setRemark={setRemark} />}
    </div>
    )
}

function TxnPrompt({type, data = {}, setTxnBox, fetchTransactions, branch, user}){
    const [loading, setLoading] = useState(false)
    const initialAmount = {pending: 'amount', advance: 'settle_amount'}
    const [amount, setAmount] = useState(data[initialAmount[type]] || 0)
    const {setImageControl, showMessage} = useContext(appContext)
    const [proof, setProof] = useState({})
    const [remark, setRemark] = useState('')
    const [isAdvance, setIsAdvance] = useState(false)
    const todaymdy = new Date().toISOString().split('T')[0]
    const [date, setDate] = useState(todaymdy)
    const [showETDate, setShowETDate] = useState(null)
    const [etDate, setEtDate] = useState(null)
    const [settlAmount, setSettlAmount] = useState()

    const selectETDate = (_et_date) => {
        setShowETDate(false)
        setEtDate(_et_date)
        let [yyyy, mm, dd] = convertEG(_et_date, true)
        dd = dd < 10 ? '0' + dd : dd
        setDate(`${yyyy}-${mm}-${dd}`)
    }

    const acceptPendingOrSettle = (e) =>  {
        e.preventDefault()
        setLoading(true)
        api.patch(baseURL + path.transactions, {
            amount,
            type,
            action: 'accept',
            proof: proof.path,
            actionId: data.id
        }).then(response => {
            let data = response.data
            if(data.success){
                showMessage('Success!')
                if(type == 'pending') fetchTransactions('pending', true)
                fetchTransactions('advance', true)
                fetchTransactions('book', true)
            }
        }).catch(e => showMessage('Error has been occured!', true))
        .finally(() => {
            setLoading(false)
            setTxnBox({})
        })
    }

    const cashInOut = e => {
        e.preventDefault()
        if(user.role == 'admin' && !branch?.value) return
        setLoading(true)
        api.post(baseURL + path.transactions + '?branchId=' + branch?.value, {
            amount,
            type,
            proof: proof.path,
            remark,
            settle_date: date,
            settle_amount: settlAmount,
            isAdvance
        }).then(response => {
            let data = response.data
            if(data.success){
                showMessage('Success!')
                fetchTransactions('book', true)
                if(isAdvance) fetchTransactions('advance', true)
            }
        }).catch(e => showMessage('Error has been occured!', true))
        .finally(() => {
            setLoading(false)
            setTxnBox({})
        })
    }

    // const takeAction = {
    //     pending: acceptPendingOrSettle
    // }

    const texts = {
        pending: {
            title: `Accept Pending amount (${data.amount ? parseFloat(data.amount).toLocaleString() : ''} Br.)`,
            btn: 'Accept'
        },
        advance: {
            title: `Settle Amount (${data.settle_amount ? parseFloat(data.settle_amount).toLocaleString() : ''} Br.)`,
            btn: 'Settle'
        },
        in: {
            title: 'Cash In',
            btn: 'Cash In'
        },
        out: {
            title: 'Cash Out',
            btn: 'Cash Out'
        }
    }
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000080] z-[99999]'>
            <form method="post" onSubmit={['pending', 'advance'].includes(type) ? acceptPendingOrSettle : cashInOut} className='flex flex-col gap-4 p-4 bg-white p-6 max-md:p-2 max-md:py-4 rounded-md'>
                <h2 className="self-center text-[25px] font-bold max-md:text-[20px]">{texts[type].title}</h2>
                <div className='grid grid-cols-3 max-md:grid-cols-1 gap-6 max-md:gap-4 feel-c1 bg-[#00000010] p-4'>
                    <div className='flex flex-col gap-2'>
                        <p>Amount</p>
                        <input value={amount} onChange={e => setAmount(e.target.value)} placeholder='Amount' name="amount" className='input' type='text' required/>
                    </div>
                    {['in', 'out'].includes(type) && (
                        <div className='flex flex-col gap-2'>
                            <p>Remark</p>
                            <input value={remark} onChange={e => setRemark(e.target.value)} placeholder='Remark' name="remark" className='input' type='text' required/>
                        </div>
                    )}
                     
                    <div className='flex gap-2 items-center '>
                        {proof?.path && <>
                            <img onClick={e => window.open(e.target.src, '_blank')} style={{width: '40px', height: '40px', objectFit: 'contain'}} src={URL.createObjectURL(proof.blob)} />
                            <button onClick={() => setProof(null)}><Trash color='#ff0000' size={15} /></button>
                        </>}
                        <div className='flex flex-col gap-2 justify-center w-full'>
                            <p>Proof / Receipt</p>
                            <button type='button' onClick={() => setImageControl({visible: true, setImage: setProof})} className='py-2 px-8 max-md:px-2 font-bold text-[16px] bg-black text-white self-center w-full'>Take Image</button>
                        </div>
                    </div>
                    {['in', 'out'].includes(type) && <div className='flex items-center gap-2'>
                        <label for='advance' className='text-sm select-none'>Is Advance Payment</label>
                        <input name='isFixed' onChange={(e) => setIsAdvance(e.target.checked)} value={isAdvance} type='checkbox' id='advance' />
                    </div> } 
                    {isAdvance && (
                        <>
                            <div className='flex flex-col gap-2'>
                                <p>Settle Amount</p>
                                <input value={settlAmount} onChange={e => setSettlAmount(e.target.value)} placeholder='Settle Amount (Optional)' name="settlamount" className='input' type='text' />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <p>Settlement Date (Optional)</p>
                                <input value={date} onChange={e => setDate(e.target.value)} name="event_date" type='date' className='input' />
                                <button type='button' onClick={() => setShowETDate(true)} className='bg-[#00000095] text-white font-bold px-4 py-2 text-sm'>🇪🇹 Convert Ethiopian Calendar</button>
                                <p className='text-black text-sm border-[1px] border-black p-2 font-bold bottom-1 self-center'>{getEtDate(date)}</p>
                            </div>
                        </>
                    )}
                 
                </div>
                <div className='flex justify-center gap-6'>
                    <button type="submit" className='py-2 px-8 font-bold text-[20px] bg-main text-white self-center' type='submit'>
                        <Loader text={texts[type].btn} loading={loading} />                        
                    </button>
                    <button onClick={() => setTxnBox({})} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Cancel</button>
                </div>
            </form>
            {showETDate && <ETDatePicker selectETDate={selectETDate} setShowETDate={setShowETDate} />}
        </div>
    )
}

function Remark({remark, setRemark}){
    return (
        <div onClick={() => setRemark(null)} className='fixed inset-0 flex items-center justify-center bg-[#00000080] z-[9999]'>
            <div className='flex flex-col items-center justify-center gap-1 bg-white p-4 rounded-md'>
                <h2 className='underline font-bold'>Remark</h2>
                <p className='max-w-[350px] text-center'>{remark}</p>
            </div>
        </div>
    )
}