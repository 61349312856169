import { useContext, useState, useEffect } from "react";
import { appContext } from "../Context/appContext";
import { api, getByPage } from "../helpers";
import { MAIN_COLOR, path } from "../constants";
import Loader from "../Components/Loader";
import InfiniteScroll from 'react-infinite-scroll-component';

export default function Logs(){
    const {showMessage} = useContext(appContext)
    const [logs, setLogs] = useState([])
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(0)
    const [hasMore, setHasMore] = useState(true)

    const fetchLogs = () => {
        api.get(getByPage(path.logs, page)).then(response => {
            const data = response.data
            if(data.success){
                setLogs([...logs, ...data.logs])
                setPage(p => p + 1)
                if(!data.logs.length) setHasMore(false)
            } else showMessage('Something went wrong', true)
        }).catch(e => showMessage('Something went wrong', true))
        .finally(() => setLoading(false))
    }


    useEffect(fetchLogs, [])
    return (
        <div className='flex flex-col flex-1 overflow-auto p-6 gap-6'>
        <p className='text-[16px] uppercase font-bold'>Action Logs</p>
        <div id="scrollable" className='overflow-auto relative flex-1 rounded-md bg-white p-6 pt-0 flex flex-col'>
        <InfiniteScroll
            dataLength={logs.length}
            next={fetchLogs}
            hasMore={hasMore}
            loader={<div className='w-full flex justify-center'><Loader text={null} loading={hasMore} color={MAIN_COLOR} style={{marginTop: 20, alignSelf: 'center'}} /></div>}
            scrollableTarget="scrollable"
            endMessage={<p className='text-center m-4 text-sm'>No more data to load.</p>}
        >
        <table className='w-full overflow-auto mt-6'> 
            <thead> 
                <tr>
                    <th>
                        Username (Branch)
                    </th>
                    <th>
                        Action
                    </th>
                    <th>
                        Device | IP
                    </th>
                    <th>
                        Action Time
                    </th>
                </tr>
            </thead>
            <tbody>
                {logs.map(log => (
                    <tr>
                        <td>{log.user?.username} ({log.user?.branch?.name || 'Admin Action'})</td>
                        <td>{log.action}</td>
                        <td>{log.log_details}</td>
                        <td>{log.createdAt}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </InfiniteScroll>
        {(!loading && !logs.length) && <h4 className='text-center mt-4'>No Data.</h4>}
        
        </div>
    </div>
    )
}