import { useContext, useEffect, useRef, useState } from "react";
import { authContext } from "../Context/authContext";
import Login from "../Pages/Login";
import { useTransition, animated, useSpringRef } from '@react-spring/web'
import AdminLayout from "./AdminLayout";
import { api } from "../helpers";
import { path, TOKEN_KEY } from "../constants";

const pages = [
    ({ style }) => <Login style={style} />,
    ({ style }) => <AdminLayout style={style} />
]

export default function LayoutControl(){
    const {token, index, setUser} = useContext(authContext)
    const transRef = useSpringRef()
    const transitions = useTransition(index, {
      ref: transRef,
      keys: null,
      from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
      enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
      leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
    })
    useEffect(() => {
        let _token = sessionStorage.getItem(TOKEN_KEY)
        let lo = document.getElementById('loader')
        if(_token){
            api.get(path.userInfo).then(response => {
                let data = response.data
                setUser({...data.user, access: data.user?.access?.split(',')})
                document.title = 'EMS - ' + data?.user?.tenant?.short_name
                if(lo) lo.remove()
                transRef.start()
            })
        }   
        else {
            if(lo) lo.remove()
            transRef.start()
        }
    }, [index, token])

    // if(!token) return <Login />
    return (
    <>
    {transitions((style, i) => {
            const Page = pages[i]
            return <Page style={style} />
    })}
    </>
    )
}