import { useContext, useState } from "react"
import { appContext } from "../Context/appContext"
import Loader from "./Loader"

export default function Confirm(){
    const {setConfirm, confirm} = useContext(appContext)
    const [loading, setLoading] = useState(false)

    const handleAction = () => {
        setLoading(true)
        confirm.action().then(_ => {
            setConfirm({})
        }).finally(() => setLoading(false))
    }
    if(!confirm.visible) return null
    return (
        <div className='dim'>
            <div className='alert-box'>
                <h3>Are you sure?</h3>
                <p>{confirm.message}</p>
                <div className="btns">
                    <button className='text-white' onClick={handleAction} disabled={loading}>
                        <Loader text={'Yes'} loading={loading} />
                    </button>
                    <button onClick={() => setConfirm({})}>No</button>
                </div>
            </div>
        </div>
    )
}