import { useContext } from 'react'
import {Calendar} from 'react-feather'
import { ETHIOPIAN_MONTHS, MAIN_COLOR } from '../constants'
import { appContext } from '../Context/appContext'
import { convertTo12HourFormat, getTotal } from '../Pages/Events'

export default function EventDetail(){
    const {showEvent, toggleShowEvent, t} = useContext(appContext)
    const event = showEvent?.data
    return (
        <div className='fixed inset-0 flex items-start justify-center bg-[#00000090] overflow-auto'>
            <div className='flex flex-col gap-4 p-4 bg-white rounded-md bg-[#e7e7e7]'>
                <div className='flex items-center gap-4 self-center'>
                    <Calendar color={MAIN_COLOR} size={30} />
                    <p className='font-bold'>{ETHIOPIAN_MONTHS[event?.et_date?.split('-')[1] - 1]} {event?.et_date?.split('-')[2]}, {event?.et_date?.split('-')[0]}</p>
                    <div className='w-[1px] h-[30px] bg-[#00000080]'></div>
                    <p className='font-bold'>G.C {event?.event_date?.split('T')[0]}</p>
                </div>
                <div className='h-[1px] bg-[#00000080] w-full'></div>

                <div className='flex gap-12 max-md:flex-col max-md:gap-6'>
                    <div className='flex flex-col gap-6'>
                        <div className='bg-white shadow-lg p-4 rounded-md'>
                            <p className='text-md'>{t('CLIENT_NAME')}: <span className='font-bold ml-2'>{event?.client?.full_name}</span> </p>
                            <p className='text-md'>{t('PRIMARY_PHONE')}: <span className='font-bold ml-2'>{event?.client?.phone_number}</span> </p>
                            <p className='text-md'>{t('SECONDARY_PHONE')}: <span className='font-bold ml-2'>{event?.client?.secondary_phone}</span> </p>
                            <p className='text-md'>{t('PEOPLE')}: <span className='font-bold ml-2'>{event.num_of_people} ({getTotal(event, true)?.toLocaleString()} {t('PER_PERSON')})</span> </p>
                        </div>
                        <div className='bg-white shadow-lg p-4 rounded-md'>
                            <p className='text-md'>{t('PACKAGE')}: <span className='font-bold ml-2'>{event.package?.name || t('CUSTOM')}</span> </p>
                            <p className='text-md'>{t('BRANCH')}: <span className='font-bold ml-2'>{event.branch?.name}</span> </p>
                            <p className='text-md'>{t('TIME')}: <span className='font-bold ml-2'>{convertTo12HourFormat(event?.time)}</span> </p>
                            <p className='text-md'>{t('ADVANCE')}: <span className='font-bold ml-2'>{event.advance_amount?.toLocaleString()} {t('BR')}</span> </p>
                        </div>
                    </div>

                    <div className='flex flex-col gap-6'>
                        <div className='bg-white shadow-lg p-4 rounded-md'>
                            <p className='text-md'>{t('HAS_ADD_ITEMS')}: <span className='font-bold ml-2'>{!!event?.additionalitems.length ? t('YES') : t('NO')}</span> </p>
                            <p className='text-md'>{t('ADDITIONAL_PLATES')}: <span className='font-bold ml-2'>{event.additional_plates}</span> </p>
                            <p className='text-md'>{t('ADDITIONAL_PRICE')}: <span className='font-bold ml-2'>{event?.additional_price?.toLocaleString()} {t('BR')}</span> </p>
                            <p className='text-md'>{t('DISCOUNT')}: <span className='font-bold ml-2'>{event?.discount_amount?.toLocaleString()} {t('BR')}</span> </p>
                        </div>
                        <div className='bg-white shadow-lg p-4 rounded-md'>
                            <p className='text-md'>{t('TOTAL')}: <span className='font-bold ml-2'></span> {getTotal(event)?.toLocaleString()} {t('BR')}</p>
                            <p className='text-md'>{t('FULL_PAID')}: <span className='font-bold ml-2'>{event.paid_full ? t('YES') : `${t('NO')} (${t('LEFT')}: ` + (getTotal(event) - event.advance_amount)?.toLocaleString() + ')' }</span> </p>
                            <p className='text-md'>{t('ASSIGNED_EMPLOYEES')}: <span className='font-bold ml-2'>{event.employees?.length}</span> </p>
                            <p className='text-md'>{t('ASSIGNED_VENDORS')}: <span className='font-bold ml-2'>{event.vendors?.length}</span> </p>
                        </div>
                    </div>
                </div>

                <div className='bg-white shadow-lg p-4 rounded-md flex flex-col'>
                    <p className='text-md text-center'>{event.package ? t('PACKAGE_ITEMS') : t('CUSTOM_PACK_ITEMS') }</p>
                    <div className='grid grid-cols-4 gap-4 p-2'>
                        {event.package ? (
                            event.package?.items?.map(i => <p className='p-2 bg-[#00000020] text-center font-bold text-md'>{i?.name}</p>)
                        ) : (
                            event?.items?.map(i => <p className='p-2 bg-[#00000020] text-center font-bold text-md'>{i?.name}</p>)
                        )}
                    </div>
                </div>
                <div className='bg-white shadow-lg p-4 rounded-md flex flex-col'>
                    <p className='text-md text-center'>{t('ADDITIONAL_ITEMS')}</p>
                    <div className='grid grid-cols-4 gap-4 p-2'>
                        {event.additionalitems?.map(i => <p className='p-2 bg-[#00000020] text-center font-bold text-md'>{i?.name}</p>)}
                    </div>
                    {!event?.additionalitems?.length && <p className='text-sm'>{t('NO_ADD_ITEMS')}</p>}
                </div>
                <div className='bg-white shadow-lg p-4 rounded-md flex flex-col'>
                    <p className='text-md text-center'>{t('REMARK')}</p>
                    <p className='text-sm'>{event.remark}</p>
                </div>
                <button onClick={() => toggleShowEvent()} className='p-3 bg-none border-[1px] border-main text-md text-main mt-4 font-bold'>{t('CLOSE')}</button>
            </div>
        </div>
    )
}