import './App.css';
import AuthContext from './Context/authContext';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import AppContext from './Context/appContext';

function App() {
  return (
    <AuthContext>
      <AppContext>  
        <RouterProvider router={router} />
      </AppContext>
    </AuthContext>
  );
}

export default App;
