import { Link } from "react-router-dom";
import {Save} from 'react-feather'
import { useContext, useEffect, useRef, useState } from "react";
import { appContext } from "../Context/appContext";
import { api } from "../helpers"
import Loader from "../Components/Loader"
import { baseURL, MAIN_COLOR, path } from "../constants"

const temp_vars = [
    'client_name', 'days_left', 'advance_paid',
    'left_money', 'event_date', 'event_time',
    'bank_name', 'banc_acc', 'acc_name'
]

export default function Settings(){
    const [loading, setLoading] = useState(false)
    // const [fetching, setFetching] = useState(true)
    const {showMessage, settings, loadingSettings, setSettings} = useContext(appContext)
    const [uploading, setUploading] = useState(null)
    // const [settings, setSettings] = useState({})

    const updateState = e => {
        setSettings(s => ({
            ...s,
            [e.target.name]: e.target.value
        }))
    }

    const saveSettings = (e) => {
        e.preventDefault()
        setLoading(true)
        api.post(path.settings, {
            ...settings
        }).then(response => {
            if(response.data.success) {
                showMessage(`Successfuly Saved!`, false)
            }
            else showMessage('Error has been occured!', true)
        })
        .catch(e => showMessage('Error has been occured!', true))
        .finally(() => {
            setLoading(false)
        })
    }

    const uploadStamp = async (e) => {
        if(!e.target.files.length) return
        let form = new FormData()
        form.append('image', e.target.files[0])
        let response = await api.post(path.uploadFile, form, {
            onUploadProgress: (e) => {
                const percentCompleted = Math.round((e.loaded * 100) / e.total);
                setUploading(percentCompleted)
            }
        })
        setUploading(null)
        setSettings(s => ({...s, stamp: response.data.path}))
    }

    return (
        <div className='flex flex-col flex-1 overflow-auto p-6 gap-6'>
        <p className='text-[16px] uppercase font-bold'>System Settings</p>
        <form method='post' onSubmit={saveSettings} className='overflow-y-auto flex-1 flex flex-col gap-4 rounded-md bg-white p-6 relative'>
            {/* <div className='flex items-center gap-6'>
                <p className='text-[17px]'>Google Calendar Key: </p>
                <input name='goog_key' onChange={updateState} value={settings.goog_key} className='w-[200px] border-b-[1px] border-b-[#00000080] outline-none foucs:border-b-main' type='text' />
            </div> */}
            <div className='flex items-center gap-4'>
                <p className='text-[17px]'>Send SMS Days Before Event: </p>
                <input name='days_before' onChange={updateState} value={settings.days_before} className='w-[200px] border-b-[1px] border-b-[#00000080] outline-none foucs:border-b-main' type='number' />
            </div>
            {/* <div className='flex items-center gap-4'>
                <p className='text-[17px]'>SMS Sender ID</p>
                <input name='sms_sender_id' onChange={updateState} value={settings.sms_id} className='w-[200px] border-b-[1px] border-b-[#00000080] outline-none foucs:border-b-main' type='text' />
            </div> */}
            <div className='flex items-center gap-4'>
                <p className='text-[17px]'>SMS API Token</p>
                <input name='sms_api_token' onChange={updateState} value={settings.sms_api_token} className='w-[200px] border-b-[1px] border-b-[#00000080] outline-none foucs:border-b-main' type='password' />
            </div>
            <div className='flex items-center gap-4'>
                <p className='text-[17px]'>SMS Identifier</p>
                <input name='sms_id' onChange={updateState} value={settings.sms_id} className='w-[200px] border-b-[1px] border-b-[#00000080] outline-none foucs:border-b-main' type='text' />
            </div>
            <div className='flex items-center gap-4'>
                <p className='text-[17px]'>SMS Sender Name</p>
                <input name='sender_name' onChange={updateState} value={settings.sender_name} className='w-[200px] border-b-[1px] border-b-[#00000080] outline-none foucs:border-b-main' type='text' />
            </div>
            <div className='flex gap-4 items-center'>
                <div className='flex gap-1 flex-col'>
                    <p className='text-[17px]'>Event Pay Bank Name: </p>
                    <input name='bank_name' onChange={updateState} value={settings.bank_name} className='w-[200px] border-b-[1px] border-b-[#00000080] outline-none foucs:border-b-main' type='text' />
                </div>
                <div className='flex gap-1 flex-col'>
                    <p className='text-[17px]'>Account Number: </p>
                    <input name='acc_number' onChange={updateState} value={settings.acc_number} className='w-[200px] border-b-[1px] border-b-[#00000080] outline-none foucs:border-b-main' type='text' />
                </div>
                <div className='flex gap-1 flex-col'>
                    <p className='text-[17px]'>Account Name: </p>
                    <input name='acc_name' onChange={updateState} value={settings.acc_name} className='w-[200px] border-b-[1px] border-b-[#00000080] outline-none foucs:border-b-main' type='text' />
                </div>
            </div>
            <p className='text-[14px]'>Variables You Can Use on Both Tmplates: </p>
            <div className='flex gap-4 flex-wrap'>
                {temp_vars.map(_var => <span className='text-red'>{'{' + _var + '}'}</span>)}
            </div>
            <div className='flex flex-col gap-4 self-start'>
                <p className='text-[17px]'>SMS Days Before Event Template: </p>
                <textarea name='days_before_temp' onChange={updateState} value={settings.days_before_temp} rows='6' className='p-2 w-[300px] border-[1px] border-[#00000080] rounded-md outline-none'>
                </textarea>
            </div>
            <div className='flex flex-col gap-4 self-start'>
                <p className='text-[17px]'>Event Payment confirmation Template: </p>
                <textarea name="pay_confirm_temp" onChange={updateState} value={settings.pay_confirm_temp} rows='6' className='p-2 w-[300px] border-[1px] border-[#00000080] rounded-md outline-none'>
                </textarea>
            </div>
            <div className='flex flex-col gap-4 self-start'>
                <p className='text-[17px]'>{uploading ? `Uploading ${uploading?.toFixed(2)}%` : 'Stamp (only PNG format)'}</p>
                <input type='file' onChange={uploadStamp} disabled={uploading} />
                {settings.stamp && <a href={baseURL + path.uploads + settings.stamp} target='_blank' className='text-[#0000ff] text-xs'>View File</a>}
            </div>
                <button type='submit' className='self-start bg-main px-4 py-2 rounded-sm font-bold  text-white flex items-center gap-2 hover:bg-darker transition-all border-none'>
                    <Loader text='Save' loading={loading} />    
                    <Save color="#ffffff" size={18} />
                </button>
                {loadingSettings && <div className='absolute inset-0 bg-[#00000090] flex items-center justify-center'>
                    <div className='flex flex-col bg-black gap-4 p-4 rounded-md items-center'>
                        <p className='text-white text-lg font-bold'>Loading Settings...</p>
                        <Loader loading={true} color={MAIN_COLOR} /> 
                    </div>
                </div> }
        </form>
    </div>
    )
}