import { createContext, useEffect, useState } from "react"
import { TOKEN_KEY } from "../constants"
import { useIdleTimer } from 'react-idle-timer'

export const authContext = createContext()

export default function AuthContext({ children }){
    const [token, setToken] = useState(null)
    const dIndex = !(sessionStorage.getItem(TOKEN_KEY)) ? 0 : 1
    const [index, set] = useState(dIndex)
    const [user, setUser] = useState({})

    
    
    const logOut = () => {
        if(token){
            sessionStorage.removeItem(TOKEN_KEY)
            setToken(null)
            toggleLayout()
        }
    }
    
    const { getRemainingTime } = useIdleTimer({
        onIdle: logOut,
        timeout: 600000,
        throttle: 500
    })
    

    const toggleLayout = () => set(state => (state + 1) % 2)

    const isAllowed = (ACTION) => {
        return user.access?.includes(ACTION)
    }

    

    useEffect(() => {
        let tok = sessionStorage.getItem(TOKEN_KEY)
        setToken(tok)
    }, [])

    return (
        <authContext.Provider value={{
            token, setToken, index, set, toggleLayout, logOut, user, setUser, isAllowed
        }}>
            {children}
        </authContext.Provider>
    )
}