import { TOKEN_KEY } from "./constants";
import LayoutControl from "./Layout/LayoutControl";
import Branches from "./Pages/Branches";
import Calendar from "./Pages/Calendar";
import CashBook from "./Pages/CashBook";
import Clients from "./Pages/Clients";
import Employees from "./Pages/Employees";
import Events from "./Pages/Events";
import Items from "./Pages/Items";
import Login from "./Pages/Login";
import Logs from "./Pages/Logs";
import Packages from "./Pages/Packages";
import Settings from "./Pages/Settings";
import User from "./Pages/Users";
import Vendors from "./Pages/Vendors";

const { createBrowserRouter, Navigate } = require("react-router-dom");

const router = createBrowserRouter([
    {
        path: '/',
        element: <LayoutControl />,
        children : [
            {
                path: '',
                element: <Calendar />
            },
            {
                path: 'clients',
                element: <Clients />
            },
            {
                path: 'events',
                element: <Events />
            },
            {
                path: 'items',
                element: <Items />
            },
            {
                path: 'packages',
                element: <Packages />
            },
            {
                path: 'settings',
                element: <Settings />
            },
            {
                path: 'users',
                element: <User />
            },
            {
                path: 'branches',
                element: <Branches />
            },
            {
                path: 'employees',
                element: <Employees />
            },
            {
                path: 'cashbook',
                element: <CashBook />
            },
            {
                path: 'vendors',
                element: <Vendors />
            },
            {
                path: 'action-logs',
                element: <Logs />
            },
            ...(!sessionStorage.getItem(TOKEN_KEY) ? [{
                path: 'login',
                element: <Login />
            }] : []),
        ]
    }, { path: '*', element: <Navigate to="/" replace /> }
])

export default router