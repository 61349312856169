import { useEffect, useState } from 'react'
import {ArrowRight, ArrowLeft} from 'react-feather'
import { ETHIOPIAN_DAYS, ETHIOPIAN_MONTHS, MAIN_COLOR } from '../../constants'
import { convertEG } from '../../helpers'

function useCalendarInfo(){
    const [days, setDays] = useState([])
    const [yy, mm, dd] = convertEG(new Date())
    const [month, setMonth] = useState(mm)
    const [year, setYear] = useState(yy)

    useEffect(() => {   
        const getPuagmeDays = () => year % 4 == 3 ? 6 : 5

        let _30days = [...Array(month == 13 ? getPuagmeDays() : 30).keys()].map(k => k + 1)
        let month_start = convertEG([year, month, 1], true)
        month_start = month_start.map(m => m?.toString()?.padStart(2, 0))
        let first_day = new Date(month_start.join('-'))
        let day_of_week_start = first_day.getDay()
       
        setDays([...Array(day_of_week_start).keys()].map(d => null).concat(_30days))
    }, [month, year])

    const changeMonth = (target) => {
        if(target == 'up') {
            if(month == 13){
                setMonth(1)
                setYear(y => y + 1)
            }
            else setMonth(m => m + 1)
        } else {
            if(month == 1){
                setMonth(13)
                setYear(y => y - 1)
            } else {
                setMonth(m => m - 1)
            }
        }
    }

    return [days, month, year, changeMonth, [yy, mm, dd]]
}

export default function ETCalendar({ onClickDay, tileContent }){
    const [days, month, year, changeMonth, today] = useCalendarInfo()
    const isToday = (_d) => {
        const [y, m, d] = today
        if(_d == d && month == m && year == y) return true
        return false
    }
    const getDate = (_d) => {
        const gc = convertEG([year, month, _d], true).map(m => m?.toString()?.padStart(2, 0))
        return new Date(gc.join('-'))
    }
    return (
        <div className='flex flex-col w-full'>
            <div className='flex items-center justify-center gap-6 max-md:gap-1'>
                <button className='p-2' onClick={() => changeMonth('down')}>
                    <ArrowLeft color={MAIN_COLOR} size={20} />
                </button>
                <h2 className='text-md bg-[#00000020] text-black p-2 w-[250px] text-center'>{ETHIOPIAN_MONTHS[month - 1]} {year}</h2>
                <button className='p-2' onClick={() => changeMonth('up')}>
                    <ArrowRight color={MAIN_COLOR} size={20} />
                </button>
            </div>
            <div className='grid grid-cols-7'>
                {ETHIOPIAN_DAYS.map(d => (
                    <h4 className='border-b-[1px] border-b-dotted pb-1 text-center mt-4 text-sm'>{d[0]}</h4>
                ))}
                {days.map(day => (
                    <div onClick={() => day && onClickDay(getDate(day))} className={`border-[1px] border-dotted border-[#d6d7da] flex flex-col items-center justify-center ${day != null ? 'cursor-pointer hover:bg-[#00000020]' : 'no-border'} ${isToday(day) ? 'bg-main text-white' : ''}`}>
                        <h4 className='px-2 py-[22px] max-md:py-5'>{day}</h4>
                        {day && tileContent({date: getDate(day)})}
                    </div>
                ))}
            </div>
        </div>
    )
}