import { useContext, useEffect, useState } from "react";
import {
    TransitionGroup,
    CSSTransition
  } from "react-transition-group";
import { access, baseURL, MAIN_COLOR, path } from "../constants";
import { appContext } from "../Context/appContext";
import { api } from "../helpers";
import Loader from "./Loader";
import date from 'date-and-time';
import { authContext } from "../Context/authContext";
import { customStyles } from "./ScheduleEvent";
import Select from 'react-select'
import { Trash } from "react-feather"

export default function PettyCash({fetchTransactions, branch}){
    const [selectedPetty, setSelectedPetty] = useState(null)
    const [pettyBox, setPettyBox] = useState({})
    const [pettyCashes, setPettyCashes] = useState([])
    const [pettyList, setPettyList] = useState([])
    const {showMessage, setConfirm} = useContext(appContext)
    const {isAllowed, user} = useContext(authContext)
    const [loading, setLoading] = useState(true)
    const dateOptions = { month: 'short', day: '2-digit', year: 'numeric' }

    const fetchPettyCash = () => {
        setLoading(true)
        console.log(branch, user.role)
        if(user.role == 'admin' && !branch?.value) return
        api.get(path.pettyCash + '?id=' + (selectedPetty?.id || '') + '&branchId=' + branch?.value).then(response => {
            let data = response.data
            if(data.success){
                if(selectedPetty) setPettyList(data.data)
                else setPettyCashes(data.data)
            } else showMessage('Something went wrong!', true)
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        if(!pettyBox.visible && user.role) fetchPettyCash()
    }, [pettyBox, selectedPetty, branch, user])

    const getBalance = petty => {
        return parseFloat(petty.pettycashtransacts?.at(0)?.balance_after || petty.budget)?.toLocaleString()
    }

    const togglePettyCash = (id) => {
        return function(){
            return api.delete(path.pettyCash, {
                data: {id}
            }).then(r => {
                setPettyCashes(m => m.map(i => {
                    if(i.id == id) return {...i, closed: r.data.closed}
                    return i
                }))
                showMessage('Petty cash ' + (r.data.closed ? 'closed' : 'opened'), false)
            }).catch(e => showMessage('Something went wrong', true))
        }
    }
    console.log(isAllowed(access.CASHBOOK_CREATE_PETTY_CASH))

    return (
        <div className='flex flex-1 flex-col gap-4 pt-4 items-center cb-container'>
            <div className='flex items-center gap-4 justify-center' style={{flexDirection: 'row', flex: 0, width: 'fit-content'}}>
                {(isAllowed(access.CASHBOOK_CREATE_PETTY_CASH) || selectedPetty) && <buttton onClick={() => selectedPetty ? setSelectedPetty(null) : isAllowed(access.CASHBOOK_CREATE_PETTY_CASH) ? setPettyBox({visible: true, type: 'create', cols: 3}) : ''} className='bg-black text-white font-bold px-4 py-2 rounded-lg shadow-lg cursor-pointer hover:opacity-[0.8]'>
                    {selectedPetty ? 'Back To List' : 'Create Petty Cash' }
                </buttton>}
                {selectedPetty && <buttton onClick={() => setPettyBox({visible: true, type: 'spend', cols: 2})} className='bg-red text-white font-bold px-4 py-2 rounded-lg shadow-lg cursor-pointer hover:opacity-[0.8]'>
                    Spend
                </buttton> }
            </div>
            <Loader text={null} loading={loading} color={MAIN_COLOR} style={{marginTop: 20}} />
            <TransitionGroup>
                <CSSTransition
                    key={selectedPetty}
                    classNames="fade"
                    timeout={500}
                    exit={false}
                >
                    {selectedPetty ? (
                        <table className='relative overflow-auto w-full'>
                            <thead>
                                <tr className='static top-0'>
                                    <th>Amount</th>
                                    <th>Time</th>
                                    <th className='max-md:hidden'>Type</th>
                                    <th>Reason</th>
                                    <th>Receipt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pettyList.map(petty => (
                                    <tr> 
                                       <td className={'font-bold text-md ' + (petty.transact_type == 'in' ? 'text-[#009100]' : 'text-[#ff0000]')}>{petty.amount?.toLocaleString()} Br.</td>
                                       <td>{new Date(petty.createdAt).toLocaleDateString('en-US', dateOptions)} |<span className='max-md:block'> {date.format(new Date(petty.createdAt), 'hh:mm:ss A')}</span></td>
                                        <td className='p1 max-md:hidden'><span className={'font-bold py-1 px-2 text-white ' + (petty.transact_type == 'in' ? 'bg-[#009100]' : 'bg-[#ff0000]')}>{petty.transact_type?.toUpperCase()}</span></td>
                                        <td>{petty.reason}</td>
                                        <td>{petty.proof && <a className='text-[#0000ff] underline' href={baseURL + path.uploads + petty.proof} target='_blank'>View</a>}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> ): (
                            <div className='flex-1 grid grid-cols-3 max-md:grid-cols-1 gap-4 max-md:p-2 overflow-auto'>
                                {pettyCashes.map(petty => (
                                    <div className={'flex flex-col gap-4 shadow-lg rounded-lg p-6 border-[1px] border-[#00000050] ' + (petty.closed ? 'bg-[#00000010] opacity-[0.6]' : 'bg-white')}>
                                        <div className='flex items-center gap-4 self-center'>
                                            <p className='whitespace-nowrap'>Initial Budget: <span className='font-bold'>{petty.budget.toLocaleString()} Br.</span></p>
                                            <div className='w-[1px] h-full bg-[#00000090]'></div>
                                            <p>Balance: <br /> <span className='font-bold'>{getBalance(petty)} Br.</span></p>
                                        </div>
                                        <div className='h-[1px] bg-[#00000090]'></div>
                                        <div className='flex items-center gap-4 self-center'>
                                            <p>Spender: <span className='font-bold'>{petty.user?.username}</span></p>
                                            <div className='w-[1px] h-full bg-[#00000090]'></div>
                                            <p>Created Date: <br /> <span className='font-bold'>{new Date(petty.createdAt).toLocaleDateString('en-US', dateOptions)}</span></p>
                                        </div>
                                        <div className='h-[1px] bg-[#00000090]'></div>
                                        <div className='flex items-center gap-2 self-center'>
                                            <p>Reason: <span className='font-bold'>{petty.reason}</span></p>
                                            {petty.proof && <a className='text-[#0000ff] bg-main text-white p-1 px-4 rounded-sm text-xs' href={baseURL + path.uploads + petty.proof} target='_blank'>View Reciept</a>}
                                            {/* <div className='w-[1px] h-full bg-[#00000090]'></div> */}
                                            {/* <p>Created Date: <span className='font-bold'>0/e/r</span></p> */}
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <button onClick={() => setSelectedPetty(petty)} className='bg-black text-sm text-white font-bold px-4 py-2 rounded-lg shadow-lg self-center cursor-pointer hover:opacity-[0.8]'>Manage</button>
                                            <button disabled={petty.closed} onClick={() => setPettyBox({visible: true, type: 'add_amount', cols: 1, data: petty})} className='bg-black text-sm whitespace-nowrap text-white font-bold px-4 py-2 rounded-lg shadow-lg self-center cursor-pointer hover:opacity-[0.8]'>Add Amount</button>
                                            <buttton onClick={() => setConfirm({
                                                    visible: true,
                                                    action: togglePettyCash(petty.id)
                                             })} className='bg-black text-sm text-white font-bold px-4 py-2 rounded-lg shadow-lg self-center cursor-pointer hover:opacity-[0.8]'>
                                                 {petty.closed ? 'Open' : 'Close'}
                                            </buttton>
                                        </div>
                                    </div>
                                ))}
                         </div>  
                        )}
                    </CSSTransition>
                </TransitionGroup>
           {pettyBox.visible && <PettPrompt type={pettyBox.type} setPettyBox={setPettyBox} data={pettyBox.data || selectedPetty} cols={pettyBox.cols} fetchPettyCash={fetchPettyCash} fetchTransactions={fetchTransactions} _user={user} branch={branch} />}
        </div>
    )
}

function PettPrompt({setPettyBox, type, data, cols, fetchPettyCash, fetchTransactions, balance, branch, _user}){
    const [amount, setAmount] = useState(0)
    const [spender, setSpender] = useState('')
    const [budget, setBudget] = useState(0)
    const [reason, setReason] = useState('')
    const [loading, setLoading] = useState(false)
    const {showMessage, setImageControl} = useContext(appContext)
    const [userOptions, setUserOptions] = useState([])
    const [user, setUser] = useState(null)
    const [proof, setProof] = useState(null)

    const pushPetty = (e)  => {
        e.preventDefault()
        if(_user.role == 'admin' && !branch.value) return
        if(type == 'spend' && data?.pettycashtransacts?.length && parseFloat(data.pettycashtransacts[0]?.balance_after) < amount){
            return showMessage('Amount is greater than left balance', true)
        }
        setLoading(true)
        api[type == 'add_amount' ? 'patch' : 'post'](path.pettyCash + '?branchId=' + branch?.value, {
            type, amount,
            spender: user?.value, budget,
            reason, pettycashId: data?.id,
            proof: proof?.path
        }).then(response => {
            let data = response.data
            if(data.success){
                showMessage('Success.')
                setPettyBox({})
            }
        }).catch(e => showMessage('Something went wrong!', true))
        .finally(() => fetchTransactions('book'))
    }

    const fetchUsers = () => {
        api.get(path.user).then(response => {
            let data = response.data
            if(data.success){
                let uOp = data.users.map(u => ({label: u.username, value: u.id}))
                setUserOptions(uOp)
                setUser(uOp[0])
            }
        })
    }

    useEffect(fetchUsers, [])

    const texts = {
        create: {
            title: 'Create Petty Cash',
            btn: 'Create'
        },
        add_amount: {
            title: 'Add Amount',
            btn: 'Add'
        },
        spend: {
            title: 'Spend Amount',
            btn: 'Spend'
        }
    }
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000080] z-[99999]'>
            <form method="post" onSubmit={pushPetty} className='flex flex-col gap-4 p-4 bg-white p-6 rounded-md'>
                <h2 className="self-center text-[25px] font-bold ">{texts[type].title}</h2>
                <div className={'grid gap-6 feel-c1 bg-[#00000010] p-4 ' + ('grid-cols-' + cols) + ' max-md:grid-cols-1'}>
                    
                    {type == 'create' ? (
                        <>
                            <div className='flex flex-col gap-2'>
                                <p>Spender</p>
                                <Select onChange={setUser} options={userOptions} value={user} styles={customStyles} name="spender" required />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <p>Budget</p>
                                <input value={budget} onChange={e => setBudget(e.target.value)} placeholder='Budget' name="budget" className='input' type='text' required/>
                            </div>
                        </>
                    ) : (
                        <div className='flex flex-col gap-2'>
                            <p>Amount</p>
                            <input value={amount} onChange={e => setAmount(e.target.value)} placeholder='Amount' name="amount" className='input' type='text' required/>
                        </div>
                    )}
                    {type != 'add_amount' && (
                        <div className='flex flex-col gap-2'>
                            <p>Reason</p>
                            <input value={reason} onChange={e => setReason(e.target.value)} placeholder='Optional' name="reason" className='input' type='text' />
                        </div>
                    )}
                    <div className='flex gap-2 items-center '>
                        {proof?.path && <>
                            <img onClick={e => window.open(e.target.src, '_blank')} style={{width: '40px', height: '40px', objectFit: 'contain'}} src={URL.createObjectURL(proof.blob)} />
                            <button onClick={() => setProof(null)}><Trash color='#ff0000' size={15} /></button>
                        </>}
                        <div className='flex flex-col gap-2 justify-center w-full'>
                            <p>Proof / Receipt</p>
                            <button type='button' onClick={() => setImageControl({visible: true, setImage: setProof})} className='py-2 px-8 max-md:px-2 font-bold text-[16px] bg-black text-white self-center w-full'>Take Image</button>
                        </div>
                    </div>
                 
                </div>
                <div className='flex justify-center gap-6'>
                    <button type="submit" className='py-2 px-8 font-bold text-[20px] bg-main text-white self-center' type='submit'>
                        <Loader text={texts[type].btn} loading={loading} />                        
                    </button>
                    <button onClick={() => setPettyBox({})} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Cancel</button>
                </div>
            </form>
        </div>
    )
}