import { useContext, useEffect, useState } from "react"
import { MAIN_COLOR, path } from "../constants"
import { appContext } from "../Context/appContext"
import { api } from "../helpers"
import Loader from "./Loader"

export default function PackageItems({packageId, setShowItems}){
    const {showMessage} = useContext(appContext)
    const [ items ,setItems ] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchItems = () => {
        setLoading(true)
        api.get(path.package + '?id=' + packageId).then(response => {
            const data = response.data
            if(data.success){
                setItems(data.packages.items)
            } else showMessage('Something went wrong', true)
        }).catch(e => showMessage('Something went wrong', true))
        .finally(() => setLoading(false))
    }

    useEffect(fetchItems, [])

    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000090]'>
            <div className='flex flex-col gap-4 p-4 bg-white p-6 max-md:p-2 rounded-md'>
                <h2 className="self-center text-[25px] font-bold ">{`Package Items (${items.length})`}</h2>
                <div className='grid  grid-cols-6 max-md:grid-cols-5 gap-6 feel-c1 bg-[#00000010] p-4'>
                    {items?.map(item => (
                        <div className='flex items-center gap-2'>
                            <label for={'item'+item.id} className='text-[12px] select-none'>{item.name} |</label>
                        </div>
                    ))}
                </div>
                <Loader text={null} color={MAIN_COLOR} style={{alignSelf: 'center'}} loading={loading} />
                <div className='flex justify-center gap-6'>
                    <button onClick={() => setShowItems(false)} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Ok</button>
                </div>
            </div>
    </div>
    )
}