import {Delete} from 'react-feather'
import { useContext, useState, useEffect } from "react";
import { appContext } from "../Context/appContext";
import { api, getByPage } from "../helpers";
import { MAIN_COLOR, path, baseURL } from "../constants";
import Loader from "../Components/Loader";
import InfiniteScroll from 'react-infinite-scroll-component';

export default function Clients(){
    const {toggleAClient, setConfirm, showMessage} = useContext(appContext)
    const [clients, setClients] = useState([])
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(0)
    const [hasMore, setHasMore] = useState(true)

    const fetchClients = () => {
        setLoading(true)
        const limit = 30
        api.get(getByPage(path.client, page)).then(response => {
            const data = response.data
            if(data.success){
                setClients(d => [...d, ...data.clients])
                setPage(p => p + 1)
                if(data.clients.length < limit) setHasMore(false)
            } else showMessage('Something went wrong', true)
        }).catch(e => showMessage('Something went wrong', true))
        .finally(() => setLoading(false))
    }

    const deleteClient = (id) => {
        return function(){
            return api.delete(path.client, {
                data: {id}}
            ).then(r => {
                setClients(m => m.filter(i => i.id != id))
                showMessage('Client deleted', false)
            }).catch(e => showMessage('Something went wrong', true))
            .finally(() => setLoading(false))
        }
    }

    useEffect(fetchClients, [])
    return (
        <div className='flex flex-col flex-1 overflow-auto p-6 gap-6'>
        {/* <button onClick={toggleAClient} className='self-end bg-main px-4 py-2 rounded-sm font-bold  text-white flex items-center gap-2 hover:bg-darker transition-all border-none'>
            Add Client
            <Plus color="#ffffff" size={18} />
        </button> */}
        <p className='text-[16px] uppercase font-bold'>Event Clients</p>
        <div className='overflow-y-auto flex-1 rounded-md bg-white p-6 pt-0 flex flex-col'>
        <InfiniteScroll
            dataLength={clients.length}
            next={fetchClients}
            hasMore={hasMore}
            loader={<div className='w-full flex justify-center'><Loader text={null} loading={hasMore} color={MAIN_COLOR} style={{marginTop: 20, alignSelf: 'center'}} /></div>}
            scrollableTarget="scrollable"
            endMessage={<p className='text-center m-4 text-sm'>No more data to load.</p>}
        >
        <table className='w-full relative overflow-auto mt-6'>
            <thead>
                <tr>
                    <th>
                        Full Name
                    </th>
                    <th>
                        Primary Phone Number
                    </th>
                    <th>
                        Secondary Phone
                    </th>
                    <th>
                        Total Events
                    </th>
                    <th>
                        Photo
                    </th>
                    <th>
                        ID Image
                    </th>
                    <th>
                        Date Created
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {clients.map(client => (
                    <tr>
                        <td>{client.full_name}</td>
                        <td>{client.phone_number}</td>
                        <td>{client.secondary_phone}</td>
                        <td>{client.events?.length}</td>
                        <td><img onClick={e => window.open(e.target.src, '_blank')} className='cursor-pointer w-[30px] h-[30px] object-contain' src={baseURL + path.uploads + client.client_image} /></td>
                        <td><img onClick={e => window.open(e.target.src, '_blank')} className='cursor-pointer w-[30px] h-[30px] object-contain' src={baseURL + path.uploads + client.id_image} /></td>
                        <td>{client.createdAt?.split('.')[0]}</td>
                        <td className=''>
                            <button onClick={() => setConfirm({
                                visible: true,
                                action: deleteClient(client.id)
                                }) } className='border-none bg-none ml-3'>
                                <Delete color="#ff0000" size={20} />
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        </InfiniteScroll>
        {/* {(!loading && !clients.length) && <h4 className='text-center mt-4'>No Data.</h4>}
        <Loader text={null} loading={loading} color={MAIN_COLOR} style={{marginTop: 20, alignSelf: 'center'}} /> */}
        </div>
    </div>
    )
}