import logo from '../../assets/platinum.png'
import {Calendar, Users, Bell, Package, ShoppingCart, Settings, List, UserCheck, LogOut, MapPin, Truck, Globe, BookOpen, Gift} from 'react-feather'
import {Link, useLocation, useNavigate, useNavigation} from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import Clock from 'react-clock'
import date from 'date-and-time';
import 'react-clock/dist/Clock.css';
import { convertEG } from '../../helpers'
import { access, baseURL, path, ETHIOPIAN_DAYS, ETHIOPIAN_MONTHS } from '../../constants'
import { authContext } from '../../Context/authContext'
import { appContext } from '../../Context/appContext'

export default function SideBar(){
    const location = useLocation()
    const _path = location.pathname
    const navigate  = useNavigate()

    const [value, setValue] = useState(new Date());
    const [e_year, e_month, e_date] = convertEG(new Date())

    const {logOut, user} = useContext(authContext)
    const {showSidebar, setShowSidebar, changeLang, t, lang} = useContext(appContext)

    const links = [
        {path: '/', title: t('CALENDAR'), Icon: Calendar, access: access.CALENDAR},
        {path: '/clients', title: t('CLIENTS'), Icon: Users, access: access.CLIENTS},
        {path: '/events', title: t('EVENTS'), Icon: Bell, access: access.VIEW_EVENTS},
        {path: '/cashbook', title: t('CASHBOOK'), Icon: BookOpen, access: [access.CASHBOOK_IN_OUT, access.CASHBOOK_VIEW_BALANCE, access.CASHBOOK_PETTY_CASH, access.APPROVE_PENDING_TRANSACTIONS, access.SETTLE_ADVANCE_PAYMENT, access.CASHBOOK_SPEND_PETTY_CASH, access.CASHBOOK_CREATE_PETTY_CASH]},
        {path: '/deliverables', title: t('DELIVERABLES'), Icon: Gift, access: access.VENDORS},
        {path: '/vendors', title: t('VENDORS'), Icon: Truck, access: access.VENDORS},
        {path: '/items', title: t('ITEMS'), Icon: ShoppingCart, access: access.ITEMS},
        {path: '/packages', title: t('PACKAGES'), Icon: Package, access: access.PACKAGES},
        {path: '/users', title: t('APP_USERS'), Icon: UserCheck, access: access.APP_USERS},
        {path: '/employees', title: t('EMPLOYEES'), Icon: Users, access: access.EMPLOYEES},
        {path: '/branches', title: t('BRANCHES'), Icon: MapPin, access: access.BRANCHES},
        {path: '/action-logs', title: t('ACTION_LOGS'), Icon: List, access: access.ACTION_LOGS},
        {path: '/settings', title: t('SYSTEM_SETTINGS'), Icon: Settings, access: access.SYSTEM_SETTINGS}
    ]

    useEffect(() => {
      const interval = setInterval(() => setValue(new Date()), 1000);
  
      return () => {
        clearInterval(interval);
      };
    }, []);

    const now = new Date();

    return (
        <div className={'z-[99999] w-[200px] max-md:absolute top-0 shadow-sm bg-white h-full gap-4 flex flex-col pt-4 pb-6 overflow-auto transition-all duration-500 ease-in-out ' + (showSidebar ? 'max-md:translate-x-[0]' : 'max-md:translate-x-[-100%]')}>
            <img src={baseURL + path.uploads + user.tenant?.logo} className='w-[100px] object-contain self-center mt-4' />
            <div className='flex flex-col overflow-auto'>
                {links.filter(lnk => Array.isArray(lnk.access) ? user.access?.some(ua => lnk.access.includes(ua)) : user.access?.includes(lnk.access)).map(item => (
                    <Link to={item.path} onClick={() => setShowSidebar(false)} className={`flex items-center gap-2 p-3 ${_path == item.path ? 'bg-main' : ''}`}>
                        <item.Icon color={_path == item.path ? "#ffffff" : '#000000'} size={20} />
                        <p style={{color: _path == item.path ? "#ffffff" : '#000000'}}>{item.title}</p>
                    </Link>
                ))}
            </div>
            <div className='flex justify-around mt-[auto]'>
                <div className='flex items-center gap-1'>
                    {/* <label for='lang' className='text-[11px]'>Language</label> */}
                    <Globe color='#000000' size={13} />
                    <select id="lang" onChange={e => changeLang(e.target.value)} className='text-[13px] outline-none'>
                        <option value='en' selected={lang == 'en'}>English</option>
                        <option value='am' selected={lang == 'am'}>አማርኛ</option>
                    </select>
                </div>
                <button onClick={() => {
                    logOut()
                    setTimeout(() => navigate('/'), 1000)
                }}>
                    <LogOut color='#000000' size={20} />
                </button>
            </div>
            <div className='flex flex-col items-center gap-2 p-4 border-[1px] border-[#00000050] m-3 rounded-md'>
                <p>{t('TODAY')}</p>
                <p className='font-bold uppercase text-main text-[13px]'>{date.format(now, 'ddd, MMM DD YYYY')}</p>
                <p className='font-bold uppercase text-main text-center text-[13px]'>{`${ETHIOPIAN_DAYS[value?.getDay()]}`} <br /> {`${ETHIOPIAN_MONTHS[e_month - 1]} ${e_date} ${e_year}`}</p>
                {/* <Clock value={value} size={120} /> */}
            </div>
        </div>
    )
}