import { useContext, useEffect, useRef, useState } from "react"
import { path } from "../constants"
import { appContext } from "../Context/appContext"
import { authContext } from "../Context/authContext"
import { api } from "../helpers"
import Loader from "./Loader"

export default function AddItem(){
    const {toggleAItem, showAddItem, showMessage} = useContext(appContext)
    const [loading, setLoading] = useState(false)
    const nameRef = useRef()

    const addOrEditItem = (e) => {
        e.preventDefault()
        setLoading(true)
        api[showAddItem.data ? 'patch' : 'post'](path.item, {
            name: e.target.name.value,
            id: showAddItem.data?.id
        }).then(response => {
            if(response.data.success) {
                toggleAItem()
                showMessage(`Item successfully ${showAddItem.data ? 'updated' : 'added'}!`, false)
            }
            else showMessage('Error has been occured!', true)
        })
        .catch(e => showMessage('Error has been occured!', true))
        .finally(() => setLoading(false))

    }

    useEffect(() => {
        if(nameRef.current) nameRef.current.value = showAddItem.data?.name || ''
    }, [showAddItem])

    if(!showAddItem.visible) return null
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000080]'>
            <form method="post" onSubmit={addOrEditItem} className='flex flex-col gap-4 p-4 bg-white p-6 rounded-md'>
                <h2 className="self-center text-[25px] font-bold ">{showAddItem.data ? 'Edit Item' : 'Add Item'}</h2>
                <div className='flex gap-6 feel-c1 bg-[#00000010] p-4'>
                    <div className='flex flex-col gap-2'>
                        <p>Item Name</p>
                        <input placeholder='Use Amharic' ref={nameRef} name="name" className='input' type='text' required/>
                    </div>
                    {/* <div className='flex flex-col gap-2 justify-center'>
                        <p>Item Image</p>
                        <button onClick={() => setImageControl({visible: true})} className='py-2 px-8 font-bold text-[16px] bg-black text-white self-center' type='submit'>Item Image ( Optinal )</button>
                    </div> */}
                </div>
                <div className='flex justify-center gap-6'>
                    <button type="submit" className='py-2 px-8 font-bold text-[20px] bg-main text-white self-center' type='submit'>
                        <Loader text={showAddItem.data ? 'Update' : 'Add Item'} loading={loading} />                        
                    </button>
                    <button onClick={() => toggleAItem()} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Cancel</button>
                </div>
            </form>
        </div>
    )
}