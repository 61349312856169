import { createContext, useContext, useEffect, useRef, useState } from "react"
import AddBranch from "../Components/AddBranch"
import AddClient from "../Components/AddClient"
import AddEmployee from "../Components/AddEmployee"
import AddItem from "../Components/AddItem"
import AdditionalItems from "../Components/AdditionalItems"
import AddPackage from "../Components/AddPackage"
import AddUser from "../Components/AddUser"
import AddVendor from "../Components/AddVendor"
import Confirm from "../Components/Confirm"
import EventDetail from "../Components/EventDetail"
import EventEmployees from "../Components/EventEmployees"
import EventVendors from "../Components/EventVendors"
import ImageTaker from "../Components/ImageTaker"
import Message from "../Components/Notification/Message"
import SalaryWage from "../Components/SalaryWage"
import ScheduleEvent from "../Components/ScheduleEvent"
import SelectItems from "../Components/SelectItems"
import VendorLedger from "../Components/VendorLedger"
import { baseURL, path } from "../constants"
import { api, getLang } from "../helpers"
import { authContext } from "./authContext"

export const appContext = createContext()

export default function AppContext({ children }){
    const messageRef = useRef()
    const {token} = useContext(authContext)
    const [showAddClient, setShowAddClient] = useState(false)
    const toggleAClient = () => setShowAddClient(s => !s)

    const [showAddSchedule, setShowAddSchedule] = useState(false)
    const toggleASchedule = (item) => setShowAddSchedule(s => ({visible: !s.visible, data: item}))

    const [showAddItem, setShowAddItem] = useState({})
    const toggleAItem = (item) => setShowAddItem(s => ({visible: !s.visible, data: item}))

    const [showAddVendor, setShowAddVendor] = useState({})
    const toggleAVendor = (item) => setShowAddVendor(s => ({visible: !s.visible, data: item}))

    const [showAddPackage, setShowAddPackage] = useState({})
    const toggleAPackage = (item) => setShowAddPackage(s => ({visible: !s.visible, data: item}))

    const [showAddBranch, setShowAddBranch] = useState({})
    const toggleABranch = (b) => setShowAddBranch(s => ({visible: !s.visible, data: b}))

    const [showAddEmployee, setShowAddEmployee] = useState({})
    const toggleAEmployee = (e) => setShowAddEmployee(s => ({visible: !s.visible, data: e}))

    const [showSalaryWage, setShowSalaryWage] = useState({})
    const toggleShowSalaryWage = (item) => setShowSalaryWage(s => ({visible: !s.visible, data: item}))

    const [showVLedger, setShowVLedger] = useState({})
    const toggleShowVLedger = (item) => setShowVLedger(s => ({visible: !s.visible, data: item}))

    const [showEvent, setShowEvent] = useState({})
    const toggleShowEvent = (item) => setShowEvent(s => ({visible: !s.visible, data: item}))

    const [showSelectItems, setShowSelectItems] = useState({items: []})
    const toggleSItems = () => setShowSelectItems(s => ({...s, visible: !s.visible}))
    const resetSItems = () => setShowSelectItems(s => ({...s, items: []}))
    const addRmvSItem = (e) => setShowSelectItems(s => {
        let new_items = e.target.checked ? [...(s.items || []), e.target.value] : s.items.filter(i => i != e.target.value)
        return ({...s, items: new_items})
    })

    const [showAdditionalItems, setShowAdditionalItems] = useState({items: []})
    const toggleAItems = () => setShowAdditionalItems(s => ({...s, visible: !s.visible}))
    const resetAItems = () => setShowAdditionalItems(s => ({...s, items: []}))
    const addRmvAItem = (e) => setShowAdditionalItems(s => {
        if(e.target.id == 'add' && s.items?.some(i => i == e.target.value)){ showMessage('Duplicate', true); return s }
        let new_items = !(s.items?.some(i => i == e.target.value)) ? [...(s.items || []), e.target.value] : s.items.filter(i => i != e.target.value)
        return ({...s, items: new_items})
    })

    const [showSelectEmployees, setShowSelectEmployees] = useState({employees: []})
    const toggleSEmployees = (data) => setShowSelectEmployees(s => ({...s, visible: !s.visible, employees: data.employees.map(e => e.id), data: data, hadData: !!data.employees.length}))
    const resetSIEmployees = () => setShowSelectEmployees(s => ({...s, employees: []}))
    const addRmvSEmployee = (e) => setShowSelectEmployees(s => {
        let new_emps = e.target.checked ? [...(s.employees || []), e.target.value] : s.employees.filter(i => i != e.target.value)
        return ({...s, employees: new_emps})
    })

    const [showSelectVendors, setShowSelectVendors] = useState({vendors: []})
    const toggleSVendors = (data) => setShowSelectVendors(s => ({...s, visible: !s.visible, vendors: data.vendors?.map(e => e.id) || [], data: data, hadData: !!data.vendors.length}))
    const resetSIVendors = () => setShowSelectVendors(s => ({...s, vendors: []}))
    const addRmvSVendors = (e) => setShowSelectVendors(s => {
        let new_vends = e.target.checked ? [...(s.vendors || []), e.target.value] : s.vendors.filter(i => i != e.target.value)
        return ({...s, vendors: new_vends})
    })

    const [showAddUser, setShowAddUser] = useState({})
    const toggleAUser = (item) => setShowAddUser(s => ({visible: !s.visible, data: item}))

    const [confirm, setConfirm] = useState({})

    const [imageControl, setImageControl] = useState({})

    const[settings, setSettings] = useState({})
    const [loadingSettings, setLoadingSettings] = useState(true)

    const showMessage = (msg, error) => {
        messageRef.current?.((error ? '❗️ ' : '✅ ' ) + msg)
    }

    const [showSidebar, setShowSidebar] = useState(false)

    useEffect(() => {
        if(token){
            setLoadingSettings(true)
            api.get(path.settings).then(response => {
                const data = response.data
                if(data.success){
                    setSettings(data.settings?.reduce((ac, cu) => {
                        ac[cu.name] = cu.value
                        return ac
                    }, {}))
                }
            }).catch(e => showMessage('Error has been occured!', true))
            .finally(() => {
                setLoadingSettings(false)
            })
        }
    }, [token])

    function isMobileDevice() {
        return /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent)
    }

    const [lang, setLang] = useState(localStorage.getItem('lang') || 'en')
    const changeLang = (lng) => {
        setLang(lng)
        localStorage.setItem('lang', lng)
    }

    const t = (word) => getLang(lang, word)

    return (
        <appContext.Provider value={{ 
            messageRef, showMessage, showAddClient, toggleAClient,
            imageControl, setImageControl, showAddSchedule, setShowAddSchedule, toggleASchedule,
            showAddItem, toggleAItem, showAddPackage, toggleAPackage, showSelectItems,
            setShowSelectItems, toggleSItems, showAddUser, toggleAUser, confirm, setConfirm,
            resetSItems, addRmvSItem, setShowAddBranch, showAddBranch, toggleABranch,
            showAddEmployee, setShowAddEmployee, toggleAEmployee, showSelectEmployees,
            setShowSelectEmployees, toggleSEmployees, resetSIEmployees, addRmvSEmployee,
            showSelectVendors, setShowSelectVendors, toggleSVendors, resetSIVendors, addRmvSVendors,
            settings, loadingSettings, setSettings,showAdditionalItems, setShowAdditionalItems,
            resetAItems, addRmvAItem, toggleAItems, showAddVendor, setShowAddVendor, toggleAVendor,
            showSidebar, setShowSidebar, showVLedger, setShowVLedger, toggleShowVLedger, isMobileDevice,
            showSalaryWage, setShowSalaryWage, toggleShowSalaryWage, lang, changeLang, t, showEvent,
            toggleShowEvent
        }}>
            {children}
            <Message
                children={(add) => {
                messageRef.current = add
                }}
            />
            {token && 
                <>
                    <AddClient />
                    {showAddSchedule.visible && <ScheduleEvent />}
                    <AddItem />
                    <AddVendor />
                    <AddPackage />
                    <AddUser />
                    <SelectItems />
                    <AdditionalItems />
                    <Confirm />
                    <AddBranch />
                    <AddEmployee />
                    {imageControl.visible && <ImageTaker />}
                    <EventEmployees />
                    <EventVendors />
                    <VendorLedger/>
                    <SalaryWage />
                    {showEvent.visible && <EventDetail />}
                </>
            }
        </appContext.Provider>
    )
}