import { useContext, useEffect, useRef, useState } from "react"
import { Delete } from "react-feather"
import { path } from "../constants"
import { appContext } from "../Context/appContext"
import { api } from "../helpers"
import Loader from "./Loader"

export default function AddPackage(){
    const {toggleAPackage, showAddPackage, toggleSItems, showSelectItems, showMessage, setShowSelectItems} = useContext(appContext)
    const [pricing, setPricing] = useState([{}])
    const [loading, setLoaing] = useState(false)
    const nameRef = useRef()

    const addOrEditPackage = (e) => {
        e.preventDefault()
        if(!showSelectItems.items?.length) return showMessage('atleast select one item', true)
        if(!pricing.length) return showMessage('atleat add one pricing', true)
        setLoaing(true)
        let data = {
            name: e.target.name.value,
            items: showSelectItems.items || [],
            pricing: pricing,
            id: showAddPackage.data?.id
        }
        api[showAddPackage.data ? 'patch' : 'post'](path.package, data).then(response => {
            const data = response.data
            if(data.success){
                showMessage('Package added successfully', false)
                setLoaing(false)
                toggleAPackage()
                setShowSelectItems({})
                setPricing([])
            } else showMessage('Something went wrong', true)
        }).catch(e => showMessage('Something went wrong', true) )
        .finally(() => setLoaing(false))
    }

    useEffect(() => {
        if(showAddPackage.data){
            if(nameRef.current) nameRef.current.value = showAddPackage.data?.name
            setPricing(showAddPackage.data.prices)
            setShowSelectItems({...showSelectItems, items: showAddPackage.data.items?.map(s => s.id) || []})
        }
    }, [showAddPackage.data])

    if(!showAddPackage.visible) return null
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000080]'>
            <form method="post" onSubmit={addOrEditPackage} className='flex flex-col gap-4 p-4 bg-white p-6 rounded-md'>
                <h2 className="self-center text-[25px] font-bold ">{showAddPackage.data ? 'Edit Package' : 'Add Package'}</h2>
                <div className='grid  grid-cols-2 gap-6 feel-c1 bg-[#00000010] p-4'>
                    <div className='flex flex-col gap-2'>
                        <p>Package Name</p>
                        <input name="name" ref={nameRef} className='input' type='text' required/>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>Package Items</p>
                        <button onClick={e => {e.preventDefault(); toggleSItems()}} className='py-2 px-8 font-bold text-[16px] bg-black text-white'>Select Items</button>
                    </div>
                </div>
                <div className='border-t-[1px] border-t-[#00000080] border-b-[1px] border-b-[#00000080] py-4 flex flex-col gap-4'>
                    <p className='self-center uppercase'>Pricing</p>
                    {pricing.map((price, index) => (
                        <div className='flex gap-2 items-center justify-center'>
                            <div className='flex gap-1 items-center'>
                                <p className='text-[11px]'>Minimum</p>
                                <input name={'min' + (index + 1)} value={price.min} onChange={e => setPricing(pricing.map((p, ind) => {
                                    if(ind == index) return ({...p, min: e.target.value})
                                    return p
                                }))} className='p-1 border-[1px] border-black w-[100px] rounded-md' type='number' required/>
                            </div>
                            <div className='flex gap-1 items-center'>
                                <p className='text-[11px]'>Maximum</p>
                                <input name={'max' + (index + 1)} value={price.max} onChange={e => setPricing(pricing.map((p, ind) => {
                                    if(ind == index) return ({...p, max: e.target.value})
                                    return p
                                }))} className='p-1 border-[1px] border-black w-[100px] rounded-md' type='number' required />
                            </div>
                            <div className='flex gap-1 items-center'>
                                <p className='text-[11px]'>Value</p>
                                <input name={'value' + (index + 1)} value={price.value} onChange={e => setPricing(pricing.map((p, ind) => {
                                    if(ind == index) return ({...p, value: e.target.value})
                                    return p
                                }))} className='p-1 border-[1px] border-black w-[100px] rounded-md' type='number' required/>
                            </div>
                            <button onClick={(e) => {e.preventDefault(); setPricing(pricing.filter((p, ind) => ind != index))}}>
                                <Delete size={15} color="#ff0000" />
                            </button>
                        </div>
                    ))}
                    <button className='bg-[#00000090] text-white p-2 text-[13px] border-none rounded-sm' onClick={(e) => {e.preventDefault(); setPricing([...pricing, {}])}}>Add Pricing</button>
                </div>
                <div className='flex justify-center gap-6'>
                    <button type="submit" className='py-2 px-8 font-bold text-[20px] bg-main text-white self-center' type='submit'>
                        <Loader text={showAddPackage.data ? 'Update' : 'Add Package'} loading={loading} />
                    </button>
                    <button onClick={(e) => {
                        e.preventDefault();
                        toggleAPackage()
                        setShowSelectItems({})
                        setPricing([])
                    }} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center'>Cancel</button>
                </div>
            </form>
    </div>
    )
}