import { useEffect, useState } from 'react'
import Select from 'react-select'
import { ETHIOPIAN_DAY_DIVISION, ETHIOPIAN_TIME_DIVISION } from '../../constants'

const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '50px', 
      height: '50px',
      width: '200px'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '50px',
      padding: '0 8px',
    })
}


const ET_DDivision = ETHIOPIAN_DAY_DIVISION.map((y, i) => ({label: y, value: i}))
const ET_TDivision = ETHIOPIAN_TIME_DIVISION.map((y, i) => ({label: y, value: i}))

const ET_TIME_1 = [12, 1, 2, 3, 4, 5].map((y, i) => ({label: y, value: y}))
const ET_TIME_2 = [6, 7, 8, 9, 10, 11].map((y, i) => ({label: y, value: y}))
const ET_TIME_3 = [11, ...Array(11).keys()].map(i => i + 1).map((y, i) => ({label: y, value: y}))

export default function ETTimePicker({selectETTime, setShowETTime}){
    const [cat, setCat] = useState(ET_DDivision[0])
    const [time, setTime] = useState(ET_TIME_1[0])
    const [tDivision, setTDivision] = useState(ET_TDivision[0])
    const time_divisions = [ ET_TIME_1, ET_TIME_2, ET_TIME_3 ]
    const [timeOptions, setTimeOptions] = useState(time_divisions[0])

    useEffect(() => {
        setTimeOptions(time_divisions[cat.value])
        setTime(time_divisions[cat.value][0])
    }, [cat])

    const convertToGTime = (day_div, t, time_div) => {
        let hour, minute, group
        switch(day_div) {
            case 0:
                hour = t == 12 ? 6 : t + 6
                group = 'AM'
                break
            case 1:
                hour = t == 6 ? 12 : t - 6
                group = 'PM'
                break
            case 2:
                if(hour > 5) {
                    group = 'AM'
                    hour = t == 6 ? 12 : t - 6
                }
                else {
                    hour = t == 12 ? 6 : t + 6
                    group = 'PM'
                }
        }
        if(time_div == 3 && !timeOptions.map(t => t.label).includes(t == 1 ? 12 : t - 1)){
            alert('Time is outside of zone ')
            return null
        }
        hour = time_div == 3 ? hour == 1 ? 12 : hour - 1 : hour
        minute = ['00', '15', '30', '45'][time_div]
        return [hour, minute, group]
    }

    const setGrTime = () => {
        const _time = convertToGTime(cat.value, time.value, tDivision.value)
        selectETTime(_time)
    }
    
    return (
        <div className='dim'>
            <div className='alert-box'>
                <div className='flex gap-4 max-md:flex-col'>
                    <div className='flex flex-col gap-2'>
                        <p>ምድብ</p>
                        <Select styles={customStyles} options={ET_DDivision} value={cat} onChange={setCat}  />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>ስዓት</p>
                        <div className='flex items-center gap-2'>
                            {tDivision?.value == 3 && <p>ለ</p>}
                            <Select styles={customStyles} options={timeOptions} value={time} onChange={setTime} />
                        </div>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>ደቂቃ</p>
                        <Select styles={customStyles} options={ET_TDivision} value={tDivision} onChange={setTDivision} />
                    </div>
                </div>
                <div className='flex items-center gap-4'>
                    <button
                        onClick={setGrTime}
                        className='px-6 py-2 text-md font-bold text-white bg-main rounded-md border-none'>SET
                    </button>
                    <button
                        onClick={() => setShowETTime(false)}
                        className='bg-none border-[1px] border-main text-black text-md px-6 py-2 rounded-md'>
                        Cancel
                    </button>
                </div>
                        
            </div>
        </div>
    )
}