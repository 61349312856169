import { Link } from "react-router-dom";
import {Plus, Edit, Delete} from 'react-feather'
import { useContext, useEffect, useState } from "react";
import { appContext } from "../Context/appContext";
import { api } from "../helpers";
import { MAIN_COLOR, path } from "../constants";
import Loader from "../Components/Loader";

export default function Items(){
    const {toggleAItem, showMessage, setConfirm, showAddItem} = useContext(appContext)
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchItems = () => {
        setLoading(true)
        api.get(path.item).then(response => {
            const data = response.data
            if(data.success){
                setItems(data.items)
            } else showMessage('Something went wrong', true)
        }).catch(e => showMessage('Something went wrong', true))
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        if(!showAddItem.visible) fetchItems()
    }, [showAddItem])

    const deleteItem = (id) => {
        return function(){
            return api.delete(path.item, {
                data: {id}}
            ).then(r => {
                setItems(m => m.filter(i => i.id != id))
                showMessage('Item deleted', false)
            }).catch(e => showMessage('Something went wrong', true))
            .finally(() => setLoading(false))
        }
    }

    return (
        <div className='flex flex-col flex-1 overflow-auto p-6 gap-6'>
        <button onClick={() => toggleAItem()} className='self-end bg-main px-4 py-2 rounded-sm font-bold  text-white flex items-center gap-2 hover:bg-darker transition-all border-none'>
            Add Item
            <Plus color="#ffffff" size={18} />
        </button>
        <p className='text-[16px] uppercase font-bold'>All Items</p>
        <div className='overflow-y-auto flex-1 rounded-md bg-white p-6 flex flex-col'>
        <table className='w-full'>
            <thead>
                <tr>
                    <th>
                        Item Name
                    </th>
                    <th>
                        Date Created
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {items.map(item => (
                    <tr className='hover:bg-[#00000040] cursor-pointer'>
                        <td>{item?.name}</td>
                        <td>{item?.createdAt?.split('.')[0]}</td>
                        <td className=''>
                            <button onClick={() => toggleAItem(item)} className='border-none bg-none'>
                                <Edit color='#000000' size={20} />
                            </button>
                            <button onClick={() => setConfirm({
                                visible: true,
                                action: deleteItem(item.id)
                                }) } className='border-none bg-none ml-3'>
                                <Delete color="#ff0000" size={20} />
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        {(!loading && !items.length) && <h4 className='text-center mt-4'>No Data.</h4>}
        <Loader text={null} loading={loading} color={MAIN_COLOR} style={{marginTop: 20, alignSelf: 'center'}} />
        </div>
    </div>
    )
}