import {Plus, Info, PhoneCall, ArrowUp, ArrowLeft} from 'react-feather'
import Calendar from 'react-calendar';
import { useContext, useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { access, baseURL, COMPANY_NAME, ETHIOPIAN_MONTHS, MAIN_COLOR, path } from '../constants';
import { api, convertEG } from '../helpers';
import {
    TransitionGroup,
    CSSTransition
  } from "react-transition-group";
import { appContext } from '../Context/appContext';
import Loader from '../Components/Loader';
import { getEtDate } from '../Components/ScheduleEvent';
import { convertTo12HourFormat } from './Events';
import { authContext } from '../Context/authContext';
import ETCalendar from '../Components/ETCalendar/ETCalendar';

export default function CalendarView(){
    const [value, onChange] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null)
    const {toggleASchedule, showMessage, t} = useContext(appContext)
    const [loadingCal, setLoadingCal] = useState(true)
    const [daysEventLoading, setDaysEventLoading] = useState(true)
    const [calendarData, setCalendarData] = useState({})
    const {user, isAllowed} = useContext(authContext)
    const [EC, setEC] = useState(true)

    function fetchCalendar(){
        api.get(baseURL + path.calendar).then(response => {
            const data = response.data
            console.log(data)
            setCalendarData(data)
        }).catch(e => showMessage(t('SOMETHING_WRONG'), true))
        .finally(() => {setLoadingCal(false); setDaysEventLoading(false)})
    }

    useEffect(() => {
        fetchCalendar()
    }, [])
   
    return (
        <div className='flex flex-1 flex-col bg-white rounded-md shadow-md p-6 max-md:p-2 gap-6 overflow-hidden'>
            <div className='flex justify-between items-center'>
                
                <div className='flex items-center gap-4 max-md:gap-1'>
                        <h2 className='text-[25px] max-md:hidden'>{t('CALENDAR')}</h2>
                        <div className={`p-2 text-sm border-[1px] cursor-pointer select-none ${EC ? 'text-white bg-main' : 'text-black bg-white border-main'} shadow-lg rounded-md`} onClick={e => setEC(true)}>
                            <p>{t('ETHIOPIAN')}</p>
                        </div>
                        <div className={`p-2 text-sm cursor-pointer select-none ${!EC ? 'text-white bg-main' : 'text-black bg-white border-main border-[1px]'} shadow-lg rounded-md`} onClick={e => setEC(false)}>
                            <p>{t('GREGORIAN')}</p>
                        </div>
                    </div>
                {isAllowed(access.CREATE_EVENT) && <button onClick={() => toggleASchedule()} className='max-md:text-[15px] max-md:px-2 px-6 py-2 rounded-sm text-white font-bold bg-main border-none outline-none pointer flex items-center gap-2 hover:bg-darker transition-all'>
                    {t('SCHEDULE_EVENT')}
                    <Plus color="#ffffff" size={18} />
                </button> }
            </div>

            <div className='flex justify-end flex-1 overflow-auto'>
                <div className='flex flex-1 calendar-event overflow-hidden relative'>
                    <TransitionGroup>
                        <CSSTransition
                            key={selectedDate}
                            classNames="fade"
                            timeout={500}
                            exit={false}
                        >
                            {loadingCal ? <div className='flex flex-1 flex-col bg-white gap-2 justify-center items-center'>
                                    <p className='text-black text-lg font-bold'>{t('LOADING_CALENDAR')}...</p>
                                    <Loader loading={true} color={MAIN_COLOR} /> 
                            </div> :
                            selectedDate ? <DayEvents date={selectedDate} setSelectedDate={setSelectedDate} />
                            :
                            EC ? <ETCalendar
                                    onClickDay={(value) => {
                                        setSelectedDate(value)
                                    }}
                                    tileContent={({ date }) => {
                                        return <CustomTile date={date} events={calendarData.event_map || {}} ec />
                                    }}
                            /> :
                            <Calendar onChange={onChange} value={value}
                                tileClassName={({ date }) => {
                                // console.log(date)
                                // return true ? 'reserved' : null
                                }}
                                onClickDay={(value, event) => {
                                    
                                    setSelectedDate(value)
                                }}
                                tileContent={({ date, view }) => {
                                    if(view != 'month') return null
                                    return <CustomTile date={date} events={calendarData.event_map || {}} />
                                }}
                                onViewChange={d =>  console.log(d)}
                            />
                            
                            }
                        </CSSTransition>
                    </TransitionGroup>
                    
                </div>
                <div className='flex-1 flex gap-6 overflow-y-auto max-md:hidden'>
                    {/* today */}
                    <div className='flex flex-col gap-3 flex-1 items-center overflow-y-auto p-4'>
                        <h2>{t('TODAY_EVENTS')} <span className='bg-main rounded-full text-white font-bold inline-flex items-center justify-center w-[25px] h-[25px]'>{calendarData.today_events?.length}</span></h2>
                        <div className='h-[1px] bg-black w-full opacity-[0.5]'></div>
                        {!daysEventLoading && !calendarData.today_events?.length && <p>{t('NO_EVENTS')}</p>}
                        {
                            daysEventLoading ? 
                            <>
                                <ProgramSkeleton />
                                <ProgramSkeleton />
                            </>
                             :
                             <>
                                {calendarData.today_events?.map(ev => <EventProgram event={ev} /> )}
                                {/* <button className='text-[12px] px-6 py-2 rounded-sm text-white font-bold bg-main border-none outline-none pointer flex items-center gap-2 transition-all hover:bg-darker'>
                                    View All Today's Events
                                    <ArrowUp color="#ffffff" size={18} />
                                </button> */}
                            </>
                        }
                        
                        
                    </div>
                    <div className='flex flex-col gap-3 flex-1 items-center p-2 overflow-y-auto'>
                        <h2>{t('TOMMOROW_EVENTS')} <span className='bg-main rounded-full text-white font-bold inline-flex items-center justify-center w-[25px] h-[25px]'>{calendarData.tommorow_events?.length}</span></h2>
                        <div className='h-[1px] bg-black w-full opacity-[0.5]'></div>
                        {!daysEventLoading && !calendarData.tommorow_events?.length && <p>{t('NO_EVENTS')}</p>}
                        {
                            daysEventLoading ? 
                            <>
                                <ProgramSkeleton />
                                <ProgramSkeleton />
                            </>
                             :
                             <>
                                {calendarData.tommorow_events?.map(ev => <EventProgram event={ev} upcomming /> )}
                            </>
                        }
                        <p className='text-[#00000080 text-[15px]'>{t('VIEW_MORE_CALENDAR')}.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

function countDayEvents(events, date){
    let count = Object.values(events).filter(e => {
        return (new Date(e)).toDateString() == date.toDateString()
    })
    return count.length
}

function CustomTile({ date, events, ec }){
    const [_, month, day] = convertEG(date)
    const count = countDayEvents(events, date)
    return ( 
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', paddingTop: '1px', position: 'relative', alignSelf: 'stretch'  }}>
            {/* {!ec && <>
                <div style={{ width: '100%', height: '1px', backgroundColor: '#00000070' }}></div>
                <p style={{ fontSize: 9 }}>{ETHIOPIAN_MONTHS[month - 1]}</p>
                <p style={{ fontSize: 9 }}>{day}</p>
            </> } */}
            {!!count && <span className='max-md:w-[17px] max-md:h-[17px] w-[20px] h-[20px] text-[12px] max-md:text-[10px] max-md:translate-y-[3px]' style={{position: 'absolute', bottom: ec ? 2 : -14, left: ec ? 4 : -6, backgroundColor: '#004a59', color: 'white', borderRadius: '50%', fontWeight: 'bold', textAlign: 'center'}}>
                <span className={`${ec ? 'max-md:translate-y-[1px]' : 'max-md:translate-y-[-1px]'} inline-block font-bold`}>{count}</span>
            </span> }
        </div>
    )
}

function EventProgram({ event, upcomming, large }){
    const time = convertTo12HourFormat(event.time)
    const {toggleShowEvent, t} = useContext(appContext)
    return (
        <div onClick={() => toggleShowEvent(event)} className={'flex shadow-lg rounded-sm flex-col self-stretch border-[1px] gap-2 cursor-pointer hover:bg-[#00000020] transition-all ' + (large ? 'p-4' : 'p-2')}>
            <div className='flex justify-between'>
                <div className='flex-flex-col'>
                    <div className='flex items-center gap-1'>
                        <Info color="#00000070" size={14} />
                        <p className='text-black text-[13px]'>{t('PEOPLE')}: <span className='font-bold ml-1'>{event.num_of_people?.toLocaleString()}</span></p>
                    </div>
                    <div className='flex items-center gap-1'>
                        <Info color="#00000070" size={14} />
                        <p className='text-black text-[13px]'>{t('PACKAGE')}: <span className='font-bold ml-1'>{event.package?.name || 'Custom'}</span></p>
                    </div>
                    <div className='flex items-center gap-1'>
                        <Info color="#00000070" size={14} />
                        <p className='text-black text-[13px]'>{t('ADVANCE')}: <span className='font-bold ml-1'>{event.advance_amount?.toLocaleString()} {t('BR')}.</span></p>
                    </div>
                    <div className='flex items-center gap-1'>
                        <Info color="#00000070" size={14} />
                        <p className='text-black text-[13px]'>{t('BRANCH')}: <span className='font-bold ml-1'>{event.branch?.name}</span></p>
                    </div>
                </div>
                {/* <div className='w-[1px] bg-black opacity-[0.3] mr-4'></div> */}
                
            </div>
            <div className='h-[1px] bg-black opacity-[0.2] mt-2'></div>
            <h1 className='text-[20px] self-center'>{event.client?.full_name}</h1>
            <div className='h-[1px] bg-black opacity-[0.2]'></div>
            <div className='flex items-center gap-3 self-center'>
                <PhoneCall color="#000000" size={20} />
                <h1>{event.client?.phone_number}</h1>
            </div>
             <p className='self-center py-1 px-3 bg-[#00000070] text-white font-bold text-[11px]'>{!upcomming ? time : `${event.et_date} | ${event.event_date.split('T')[0]} | ${time}`}</p>
        </div>
    )
}

function ProgramSkeleton(){
    return (
        <div className='w-[273px] h-[200px] shadow-lg rounded-sm animate-pulse p-4 flex flex-col gap-4'>
            <div className='flex gap-4'>
                <div className='flex flex-col gap-2 flex-1'>
                    <div className='h-[20px] bg-[#00000060]'></div>
                    <div className='h-[20px] bg-[#00000060]'></div>
                    <div className='h-[20px] bg-[#00000060]'></div>
                </div>
                <div className='flex flex-col gap-2 flex-1'>
                    <div className='h-[20px] bg-[#00000060]'></div>
                    <div className='h-[20px] bg-[#00000060]'></div>
                    <div className='h-[20px] bg-[#00000060]'></div>
                </div>
            </div>
            <div className='h-[20px] bg-[#00000060] mt-6'></div>
            <div className='h-[20px] bg-[#00000060]'></div>
        </div>
    )
}

function DayEvents({ date, setSelectedDate }){
    const [loading, setLoading] = useState(true)
    const [events, setEvents] = useState([])
    const {showMessage, t} = useContext(appContext)
    let _date = new Date(date)
    let  offset = _date.getTimezoneOffset() / 60
    _date.setUTCHours(_date.getUTCHours() - offset)

    useEffect(() => {
        api.get(baseURL+ path.event + '?date=' + _date.toISOString()).then(response => {
            const data = response.data
            setEvents(data.events)
        }).catch(e => showMessage(t('SOMETHING_WRONG'), true))
        .finally(() => setLoading(false))
    }, [])

    return (
        <div className='flex flex-1 flex-col bg-white gap-2 p-4 overflow-auto'>
            <div className='flex gap-3'>
                <button onClick={() => setSelectedDate(null)} className='bg-[transparent] cursor-pointer group transition-all border-none flex items-center text-sm gap-2'><ArrowLeft className='group-hover:translate-x-2 transition-all' size={20} color="#000000" /> <span className='max-md:hidden'>{t('CALENDAR')}</span></button>
                <div class='flex justify-center items-center gap-2 flex-col'>
                    <p className='text-center max-md:text-xs text-sm bg-[#00000020] py-1 px-3 whitespace-nowrap flex items-center gap-2 rounded-sm'>
                        {getEtDate(date)}
                        <span className='w-[2px] h-full bg-black inline-block'></span>
                        {date.toDateString()}
                        <span className='inline-flex w-[25px] h-[25px] items-center justify-center font-bold bg-main text-white rounded-full ml-3'>{events.length}</span>
                    </p>
                </div>
            </div>
            { loading ? <div className='flex flex-1 flex-col bg-white gap-2 justify-center items-center self-center'>
                    <p className='text-black text-lg font-bold'>{t('GETTING_EVENTS')}...</p>
                    <Loader loading={true} color={MAIN_COLOR} /> 
            </div>
            :
            <>
                {events.map(ev => <EventProgram large event={ev} /> )}
            </> 
            }
        </div>
    )
}