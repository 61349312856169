import { useContext, useRef, useState, useEffect } from "react"
import { appContext } from "../Context/appContext"
import { api } from "../helpers"
import Loader from "./Loader"
import { path } from "../constants"
import { customStyles } from "./ScheduleEvent"
import Select from 'react-select'

export default function AddUser(){
    const {toggleAUser, showAddUser, showMessage} = useContext(appContext)
    const [loading, setLoading] = useState(false)
    const uRef = useRef()
    const [branches, setBranches] = useState([])
    const [branch, setBranch] = useState(null)
    const brRef = useRef()

    const addOrEditUser = (e) => {
        e.preventDefault()
        if(e.target.password.value != e.target.confirm.value) return showMessage('Password does not match!', true)
        setLoading(true)
        api[showAddUser.data ? 'patch' : 'post'](path.user, {
            username: e.target.username.value,
            password: e.target.password.value,
            id: showAddUser.data?.id,
            branchId: branch.value
        }).then(response => {
            if(response.data.success) {
                toggleAUser()
                showMessage(`User successfully ${showAddUser.data ? 'updated' : 'added'}!`, false)
            }
            else showMessage(response.data.msg, true)
        })
        .catch(e => showMessage('Error has been occured!', true))
        .finally(() => setLoading(false))
    }

    const fetchBranches = () => {
        api.get(path.branch).then(response => {
            let data = response.data
            if(data.success){
                let brOptions = data.branches.map(b => ({label: b.name, value: b.id}))
                setBranches(brOptions)
                setBranch(brOptions[0])
            }
        })
    }

    useEffect(() => {
        if(showAddUser?.data){
            if(uRef.current) uRef.current.value = showAddUser.data?.username || ''
            setBranch(branches.find(b => b.value == showAddUser.data?.branchId))
        }
    }, [showAddUser, branches])

    useEffect(() => {
        if(showAddUser.visible) fetchBranches()
    }, [showAddUser])


    if(!showAddUser.visible) return null
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000080]'>
            <form method="post" onSubmit={addOrEditUser} className='flex flex-col gap-4 p-4 bg-white p-6 rounded-md'>
                <h2 className="self-center text-[25px] font-bold ">{showAddUser?.data ? 'Edit User' : 'Create User'}</h2>
                <div className='grid  grid-cols-2 gap-6 feel-c1 bg-[#00000010] p-4'>
                    <div className='flex flex-col gap-2'>
                        <p>Assigned Branch</p>
                        <Select onChange={setBranch} options={branches} value={branch} styles={customStyles} name="branch" required ref={brRef} />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>Username</p>
                        <input ref={uRef} name="username" className='input' type='text' required /> 
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>Password</p>
                        <input name="password" className='input' type='password' required />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>Confirm Password</p>
                        <input name="confirm" className='input' type='password' required /> 
                    </div>
                </div>
                <div className='flex justify-center gap-6'>
                    <button type="submit" className='py-2 px-8 font-bold text-[20px] bg-main text-white self-center' type='submit'>
                        <Loader text={showAddUser.data ? 'Update' : 'Create'} loading={loading} />                        
                    </button>
                    <button onClick={toggleAUser} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Cancel</button>
                </div>
            </form>
    </div>
    )
}