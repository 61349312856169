import { useContext, useEffect, useState } from "react"
import { MAIN_COLOR, path } from "../constants"
import { appContext } from "../Context/appContext"
import { api } from "../helpers"
import { convertTo12HourFormat } from "../Pages/Events"
import Loader from "./Loader"

export default function EventVendors(){
    const {showSelectVendors, setShowSelectVendors, toggleSVendors, resetSIVendors, addRmvSVendors, showMessage} = useContext(appContext)
    const [ vendors, setVendors ] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchVendors = () => {
        setLoading(true)
        api.get(path.vendor).then(response => {
            const data = response.data
            if(data.success){
                setVendors(data.vendors)

            } else showMessage('Something went wrong', true)
        }).catch(e => showMessage('Something went wrong', true))
        .finally(() => setLoading(false))
    }

    const assignVendors = () => {
        if(!showSelectVendors.vendors?.length && !showSelectVendors.hadData) return setShowSelectVendors({})
        setLoading(true)
        api.post(path.assignVendor, {
            vendors: showSelectVendors.vendors,
            eventId: showSelectVendors.data?.id
        }).then(response => {
            const data = response.data
            if(data.success){
                showMessage('Data Saved!')
            } else showMessage('Something went wrong!', true)
        }).catch(e => showMessage('Something went wrong! ' + e, true))
        .finally(() => {
            setLoading(false)
            setShowSelectVendors({})
        })
    }

    useEffect(() => {
        if(showSelectVendors.visible) fetchVendors()
    }, [showSelectVendors.visible])

    if(!showSelectVendors.visible) return null
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000090]'>
            <div className='flex flex-col gap-4 p-4 bg-white p-6 rounded-md'>
                <h2 className="self-center text-[19px] font-bold ">{`Assign Vendors to Event | ${showSelectVendors.data.client?.full_name} | ${showSelectVendors.data?.et_date} E.C | ${convertTo12HourFormat(showSelectVendors.data?.time)}`}</h2>
                <div className='grid  grid-cols-3 gap-6 feel-c1 bg-[#00000010] p-4'>
                    {vendors.map(vend => (
                        <div className='flex items-center gap-2'>
                            <input checked={showSelectVendors.vendors?.some(s => s == vend.id)} onChange={addRmvSVendors} value={vend.id} id={'item' + vend.id} type='checkbox' />
                            <label for={'item'+vend.id} className='text-[14px] select-none'>{vend.name} | {vend.category} | {vend.pricing} pricing | {vend.amount?.toLocaleString()} Br.</label>
                        </div>
                    ))}
                </div>
                <Loader text={null} color={MAIN_COLOR} style={{alignSelf: 'center'}} loading={loading} />
                <div className='flex justify-center gap-6'>
                    <button onClick={assignVendors} className='py-2 px-8 font-bold text-[20px] bg-main text-white self-center' type='submit'>
                    <Loader text={`Assign (${showSelectVendors.vendors?.length || 0}) Vendors`} loading={loading} />
                    </button>
                    <button onClick={resetSIVendors} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Reset</button>
                    <button onClick={() => setShowSelectVendors({})} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Cancel</button>
                </div>
            </div>
    </div>
    )
}