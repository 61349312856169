import ScaleLoader from "react-spinners/ScaleLoader";

export default function Loader({ text, loading, size, color='white', style }){
    return loading ? (
        <ScaleLoader
            color={color}
            loading={loading}
            // cssOverride={override}
            size={size || 20}
            height={13}
            style={style}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
    ) : text
}