export const ETHIOPIAN_MONTHS = [
    'መስከረም', 'ጥቅምት', 'ኅዳር', 'ታኅሣሥ', 'ጥር', 'የካቲት', 'መጋቢት',
    'ሚያዝያ', 'ግንቦት', 'ሰኔ', 'ሐምሌ', 'ነሐሴ', 'ጳጐሜን'
]

export const ETHIOPIAN_DAYS = [
    'እሁድ', 'ሰኞ', 'ማክሰኞ', 'ረቡዕ', 'ሐሙስ', 'ዓርብ', 'ቅዳሜ'
]

export const ETHIOPIAN_DAY_DIVISION = [
    'ጠዋት', 'ከሰዓት', 'ማታ'
]

export const ETHIOPIAN_TIME_DIVISION = [
    'ሻርፕ', 'ከእሩብ', 'ተኩል', 'እሩብ ጉዳይ'
]

export const baseURL = 'https://api.ems.eastbyte.cloud'
// export const baseURL = 'http://localhost:4002'

export const path = {
    login: '/login',
    calendar: '/get-calendar',
    item: '/item',
    package: '/package',
    uploadFile: '/upload-file',
    event: '/event',
    branch: '/branch',
    employee: '/employee',
    uploads: '/uploads/',
    user: '/user',
    settings: '/settings',
    client: '/client',
    generateAgreement: '/generate-agreement',
    assignEmployee: '/assign-employee',
    transactions: '/transactions',
    pettyCash: '/petty-cash',
    vendor: '/vendor',
    assignVendor: '/assign-vendor',
    vendorLedger: '/vendor-ledger',
    employeeLedger: '/employee-ledger',
    userInfo: '/user-info',
    logs: '/action-logs'
}

export const TOKEN_KEY = 'platinum_tok'

export const MAIN_COLOR = '#004a59'

export const access = {
    CALENDAR: 'Calendar', VIEW_EVENTS: 'View Events', CREATE_EVENT: 'Create Event', DELETE_EVENT: 'Delete Event',
    GENERATE_AGREEMENT: 'Generate Aggrement', ASSIGN_EMPLOYEES: 'Assign Employees', ASSIGN_VENDORS: 'Assign Vendors',
    CLIENTS: 'Clients', VENDORS: 'Vendors', ITEMS: 'Items', PACKAGES: 'Packages',
    EMPLOYEES: 'Employees', BRANCHES: 'Branches', APP_USERS: 'App Users',
    CASHBOOK_VIEW_RECORDS: 'Cashbook View Records', CASHBOOK_IN_OUT: 'Cashbook In/Out', CASHBOOK_VIEW_BALANCE: 'Cashbook View Balance',
    CASHBOOK_SPEND_PETTY_CASH: 'Cashbook Spend Petty Cash', CASHBOOK_CREATE_PETTY_CASH: 'Cashbook Create Petty Cash',
    APPROVE_PENDING_TRANSACTIONS: 'Cashbook Approve Pending Transaction',
    SETTLE_ADVANCE_PAYMENT: 'Cashbook Settle Advance Payment', VENDOR_LEDGER: 'Vendor Ledger', SYSTEM_SETTINGS: 'System Settings',  ACTION_LOGS: 'Action Logs',
}