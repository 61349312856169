import { useContext, useEffect, useRef, useState } from "react"
import { baseURL, path } from "../constants"
import { appContext } from "../Context/appContext"
import { authContext } from "../Context/authContext"
import { api } from "../helpers"
import Loader from "./Loader"
import { customStyles } from "./ScheduleEvent"
import Select from 'react-select'

export default function AddVendor(){
    const {toggleAVendor, showAddVendor, showMessage} = useContext(appContext)
    const [loading, setLoading] = useState(false)
    const nameRef = useRef(), priRef = useRef(), name = useRef(),
    amoRef = useRef(), repRef = useRef(), contRef = useRef(), indRef = useRef()

    const pricingOptions = [
        {label: 'Fixed (Per Event)', value: 'fixed'},
        {label: 'Per Person', value: 'per-person'}
    ]
    const [pricing, setPricing] = useState(pricingOptions[0])

    const addOrEditVendor = (e) => {
        e.preventDefault()
        setLoading(true)
        api[showAddVendor.data ? 'patch' : 'post'](path.vendor, {
            name: e.target.name.value,
            category: e.target.industry.value,
            pricing: e.target.pricing.value,
            amount: e.target.amount.value,
            representative: e.target.representative.value,
            contact: e.target.contact.value,
            id: showAddVendor.data?.id
        }).then(response => {
            if(response.data.success) {
                toggleAVendor()
                showMessage(`Vendor successfully ${showAddVendor.data ? 'updated' : 'added'}!`, false)
            }
            else showMessage('Error has been occured!', true)
        })
        .catch(e => showMessage('Error has been occured!', true))
        .finally(() => setLoading(false))

    }

    useEffect(() => {
        if(showAddVendor.data){
            let data = showAddVendor.data
            if(nameRef.current) nameRef.current.value = data?.name || ''
            if(indRef.current) indRef.current.value = data?.category || ''
            setPricing(pricingOptions.find(p => p.value == data.pricing))
            if(amoRef.current) amoRef.current.value = data?.amount || ''
            if(repRef.current) repRef.current.value = data?.representative || ''
            if(contRef.current) contRef.current.value = data?.contact || ''
        }
    }, [showAddVendor])

    if(!showAddVendor.visible) return null  
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000080]'>
            <form method="post" onSubmit={addOrEditVendor} className='flex flex-col gap-4 p-4 bg-white p-6 rounded-md'>
                <h2 className="self-center text-[25px] font-bold ">{showAddVendor.data ? 'Edit Vendor' : 'Add Vendor'}</h2>
                <div className='grid grid-cols-2 gap-6 feel-c1 bg-[#00000010] p-4'>
                    <div className='flex flex-col gap-2'>
                        <p>Vendor Name</p>
                        <input placeholder='Name' ref={nameRef} name="name" className='input' type='text' required/>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>Vendor Offering</p>
                        <input placeholder={'Vendor\'s Offer'} ref={indRef} name="industry" className='input' type='text' required/>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>Pricing</p>
                        <Select onChange={setPricing} options={pricingOptions} value={pricing} styles={customStyles} name="pricing" required ref={priRef} />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>Amount</p>
                        <input placeholder='Amount' ref={amoRef} name="amount" className='input' type='number' required/>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>Representative</p>
                        <input placeholder='Reprsentative Name' ref={repRef} name="representative" className='input' type='text' required/>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>Contact</p>
                        <input placeholder='Contact Phone' ref={contRef} name="contact" className='input' type='text' required/>
                    </div>
                </div>
                <div className='flex justify-center gap-6'>
                    <button type="submit" className='py-2 px-8 font-bold text-[20px] bg-main text-white self-center' type='submit'>
                        <Loader text={showAddVendor.data ? 'Update' : 'Add Vendor'} loading={loading} />                        
                    </button>
                    <button onClick={() => toggleAVendor()} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Cancel</button>
                </div>
            </form>
        </div>
    )
}