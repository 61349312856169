import { Link } from "react-router-dom";
import {Plus, Edit, Delete} from 'react-feather'
import { useContext, useState, useEffect } from "react";
import { appContext } from "../Context/appContext";
import { api } from "../helpers";
import { access, MAIN_COLOR, path } from "../constants";
import Loader from "../Components/Loader";

export default function User(){
    const {toggleAUser, showAddUser, showMessage, setConfirm} = useContext(appContext)
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true)
    const [_access, setAccess] = useState(null)

    const fetchUsers = () => {
        setLoading(true)
        api.get(path.user).then(response => {
            const data = response.data
            if(data.success){
                setUsers(data.users)
            } else showMessage('Something went wrong', true)
        }).catch(e => showMessage('Something went wrong', true))
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        if(!showAddUser.visible) fetchUsers()
    }, [showAddUser])

    const deleteUser = (id) => {
        return function(){
            return api.delete(path.user, {
                data: {id}}
            ).then(r => {
                setUsers(m => m.filter(i => i.id != id))
                showMessage('User deleted', false)
            }).catch(e => showMessage('Something went wrong', true))
            .finally(() => setLoading(false))
        }
    }

    return (
        <div className='flex flex-col flex-1 overflow-auto p-6 gap-6'>
        <button onClick={() => toggleAUser()} className='self-end bg-main px-4 py-2 rounded-sm font-bold  text-white flex items-center gap-2 hover:bg-darker transition-all border-none'>
            Create User
            <Plus color="#ffffff" size={18} />
        </button>
        <p className='text-[16px] uppercase font-bold'>User / Agents</p>
        <div className='overflow-y-auto flex-1 rounded-md bg-white p-6 flex flex-col'>
        <table className='w-full'>
            <thead>
                <tr>
                    <th>
                        Username
                    </th>
                    <th>
                        Role
                    </th>
                    <th>
                        Branch
                    </th>
                    <th>
                        Date Created
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {users.map(user => (
                    <tr>
                        <td>{user.username}</td>
                        <td>{user.role}</td>
                        <td>{user.branch?.name}</td>
                        <td>{user.createdAt?.split('.')[0]}</td>
                        <td className='flex items-center justify-center'>
                            <button onClick={() => setAccess(user)} className='mr-4 px-4 py-2 bg-black border-none text-white max-md:text-xs rounded-md mr-4'>
                                User Access ( {user.access?.split(',')?.filter(f=>f)?.length} / {Object.values(access).length} )
                            </button>
                            <button onClick={() => toggleAUser(user)} className='border-none bg-none'>
                                <Edit color='#000000' size={20} />
                            </button>
                            <button onClick={() => {
                                if(user.role == 'admin') return showMessage('you can\'t delete admin!', true)
                                setConfirm({
                                visible: true,
                                action: deleteUser(user.id)
                                })
                             } } className='border-none bg-none ml-3'>
                                <Delete color="#ff0000" size={20} />
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        {(!loading && !users.length) && <h4 className='text-center mt-4'>No Data.</h4>}
        <Loader text={null} loading={loading} color={MAIN_COLOR} style={{marginTop: 20, alignSelf: 'center'}} />
        </div>
        {_access && <UserAccess data={_access} setAccess={setAccess} fetchUsers={fetchUsers} />}
    </div>
    )
}

function UserAccess({setAccess, data, fetchUsers}){
    const [userAccess, setUserAccess] = useState(data.access?.split(',')?.filter(f=>f) || [])
    const [loading, setLoading] = useState(false)
    const {showMessage} = useContext(appContext)

    
    const saveAccess = () => {
        setLoading(true)
        api.put(path.user, {
            id: data.id,
            access: userAccess
        }).then(response => {
            let data = response.data
            if(data.success) {
                showMessage('Success')
                fetchUsers()
                setAccess(false)
            }
            else showMessage('Something went wrong!')
        }).finally(() => setLoading(false))
    }
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000080] z-[99999]'>
            <div className='flex flex-col gap-6 p-4 bg-white p-6 max-md:p-2 max-md:py-4 rounded-md'>
                <h2 className="self-center text-[25px] font-bold max-md:text-[20px]">User Access</h2>
                <div className='grid grid-cols-3 max-md:grid-cols-2 gap-4 self-center'>
                    {Object.values(access).map(acc => (
                        <div className='flex items-center gap-2'>
                            <input type='checkbox' onChange={e => {
                                if(e.target.checked) setUserAccess(a => [...a, acc])
                                else setUserAccess(a => a.filter(value => value != acc))
                            }} id={acc} checked={userAccess?.includes(acc)} />
                            <label className='select-none text-sm' for={acc}>{acc}</label>
                        </div>
                    ))}
                </div>
                <div className='flex justify-center gap-6'>
                    <button onClick={saveAccess} className='py-2 px-8 font-bold text-[20px] bg-main text-white self-center'>
                        <Loader text={'Save'} loading={loading} />                        
                    </button>
                    <button onClick={() => setAccess(false)} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Cancel</button>
                </div>
            </div>
        </div>
    )
}