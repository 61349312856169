import { useContext, useRef, useState, useEffect } from "react"
import { path } from "../constants"
import { appContext } from "../Context/appContext"
import { api } from "../helpers"
import Loader from "./Loader"
import {Trash} from 'react-feather'
import { customStyles } from "./ScheduleEvent"
import Select from 'react-select'

export default function AddEmployee(){
    const {toggleAEmployee, showAddEmployee, showMessage, setImageControl} = useContext(appContext)
    const [loading, setLoading] = useState(false)
    const [empImage, setEmpImage] = useState(null)
    const [empIdImage, setEmpIdImage] = useState(null)
    const [guarImage, setGuarImage] = useState(null)
    const [aggrImage, setAggrImage]= useState(null)
    const [errors, setErrors] = useState({})
    const [phoneNums, setPhoneNums] = useState({})
    const wageOptions = [
        {label: 'Fixed', value: 'fixed'},
        {label: 'Dynamic', value: 'dynamic'}
    ]
    const [wageType, setWageType] = useState(wageOptions[0])
    const fnRef = useRef()
    const lnRef = useRef()
    const pnRef = useRef()
    const guaRef = useRef()
    const guaPhRef = useRef()
    const salRef = useRef()
    const ewageRef = useRef()
    const wageRef = useRef()
    const afwageRef = useRef()
    const evwageRef = useRef()

    const clearImages = () => {
        setAggrImage(null)
        setEmpImage(null)
        setEmpIdImage(null)
        setAggrImage(null)
    }

    const addOrEditEmpoyee = (e) => {
        e.preventDefault()
        if(Object.keys(errors).map(e => errors[e]).some(e => e)) return showMessage('Please fix all errors', true)
        setLoading(true)
        api[showAddEmployee.data ? 'patch' : 'post'](path.employee, {
            first_name: e.target.first_name.value,
            last_name: e.target.last_name.value,
            phone_number: e.target.phone_number.value,
            // guarantor_name: e.target.guarantor_name.value,
            guarantor_phone: e.target.guarantor_number.value,
            salary: e.target.salary.value,
            event_wage: e.target.event_wage?.value,
            id: showAddEmployee.data?.id,
            personal_image: empImage?.path,
            id_image: empIdImage?.path,
            guarantor_id: guarImage?.path,
            agreement_image: aggrImage?.path,
            wage_type: e.target.wage_type.value,
            afternoon_wage: e.target.afternoon_wage?.value,
            evening_wage: e.target.evening_wage?.value
        }).then(response => {
            if(response.data.success) {
                clearImages()
                toggleAEmployee()
                showMessage(`Employee successfully ${showAddEmployee.data ? 'updated' : 'added'}!`, false)
            }
            else showMessage('Error has been occured!', true)
        })
        .catch(e => showMessage('Error has been occured!', true))
        .finally(() => setLoading(false))

    }

    useEffect(() => {
        let _errors = {...errors}
        if(phoneNums.employee){
            _errors = {..._errors, employee: /^09\d{8}$/.test(phoneNums.employee) ? null : 'Invalid phone format'}
        }
        if(phoneNums.guarantor){
            _errors = {..._errors, guarantor: /^09\d{8}$/.test(phoneNums.guarantor) ? null : 'Invalid phone format'}
        }
        setErrors(_errors)
    }, [phoneNums])

    useEffect(() => {
        if(showAddEmployee.data){
            if(fnRef.current) fnRef.current.value = showAddEmployee.data?.full_name?.split(' ')[0] || ''
            if(lnRef.current) lnRef.current.value = showAddEmployee.data?.full_name?.split(' ')[1] || ''
            if(pnRef.current) pnRef.current.value = showAddEmployee.data?.phone_number || ''
            if(guaPhRef.current) guaPhRef.current.value = showAddEmployee.data?.guarantor_phone || ''
            if(salRef.current) salRef.current.value = showAddEmployee.data?.salary || ''
            if(ewageRef.current) ewageRef.current.value = showAddEmployee.data?.event_wage || ''
            setWageType(wageOptions.find(w => w.value == showAddEmployee.data.wage_type))
            if(afwageRef.current) afwageRef.current.value = showAddEmployee.data?.afternoon_wage || ''
            if(evwageRef.current) evwageRef.current.value = showAddEmployee.data?.evening_wage || ''
        }
    }, [showAddEmployee])

    if(!showAddEmployee.visible) return null
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000080]'>
            <form method="post" onSubmit={addOrEditEmpoyee} className='flex flex-col gap-4 p-4 bg-white p-6 rounded-md'>
                <h2 className="self-center text-[25px] font-bold ">{showAddEmployee?.data ? 'Edit Employee' : 'Add Employee'}</h2>
                <div className='grid  grid-cols-3 gap-6 feel-c1 bg-[#00000010] p-4'>
                    <div className='flex flex-col gap-2'>
                        <p>First Name</p>
                        <input ref={fnRef} name="first_name" type='text' className='input' required/>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>Last Name</p>
                        <input ref={lnRef} name="last_name" type='text' className='input' required /> 
                    </div>
                    <div className='flex flex-col gap-2 relative'>
                        <p>Phone Number</p>
                        <input onChange={e => setPhoneNums({
                            ...phoneNums,
                            employee: e.target.value
                        })} value={phoneNums.employee} ref={pnRef} name="phone_number" type='text' className='input' required /> 
                        {errors.employee && <p className='text-red text-sm font-bold bottom-1'>{errors.employee}</p>}
                    </div>
                    {/* <div className='flex flex-col gap-2'>
                        <p>Guarantor Name</p>
                        <input ref={guaRef} name="guarantor_name" type='text' className='input' /> 
                    </div> */}
                    <div className='flex flex-col gap-2'>
                        <p>Guarantor Phone Number</p>
                        <input onChange={e => setPhoneNums({
                            ...phoneNums,
                            guarantor: e.target.value
                        })} value={phoneNums.guarantor} ref={guaPhRef} name="guarantor_number" type='text' className='input' /> 
                        {errors.guarantor && <p className='text-red text-sm font-bold bottom-1'>{errors.guarantor}</p>}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>Salary</p>
                        <input ref={salRef} name="salary" type='text' className='input' required /> 
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>Wage Type</p>
                        <Select onChange={setWageType} options={wageOptions} value={wageType} styles={customStyles} name="wage_type" required ref={wageRef} />
                    </div>
                    {wageType?.value == 'fixed' ? <div className='flex flex-col gap-2'>
                        <p>Event Wage</p>
                        <input ref={ewageRef} name="event_wage" type='text' className='input' required /> 
                    </div> : <>
                        <div className='flex flex-col gap-2'>
                            <p>Afternoon Wage</p>
                            <input ref={afwageRef} name="afternoon_wage" type='text' className='input' required /> 
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Evening Wage</p>
                            <input ref={evwageRef} name="evening_wage" type='text' className='input' required /> 
                        </div>
                    </> }
                    
                    <div className='flex gap-2 items-center '>
                        {empImage?.path && <>
                            <img onClick={e => window.open(e.target.src, '_blank')} style={{width: '40px', height: '40px', objectFit: 'contain'}} src={URL.createObjectURL(empImage.blob)} />
                            <button onClick={() => setEmpImage(null)}><Trash color='#ff0000' size={15} /></button>
                        </>}
                        <div className='flex flex-col gap-2 justify-center w-full'>
                            <p>Employee ID Image</p>
                            <button type='button' onClick={() => setImageControl({visible: true, setImage: setEmpImage})} className='py-2 px-8 font-bold text-[16px] bg-black text-white self-center w-full'>Take Image</button>
                        </div>
                    </div>
                    <div className='flex gap-2 items-center'>
                        {empIdImage?.path && <>
                            <img onClick={e => window.open(e.target.src, '_blank')} style={{width: '40px', height: '40px', objectFit: 'contain'}} src={URL.createObjectURL(empIdImage.blob)} />
                            <button onClick={() => setEmpIdImage(null)}><Trash color='#ff0000' size={15} /></button>
                        </>}
                        <div className='flex flex-col gap-2 justify-center w-full'>
                            <p>Guarantor ID Image</p>
                            <button type='button' onClick={() => setImageControl({visible: true, setImage: setEmpIdImage})} className='py-2 px-8 font-bold text-[16px] bg-black text-white self-center w-full'>Take Image</button>
                        </div>
                    </div>
                    <div className='flex gap-2 items-center'>
                        {guarImage?.path && <>
                            <img onClick={e => window.open(e.target.src, '_blank')} style={{width: '40px', height: '40px', objectFit: 'contain'}} src={URL.createObjectURL(guarImage.blob)} />
                            <button onClick={() => setGuarImage(null)}><Trash color='#ff0000' size={15} /></button>
                        </>}
                        <div className='flex flex-col gap-2 justify-center w-full'>
                            <p>Employee Photo</p>
                            <button type='button' onClick={() => setImageControl({visible: true, setImage: setGuarImage})} className='py-2 px-8 font-bold text-[16px] bg-black text-white self-center w-full'>Take Image</button>
                        </div>
                    </div>
                    <div className='flex gap-2 items-center'>
                        {aggrImage?.path && <>
                            <img onClick={e => window.open(e.target.src, '_blank')} style={{width: '40px', height: '40px', objectFit: 'contain'}} src={URL.createObjectURL(aggrImage.blob)} />
                            <button onClick={() => setAggrImage(null)}><Trash color='#ff0000' size={15} /></button>
                        </>}
                        <div className='flex flex-col gap-2 justify-center w-full'>
                            <p>Agreement Image</p>
                            <button type='button' onClick={() => setImageControl({visible: true, setImage: setAggrImage})} className='py-2 px-8 font-bold text-[16px] bg-black text-white self-center w-full'>Take Image</button>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center gap-6'>
                    <button disabled={loading} className='py-2 px-8 font-bold text-[20px] bg-main text-white self-center' type='submit'>
                        <Loader text={showAddEmployee.data ? 'Update' : 'Add Employee'} loading={loading} />                        
                    </button>
                    <button type='button' onClick={() => {clearImages(); toggleAEmployee()}} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center'>Cancel</button>
                </div>
            </form>
    </div>
    )
}