import { Link } from "react-router-dom";
import {Plus, Edit, Delete} from 'react-feather'
import { useContext, useState, useEffect } from "react";
import { appContext } from "../Context/appContext";
import { api } from "../helpers";
import { access, MAIN_COLOR, path } from "../constants";
import Loader from "../Components/Loader";
import { authContext } from "../Context/authContext";

export default function Vendors(){
    const {toggleAVendor, toggleShowVLedger, showAddVendor, showMessage, setConfirm} = useContext(appContext)
    const [vendors, setVendors] = useState([])
    const [loading, setLoading] = useState(false) // true
    const {isAllowed} = useContext(authContext)

    const fetchVendors = () => {
        setLoading(true)
        api.get(path.vendor).then(response => {
            const data = response.data
            if(data.success){
                setVendors(data.vendors)
            } else showMessage('Something went wrong', true)
        }).catch(e => showMessage('Something went wrong', true))
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        if(!showAddVendor.visible) fetchVendors()
    }, [showAddVendor])

    const deleteVendor = (id) => {
        return function(){
            return api.delete(path.vendor, {
                data: {id}}
            ).then(r => {
                setVendors(m => m.filter(i => i.id != id))
                showMessage('Vendor deleted', false)
            }).catch(e => showMessage('Something went wrong', true))
            .finally(() => setLoading(false))
        }
    }

    return (
        <div className='flex flex-col flex-1 overflow-auto p-6 gap-6'>
        <button onClick={() => toggleAVendor()} className='self-end bg-main px-4 py-2 rounded-sm font-bold  text-white flex items-center gap-2 hover:bg-darker transition-all border-none'>
            Add Vendor
            <Plus color="#ffffff" size={18} />
        </button>
        <p className='text-[16px] uppercase font-bold'>All Vendors</p>
        <div className='overflow-y-auto flex-1 rounded-md bg-white p-6 flex flex-col'>
        <table className='w-full'>
            <thead>
                <tr>
                    <th>
                        Vendor Name
                    </th>
                    <th>
                        Offer
                    </th>
                    <th>
                        Pricing
                    </th>
                    <th>
                        Amount
                    </th>
                    <th>
                        Representative
                    </th>
                    <th>
                        Contact
                    </th>
                    <th>
                        Date Created
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {vendors.map(vendor => (
                    <tr>
                        <td>{vendor.name}</td>
                        <td>{vendor.category}</td>
                        <td>{vendor.pricing}</td>
                        <td>{vendor.amount}</td>
                        <td>{vendor.representative}</td>
                        <td>{vendor.contact}</td>
                        <td>{vendor.createdAt?.split('.')[0]}</td>
                        <td className='flex items-center'>
                            {isAllowed(access.VENDOR_LEDGER) && <button onClick={() => toggleShowVLedger(vendor)} className='px-4 py-2 bg-black border-none text-white max-md:text-xs rounded-md mr-4'>
                                Payment Ledger
                            </button> }
                            <button onClick={() => toggleAVendor(vendor)} className='border-none bg-none'>
                                <Edit color='#000000' size={20} />
                            </button>
                            <button onClick={() => setConfirm({
                                visible: true,
                                action: deleteVendor(vendor.id)
                                }) } className='border-none bg-none ml-3'>
                                <Delete color="#ff0000" size={20} />
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        {(!loading && !vendors.length) && <h4 className='text-center mt-4'>No Data.</h4>}
        <Loader text={null} loading={loading} color={MAIN_COLOR} style={{marginTop: 20, alignSelf: 'center'}} />
        </div>
    </div>
    )
}