import { useContext, useEffect, useState } from "react"
import { MAIN_COLOR, path } from "../constants"
import { appContext } from "../Context/appContext"
import { api } from "../helpers"
import Loader from "./Loader"

export default function SelectItems(){
    const {toggleSItems, showSelectItems, showMessage, addRmvSItem, resetSItems} = useContext(appContext)
    const [ items ,setItems ] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchItems = () => {
        setLoading(true)
        api.get(path.item).then(response => {
            const data = response.data
            if(data.success){
                setItems(data.items)
            } else showMessage('Something went wrong', true)
        }).catch(e => showMessage('Something went wrong', true))
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        if(showSelectItems.visible) fetchItems()
    }, [showSelectItems.visible])

    if(!showSelectItems.visible) return null
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000090]'>
            <div className='flex flex-col gap-4 p-4 bg-white p-6 rounded-md'>
                <h2 className="self-center text-[25px] font-bold ">{false ? 'Edit Items' : 'Select Package Items'}</h2>
                <div className='grid  grid-cols-6 gap-6 feel-c1 bg-[#00000010] p-4'>
                    {(showSelectItems.filter ? items.filter(i => !showSelectItems.filter.includes(i.id)) : items)?.map(item => (
                        <div className='flex items-center gap-2'>
                            <input checked={showSelectItems.items?.some(s => s == item.id)} onChange={addRmvSItem} value={item.id} id={'item' + item.id} type='checkbox' />
                            <label for={'item'+item.id} className='text-[14px] select-none'>{item.name}</label>
                        </div>
                    ))}
                </div>
                <Loader text={null} color={MAIN_COLOR} style={{alignSelf: 'center'}} loading={loading} />
                <div className='flex justify-center gap-6'>
                    <button onClick={toggleSItems} className='py-2 px-8 font-bold text-[20px] bg-main text-white self-center' type='submit'>{`Done (${showSelectItems.items?.length || 0}) Items`}</button>
                    <button onClick={resetSItems} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Reset</button>
                </div>
            </div>
    </div>
    )
}