import { useState } from 'react'
import Select from 'react-select'
import { ETHIOPIAN_MONTHS } from '../../constants'
import { convertEG } from '../../helpers'

const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '50px', 
      height: '50px',
      width: '200px'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '50px',
      padding: '0 8px',
    })
}

const generateYears = () => {
    let years = [...Array(150).keys()]
    years = years.map(y => y + 1950)
    return years
}
const ET_YEARS = generateYears().map(y => ({label: y, value: y}))
const ET_MONTH = ETHIOPIAN_MONTHS.map((op, i) => ({
    label: op, value: i + 1
}))
const ET_DAYS = [...Array(30).keys()].map((d, i) => ({
    label: d + 1, value: d + 1
}))

export default function ETDatePicker({selectETDate, setShowETDate}){
    const [THIS_ET_YEAR, THIS_ET_MONTH, THIS_ET_DAY] = convertEG(new Date())
    const [etDay, setEtDay] = useState(ET_DAYS.find(y => y.value == THIS_ET_DAY))
    const [etMonth, setEtMonth] = useState(ET_MONTH.find(y => y.value == THIS_ET_MONTH))
    const [etYear, setEtYear] = useState(ET_YEARS.find(y => y.value == THIS_ET_YEAR))
    
    return (
        <div className='dim'>
            <div className='alert-box'>
                <div className='flex gap-4 max-md:flex-col'>
                    <div className='flex flex-col gap-2'>
                        <p>ወር</p>
                        <Select styles={customStyles} options={ET_MONTH} value={etMonth} onChange={setEtMonth} />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>ቀን</p>
                        <Select styles={customStyles} options={ET_DAYS} value={etDay} onChange={setEtDay} />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>አመት</p>
                        <Select styles={customStyles} options={ET_YEARS} value={etYear} onChange={setEtYear} />
                    </div>
                </div>
                <div className='flex items-center gap-4'>
                    <button
                        onClick={() => selectETDate([etYear.value, etMonth.value, etDay.value])}
                        className='px-6 py-2 text-md font-bold text-white bg-main rounded-md border-none'>SET
                    </button>
                    <button
                        onClick={() => setShowETDate(false)}
                        className='bg-none border-[1px] border-main text-black text-md px-6 py-2 rounded-md'>
                        Cancel
                    </button>
                </div>
                        
            </div>
        </div>
    )
}