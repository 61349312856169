import logo from '../assets/platinum.png'
import {animated} from '@react-spring/web'
import { useContext, useState } from 'react'
import { authContext } from '../Context/authContext'
import { appContext } from '../Context/appContext'
import { api } from '../helpers'
import { path, TOKEN_KEY } from '../constants'
import Loader from '../Components/Loader'

export default function Login({ style }){
    const {toggleLayout, setToken} = useContext(authContext)
    const {showMessage, t} = useContext(appContext)
    const [loading, setLoading] = useState(false)

    const login = async (e) => {
        e.preventDefault()
        setLoading(true)
        api.post(path.login, {
            username: e.target.username.value,
            password: e.target.password.value
        }).then(response => {
            const data = response.data
            if(data.success){
                setToken(data.token)
                sessionStorage.setItem(TOKEN_KEY, data.token)
                toggleLayout()
            }
            else {
                showMessage(t("INCORRECT_LOGIN"), true)
            }
        }).finally(() => setLoading(false))
    }
    return (
        <animated.div style={{...style}} className='flex absolute w-full h-full flex-1 items-center justify-center bg-cover will-change-[transform,opacity]'>
            <form method='post' onSubmit={login} className='p-10 rounded-[30px] max-md:rounded-[10px] shadow-md bg-white flex flex-col items-center gap-4 w-[400px] max-md:w-[300px]'>
                {/* <img src={logo} className='w-[100px] object-fit' /> */}
                <h4>Login to EMS </h4>
                <input type='text' name='username' className='border-2 border-main border-solid p-3 text-center w-full' placeholder='Username' />
                <input type='password' name='password' className='border-2 border-main p-3 text-center w-full' placeholder='Password' />
                <button disabled={loading} type='submit' className='p-3 bg-main text-white font-bold w-full'>
                    <Loader text={t("LOGIN")} loading={loading} />
                </button>
            </form>
        </animated.div>
    )
}