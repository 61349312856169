import { Link } from "react-router-dom";
import {Plus, Edit, Delete} from 'react-feather'
import { useContext, useEffect, useState } from "react";
import { appContext } from "../Context/appContext";
import { MAIN_COLOR, path } from "../constants";
import { api } from "../helpers";
import Loader from "../Components/Loader";

export default function Branches(){
    const {toggleABranch, showAddBranch, showMessage, setConfirm} = useContext(appContext)
    const [branches, setBranches] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchBranches = () => {
        setLoading(true)
        api.get(path.branch).then(response => {
            const data = response.data
            if(data.success){
                setBranches(data.branches)
            } else showMessage('Something went wrong', true)
        }).catch(e => showMessage('Something went wrong', true))
        .finally(() => setLoading(false))
    }

    const deleteBranch = (id) => {
        return function(){
            return api.delete(path.branch, {
                data: {id}}
            ).then(r => {
                setBranches(m => m.filter(i => i.id != id))
                showMessage('Branch deleted', false)
            }).catch(e => showMessage('Something went wrong', true))
            .finally(() => setLoading(false))
        }
    }

    useEffect(() => {
        if(!showAddBranch.visible) fetchBranches()
    }, [showAddBranch])
    
    return (
        <div className='flex flex-col flex-1 overflow-auto p-6 gap-6'>
        <button onClick={() => toggleABranch()} className='self-end bg-main px-4 py-2 rounded-sm font-bold  text-white flex items-center gap-2 hover:bg-darker transition-all border-none'>
            Add Branch
            <Plus color="#ffffff" size={18} />
        </button>
        <p className='text-[16px] uppercase font-bold'>All Branches</p>
        <div className='overflow-y-auto flex-1 rounded-md bg-white p-6 flex flex-col'>
        <table className='w-full'>
            <thead>
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Address
                    </th>
                    <th>
                        Date Created
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {branches.map(branch => (
                    <tr>
                        <td>{branch.name}</td>
                        <td>{branch.address}</td>
                        <td>{branch.createdAt?.split('.')[0]}</td>
                        <td className=''>
                            <button onClick={() => toggleABranch(branch)} className='border-none bg-none'>
                                <Edit color='#000000' size={20} />
                            </button>
                            <button onClick={() => setConfirm({
                                visible: true,
                                action: deleteBranch(branch.id)
                                }) } className='border-none bg-none ml-3'>
                                <Delete color="#ff0000" size={20} />
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        {(!loading && !branches.length) && <h4 className='text-center mt-4'>No Data.</h4>}
        <Loader text={null} loading={loading} color={MAIN_COLOR} style={{marginTop: 20, alignSelf: 'center'}} />
        </div>
    </div>
    )
}