import { useContext, useEffect, useRef, useState } from "react"
import { baseURL, MAIN_COLOR, path } from "../constants"
import { appContext } from "../Context/appContext"
import date from 'date-and-time';
import { api, getByPage } from "../helpers"
import Loader from "./Loader"
import { Trash } from "react-feather";
import { authContext } from "../Context/authContext";
import Select from 'react-select'
import InfiniteScroll from 'react-infinite-scroll-component';

export default function SalaryWage(){
    const {toggleShowSalaryWage, showSalaryWage, showMessage} = useContext(appContext)
    const [loading, setLoading] = useState(false)
    const [balance, setBalance] = useState(0)
    const [history, setHistory] = useState([])
    const [makePayment, setMakePayment] = useState(false)
    const [page, setPage] = useState(0)
    const [hasMore, setHasMore] = useState(true)
    const {user} = useContext(authContext)
    const brRef = useRef()
    console.log(hasMore)
    const dateOptions = { month: 'short', day: '2-digit', year: 'numeric' };

    const fetchLegder = (p) => {
        setLoading(true)
        const limit = 10
        api.get(getByPage(path.employeeLedger + `?&id=${showSalaryWage.data?.id}`, p || page, true))
        .then(response => {
            let data = response.data 
            setHistory(d => !p ? [...d, ...data.ledger] : data.ledger)
            !p ? setPage(p => p + 1) : setPage(2)
            setHasMore(!(data.ledger.length < limit))
            setBalance(data.gain)
        }).finally(() => {
            setLoading(false)
        })
    }
    useEffect(() => {
        if(showSalaryWage.data) fetchLegder(1)
    }, [showSalaryWage.data])

    useEffect(() => {
        if(!showSalaryWage.visible){
            setHistory([])
        }
    }, [showSalaryWage.visible])
   

    if(!showSalaryWage.visible) return null  
    return (
        <div className='fixed inset-0 flex items-center max-md:items-start overflow-auto justify-center bg-[#00000080]'>
            <div method="post" className='flex flex-col gap-4 p-4 bg-white p-6 rounded-md relative'>
                <h2 className="text-[22px] font-bold text-center">{'Ledger for ' + showSalaryWage.data?.full_name}</h2>
                
                <div className='flex gap-10 flex-col'>
                    <div id="scrollable" className='flex flex-col gap-2 items-center max-h-[350px] overflow-auto'>
                        <h4 className='text-[17px] text-center'>Gain History</h4>
                        <InfiniteScroll
                            dataLength={history.length}
                            next={fetchLegder}
                            hasMore={hasMore}
                            loader={<div className='w-full flex justify-center'><Loader text={null} loading={hasMore} color={MAIN_COLOR} style={{marginTop: 20, alignSelf: 'center'}} /></div>}
                            scrollableTarget="scrollable"
                            endMessage={<p className='text-center m-4 text-sm'>No more data to load.</p>}
                        >
                        <table className='relative overflow-auto w-full'>
                            <thead>
                                <tr className='static top-0'>
                                    <th className='px-4'>Amount</th>
                                    <th className='px-4'>Generated Time</th>
                                    <th className='px-4'>Source</th>
                                </tr>
                            </thead>
                            <tbody>
                                {history.map(gain => (
                                    <tr>
                                        <td className={'font-bold text-md ' + (gain.transact_type == 'in' ? 'text-[#009100]' : 'text-[#ff0000]')}>{gain.amount?.toLocaleString()} Br.</td>
                                        <td>{new Date(gain.createdAt).toLocaleDateString('en-US', dateOptions)} |<span className='max-md:block'> {date.format(new Date(gain.createdAt), 'hh:mm:ss A')}</span></td>
                                        <td className='text-xs'>{gain .event ? `Event: ${gain.event?.client?.full_name} | ${gain.event?.et_date}` : 'Payment'}
                                            {gain.proof && <a className='text-xs ml-2 text-[#0000ff] underline' href={baseURL + path.uploads + gain.proof} target='_blank'>Receipt</a>}
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        </InfiniteScroll>
                        {/* {(!loading && !history.length) && <h4 className='text-center mt-4'>No Data.</h4>}
                        <Loader text={null} loading={loading} color={MAIN_COLOR} style={{marginTop: 20}} /> */}
                    </div>
                </div>
                <div className='border-t-[1px] border-b-[1px] border-solid border-black py-4 flex justify-center'>
                    <h2>Employee Wage Balance: <span className='text-red font-bold'>{parseFloat(balance)?.toLocaleString()} Br.</span></h2>
                </div>
                <div className='flex justify-center gap-6'>
                    <button onClick={() => setMakePayment(true)} className='py-2 px-8 font-bold text-[20px] bg-main text-white self-center'>
                        Pay & Clear Balance
                    </button>
                    <button onClick={() => toggleShowSalaryWage()} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Cancel</button>
                </div>
            </div>
            {makePayment && <MakePayment data={{id: showSalaryWage.data?.id, balance, salary: showSalaryWage.data?.salary}} setMakePayment={setMakePayment} fetchLegder={fetchLegder} />}
        </div>
    )
}

function MakePayment({data = {}, setMakePayment, fetchLegder, balance}){
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState(data.balance || 0)
    const {setImageControl, showMessage} = useContext(appContext)
    const [proof, setProof] = useState({})
    const [withSalary, setWithSalary] = useState(true)

    const processPayment = (e) =>  {
        e.preventDefault()
        setLoading(true)
        api.patch(baseURL + path.employeeLedger, {
            proof: proof.path,
            id: data.id,
            withSalary
        }).then(response => {
            let data = response.data
            if(data.success){
                showMessage('Success!')
                fetchLegder(1)
            }
        }).catch(e => showMessage('Error has been occured!', true))
        .finally(() => {
            setLoading(false)
            setMakePayment()
        })
    }

    const getTotal= () => withSalary ? (parseFloat(data.balance) + parseFloat(data.salary)) : parseFloat(data.balance)

    // const takeAction = {
    //     pending: acceptPendingOrSettle
    // }

    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000080] z-[9999]'>
            <form method='post' onSubmit={processPayment} className='flex flex-col gap-4 p-4 bg-white p-6 max-md:p-2 max-md:py-4 rounded-md'>
                <h2 className="self-center text-[25px] font-bold max-md:text-[20px]">Make Payment</h2>
                <div className='grid grid-cols-3 max-md:grid-cols-1 gap-6 max-md:gap-3 feel-c1 bg-[#00000010] p-4'>
                    <div className='flex items-center gap-2'>
                        <label for='with-salary' className='whitespace-nowrap select-none'>Pay With Salary</label>
                        <input id='with-salary' checked={withSalary} onChange={e => setWithSalary(e.target.checked)} name="with_salary" type='checkbox' />
                    </div>

                     <p className='text-sm text-main'>Total Wage: <span className='font-bold text-red'>{data.balance?.toLocaleString()} Br. </span></p>
                     <p className='text-sm text-main'>Salary: <span className='font-bold text-red'>{data.salary?.toLocaleString()} Br.</span></p>
                    <div className='flex gap-2 items-center '>
                        {proof?.path && <>
                            <img onClick={e => window.open(e.target.src, '_blank')} style={{width: '40px', height: '40px', objectFit: 'contain'}} src={URL.createObjectURL(proof.blob)} />
                            <button onClick={() => setProof(null)}><Trash color='#ff0000' size={15} /></button>
                        </>}
                        <div className='flex flex-col gap-2 justify-center w-full'>
                            <p>Proof / Receipt</p>
                            <button type='button' onClick={() => setImageControl({visible: true, setImage: setProof})} className='py-2 px-8 max-md:px-2 font-bold text-[16px] bg-black text-white self-center w-full'>Take Image</button>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <p className='text-sm text-main'>Total to Pay: <span className='font-bold text-red'>{getTotal()?.toLocaleString()} Br. </span></p>
                    </div>
                    
                </div>
                <div className='flex justify-center gap-6'>
                    <button type="submit" className='py-2 px-8 font-bold text-[20px] bg-main text-white self-center' type='submit'>
                        <Loader text={'Pay & Clear'} loading={loading} />                        
                    </button>
                    <button onClick={() => setMakePayment()} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Cancel</button>
                </div>
            </form>
        </div>
    )
}