import { Link } from "react-router-dom";
import {Plus, Edit, Delete} from 'react-feather'
import { useContext, useEffect, useState } from "react";
import { appContext } from "../Context/appContext";
import { baseURL, MAIN_COLOR, path } from "../constants";
import { api } from "../helpers";
import Loader from "../Components/Loader";

export default function Employees(){
    const {toggleAEmployee, showAddEmployee, showMessage, setConfirm, toggleShowSalaryWage} = useContext(appContext)
    const [employees, setEmployees] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchEmployees = () => {
        setLoading(true)
        api.get(path.employee).then(response => {
            const data = response.data
            if(data.success){
                setEmployees(data.employees)
            } else showMessage('Something went wrong', true)
        }).catch(e => showMessage('Something went wrong', true))
        .finally(() => setLoading(false))
    }

    const deleteEmployee = (id) => {
        return function(){
            return api.delete(path.employee, {
                data: {id}}
            ).then(r => {
                setEmployees(m => m.filter(i => i.id != id))
                showMessage('Employee deleted', false)
            }).catch(e => showMessage('Something went wrong', true))
            .finally(() => setLoading(false))
        }
    }

    useEffect(() => {
        if(!showAddEmployee.visible) fetchEmployees()
    }, [showAddEmployee])
    
    return (
        <div className='flex flex-col flex-1 overflow-auto p-6 gap-6'>
        <button onClick={() => toggleAEmployee()} className='self-end bg-main px-4 py-2 rounded-sm font-bold  text-white flex items-center gap-2 hover:bg-darker transition-all border-none'>
            Add Employee
            <Plus color="#ffffff" size={18} />
        </button>
        <p className='text-[16px] uppercase font-bold'>All Employees</p>
        <div className='overflow-y-auto flex-1 rounded-md bg-white p-6 flex flex-col'>
        <table className='w-full'>
            <thead>
                <tr>
                    <th>
                        Full Name
                    </th>
                    <th>
                        Phone Number
                    </th>
                    <th>
                        Image
                    </th>
                    <th>
                        Wage Type
                    </th>
                    <th>
                        Event Wage
                    </th>
                    <th>
                        Afternoon Wage
                    </th>
                    <th>
                        Evening Wage
                    </th>
                    <th>
                        Salary
                    </th>
                    <th>
                        Guarantor Phone
                    </th>
                    <th>
                        Created At
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {employees.map(employee => (
                    <tr>
                        <td>{employee.full_name}</td>
                        <td>{employee.phone_number}</td>
                        <td><img onClick={e => window.open(e.target.src, '_blank')} className='cursor-pointer w-[30px] h-[30px] object-contain' src={baseURL + path.uploads + employee.personal_image} /></td>
                        <td>{employee.wage_type?.toUpperCase()}</td>
                        <td>{employee.event_wage}</td>
                        <td>{employee.afternoon_wage?.toLocaleString()}</td>
                        <td>{employee.evening_wage?.toLocaleString()}</td>
                        <td>{employee.salary?.toLocaleString()}</td>
                        <td>{employee.guarantor_phone}</td>
                        <td>{employee.createdAt?.split('T')[0]}</td>
                            <td className='flex items-center'>
                                <button onClick={() => toggleShowSalaryWage(employee)} className='px-4 py-2 bg-black border-none text-white max-md:text-xs rounded-md mr-4'>
                                    Salary & Wage
                                </button>
                                <button onClick={() => toggleAEmployee(employee)} className='border-none bg-none'>
                                    <Edit color='#000000' size={20} />
                                </button>
                                <button onClick={() => setConfirm({
                                    visible: true,
                                    action: deleteEmployee(employee.id)
                                    }) } className='border-none bg-none ml-3'>
                                    <Delete color="#ff0000" size={20} />
                                </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        {(!loading && !employees.length) && <h4 className='text-center mt-4'>No Data.</h4>}
        <Loader text={null} loading={loading} color={MAIN_COLOR} style={{marginTop: 20, alignSelf: 'center'}} />
        </div>
    </div>
    )
}