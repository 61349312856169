import { useContext, useEffect, useRef, useState } from "react"
import { appContext } from "../Context/appContext"
import Select from 'react-select'
import { api, convertEG } from "../helpers"
import { ETHIOPIAN_MONTHS, MAIN_COLOR, path } from "../constants"
import Loader from "./Loader"
import ETDatePicker from "./ETCalendar/ETDatePicker"
import ETTimePicker from "./ETCalendar/ETTimePicker"
import {Trash} from 'react-feather'
import PackageItems from "./PackageItems"

export const getEtDate = d => {
    const [e_year, e_month, e_date] = convertEG(new Date(d))
    return `${ETHIOPIAN_MONTHS[e_month - 1]} ${e_date}, ${e_year}`
}

export function convertTo24Hour(time12) {
    if(!time12) return null
    const [_,__,modifier] = time12;
    let [hours, minutes] = time12.slice(0, 2).map(Number);

    if (modifier === "PM" && hours !== 12) {
        hours += 12;
    } else if (modifier === "AM" && hours === 12) {
        hours = 0; // Midnight case
    }

    hours = String(hours).padStart(2, '0');
    minutes = String(minutes).padStart(2, '0');

    return `${hours}:${minutes}`;
}

export const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '50px', 
      height: '50px',  
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '50px',
      padding: '0 8px', 
    })
}

export default function ScheduleEvent(){
    const {
        toggleASchedule, showAddSchedule, setImageControl,
        showMessage, setShowAddSchedule, toggleSItems,
        setShowSelectItems, showSelectItems, settings, toggleAItems,
        showAdditionalItems, resetAItems, setShowAdditionalItems, t
    } = useContext(appContext)
    const [packages, setPackages] = useState([])
    const pkgRef = useRef(), brRef = useRef(), dateRef = useRef(), timeRef = useRef(),
    nopRef = useRef(), advRef = useRef(), adprRef = useRef(), adplRef = useRef(),
    discRef = useRef(), remRef = useRef(), paidRef = useRef()
    const [loading, setLoading] = useState(false)
    const todaymdy = new Date().toISOString().split('T')[0]
    const [date, setDate] = useState(todaymdy)
    const [time, setTime] = useState()
    const [etDate, setEtDate] = useState()
    const [etTime, setEtTime] = useState()
    const [showETDate, setShowETDate] = useState(false)
    const [showETTime, setShowETTime] = useState(false)
    const [idImage, setIdImage] = useState(null)
    const [selfImage, setSelfImage] = useState(null)
    const [showPackageItems, setShowPackageItems] = useState(null)
    const [selectedPackage, setSelectedPackage] = useState({})
    const [errors, setErrors] = useState({})
    const [clientInfo, setClientInfo] = useState({})
    const [branches, setBranches] = useState([])
    const [selectedBranch, setSelectedBranch] = useState({})
    const [packagesData, setPackagesData] = useState([])
    const [isFixed,setIsFixed] = useState(false)
    const [perPerson, setPerPerson] = useState('')

    const [numP, setNumP] = useState(1)
    const [adp, setAdp] = useState(0)
    const [discount, setDiscount] = useState(0)

    const selectETDate = (_et_date) => {
        setShowETDate(false)
        setEtDate(_et_date)
        let [yyyy, mm, dd] = convertEG(_et_date, true)
        dd = dd.toString().padStart(2, '0')
        mm = mm.toString().padStart(2, '0')
        setDate(`${yyyy}-${mm}-${dd}`)
    }

    const selectETTime = (gr_time, et_time) => {
        setEtTime(et_time)
        setTime(convertTo24Hour(gr_time))
        setShowETTime(false)
    }

    const populate_data = () => {
        api.get(path.package).then(response => {
            const data = response.data
            if(data.success){
                let _p = data.packages.map(p => ({label: p.name, value: p.id})).concat([{label: 'Custom', value: 'custom'}])
                setPackagesData(data.packages)
                setPackages(_p)
                setSelectedPackage(_p[0])
            }
        })
        api.get(path.branch).then(response => {
            const data = response.data
            if(data.success){
                let _b = data.branches.map(b => ({label: b.name, value: b.id}))
                setBranches(_b)
                setSelectedBranch(_b[0])
            }
        })
    }

    useEffect(() => {
        if(showAddSchedule.visible) populate_data()
    }, [showAddSchedule.visible])

    const calculate_total = () => {
        let price_range = packagesData.find(p => p.id == selectedPackage?.value)?.prices || [],
        total = 0
        if(selectedPackage?.value == 'custom'){
            total = numP * perPerson
        }
        else {
            for(let range of price_range){
                if(numP <= range.max && numP >= range.min){
                    total += numP * range.value
                    break
                }
            }
        }
        total += (parseFloat(adp) || 0)
        total -= (parseFloat(discount) || 0)
        return total
    }

    calculate_total()

    const scheduleEvent = e => {
        e.preventDefault()
        if(
            Object.keys(errors).map(e => errors[e]).some(e => e) ||
            !selectedBranch.value || !selectedPackage.value
        ) return showMessage('Please fix all errors', true)
        setLoading(true)
        let send_sms = e.target.send_sms.checked
        let send_schedule = e.target.send_schedule.checked
        api[showAddSchedule.data ? 'patch' : 'post'](path.event, {
            client: clientInfo,
            packageId: selectedPackage.value,
            event_date: date,
            time,
            et_date: convertEG(new Date(date)),
            // et_time: etTime,
            num_of_people: e.target.num_of_people.value,
            advance_amount: e.target.advance_amount.value,
            additional_plates: 0,
            additional_price: e.target.additional_price.value,
            discount_amount: e.target.discount_amount.value,
            custom_items: showSelectItems.items || [],
            additional_items: showAdditionalItems.items || [],
            remark: e.target.remark.value,
            per_person: e.target.per_person?.value,
            is_fixed: e.target.isFixed?.value,
            paid_full: e.target.paid_full.checked,
            send_sms, send_schedule,
            client_id: idImage?.path,
            client_image: selfImage?.path,
            branchId: selectedBranch.value,
            id: showAddSchedule.data?.id
        }).then(response => {
            const data = response.data
            if(data.success){
                setShowAddSchedule(false)
                setShowSelectItems({})
                resetAItems()
                if(showAddSchedule.data) showMessage('Event successfully updated!')
                else showMessage(`Event successfullly scheduled!${send_sms ? '\nClient will receive SMS ' : 'SMS will not be sent!'}`)
            }
        }).catch(e => {
            showMessage(e.message, true)
            setLoading(false)
        })
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        let _errors = {...errors}
        if(clientInfo.primary_phone){
            _errors = {..._errors, primary_phone: /^09\d{8}$/.test(clientInfo.primary_phone) ? null : 'Invalid phone format'}
        }
        if(clientInfo.secondary_phone){
            _errors = {..._errors, secondary_phone: /^09\d{8}$/.test(clientInfo.secondary_phone) ? null : 'Invalid phone format'}
        }
        setErrors(_errors)
    }, [clientInfo])

    useEffect(() => {
        if(showAddSchedule.data){
            let data = showAddSchedule.data
            console.log(data)
            setClientInfo({
                name: data?.client?.full_name,   
                primary_phone: data?.client?.phone_number,
                secondary_phone: data?.client?.secondary_phone
            })
            setSelectedPackage(!data.packageId ? {label: 'Custom', value: 'custom'} : packages.find(p => p.value == data.packageId))
            if(data.per_person) setPerPerson(data.per_person)
            setIsFixed(data.is_fixed)
            setShowAdditionalItems({...showAdditionalItems, items: data.additionalitems?.map(s => s.name) || []})
            if(dateRef.current) setDate(data?.event_date?.split('T')[0])
            if(timeRef.current) timeRef.current.value = data?.time
            if(nopRef.current) setNumP(data?.num_of_people)
            setSelectedBranch(branches.find(b => b.value == data.branchId))
            if(advRef.current) advRef.current.value = data?.advance_amount
            setShowSelectItems({...showSelectItems, items: data.items?.map(s => s.id) || []})
            if(adplRef.current) adplRef.current.value = data?.additional_plates
            if(adprRef.current) adprRef.current.value = data?.additional_price
            if(discRef.current) discRef.current.value = data?.discount_amount
            if(remRef.current) remRef.current.value = data?.remark
            if(paidRef.current) paidRef.current.checked = data?.paid_full
        }
    }, [showAddSchedule.data, packages, branches])

    return (
        <div className='fixed inset-0 flex justify-center bg-[#00000080] overflow-auto'>
            <form method="post" onSubmit={scheduleEvent} className='flex flex-col gap-4 p-4 max-md:p-0 bg-white p-6 rounded-md transition-[width 0.3s ease, height 0.3s ease] relative overflow-auto'>
                <h2 className="self-center text-[25px] font-bold ">{showAddSchedule.data ? t('EDIT_EVENT') : t('SCHEDULE_EVENT')}</h2>
                <div className='grid grid-cols-3 gap-4 max-md:grid-cols-2 feel-c1 bg-[#00000010] p-4 form'>
                    
                    <div className='flex flex-col gap-2'>
                        <div className='flex items-center gap-4 relative'>
                            <p>{t('CLIENT_NAME')}</p>
                            <div className='flex items-center gap-2 p-1 bg-[#00000090] max-md:hidden'>
                                {/* <input type='checkbox' name='save_client' checked /> */}
                                <p className='text-white text-[10px]'>{t('CLIENT_WI_SAVED')}</p>
                            </div>
                            {errors.name && <p className='text-red text-sm font-bold bottom-1'>{errors.name}</p>}
                        </div>
                        <input name="client_name" placeholder='Use Amharic' onChange={e => setClientInfo({
                            ...clientInfo,
                            name: e.target.value
                        })} value={clientInfo.name} type='text' className='input' required/>
                    </div>
                    <div className='flex flex-col gap-2 relative'>
                        <p>{t('PRIMARY_PHONE')}</p>
                        <input name="client_pr_phone" onChange={e => setClientInfo({
                            ...clientInfo,
                            primary_phone: e.target.value
                        })} value={clientInfo.primary_phone} type='text' className='input' required/>
                        {errors.primary_phone && <p className='text-red text-sm font-bold bottom-1'>{errors.primary_phone}</p>}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>{t('SECONDARY_PHONE')}</p>
                        <input  ame="client_sec_phone" onChange={e => setClientInfo({
                            ...clientInfo,
                            secondary_phone: e.target.value
                        })} value={clientInfo.secondary_phone} type='text' className='input' required/>
                        {errors.secondary_phone && <p className='text-red text-sm font-bold bottom-1'>{errors.secondary_phone}</p>}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>{t('PACKAGE')}</p>
                        <Select onChange={setSelectedPackage} options={packages} value={selectedPackage} styles={customStyles} name="packageId" required ref={pkgRef} />
                        {selectedPackage?.value == 'custom' ? <>
                            <button type='button' onClick={e => {
                                // let prices = packagesData?.find(p => p.id == selectedPackage.value)?.items?.map(i => i.id) || []
                                setShowSelectItems({...showSelectItems, visible: true})
                            }} className='py-2 px-8 font-bold text-[16px] bg-[#00000095] text-white'>{t('SELECT_ITEMS')} {showSelectItems.items?.length || ''}</button>
                                <div className='flex items-center gap-4'>
                                <input name="per_person" value={perPerson} onChange={e => setPerPerson(e.target.value)} placeholder={isFixed ? t('FIXED') : t('PER_PERSON')} type='text' className='input' required/>
                                <div className='flex items-center gap-2'>
                                    <label for='fixed' className='text-sm'>{t('FIXED')}</label>
                                    <input name='isFixed' onChange={(e) => setIsFixed(e.target.checked)} checked={isFixed} type='checkbox' id='fixed' />
                                </div>
                            </div>
                            </>
                            :
                            <button type='button' onClick={() => setShowPackageItems(selectedPackage.value)} className='bg-[#00000095] text-white font-bold px-4 py-2 text-sm'>{t('VIEW_PACK_ITEMS')}</button>
                        }
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>{t('EVENT_DATE')} (G.C)</p>
                        <input ref={dateRef} value={date} onChange={e => setDate(e.target.value)} name="event_date" type='date' className='input' required/>
                        <button type='button' onClick={() => setShowETDate(true)} className='bg-[#00000095] text-white font-bold px-4 py-2 text-sm'>{t('CONVERT_ET_CAL')}</button>
                        <p className='text-black text-sm border-[1px] border-black p-2 font-bold bottom-1 self-center'>{getEtDate(date)}</p>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>{t('EVENT_TIME')}</p>
                        <input ref={timeRef} name="event_time" type='time' value={time} onChange={e => setTime(e.target.value)} className='input' required/>
                        <button type='button' onClick={() => setShowETTime(true)} className='bg-[#00000095] text-white font-bold px-4 py-2 text-sm'>{t('CONVERT_ET_TIME')}</button>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>{t('PEOPLE')}</p>
                        <input disabled={isFixed} ref={nopRef} name="num_of_people" type='number' className='input' value={numP} onChange={e => setNumP(e.target.value)} required /> 
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>{t('BRANCH')}</p>
                        <Select onChange={setSelectedBranch} options={branches} value={selectedBranch} styles={customStyles} name="packageId" required ref={brRef} />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>{t('ADVANCE')}</p>
                        <input ref={advRef} name="advance_amount" defaultValue='0' type='number' className='input' required /> 
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>{t('ADDITIONAL_ITEMS')} ({showAdditionalItems.items?.length})</p>
                        <button type='button' onClick={e => {
                            toggleAItems()
                        }} className='py-2 px-8 font-bold text-[16px] bg-[#00000095] text-white'>{t('SELECT_ITEMS')}</button>
                    </div>
                    {showAddSchedule.data && <div className='flex flex-col gap-2'>
                        <p>{t('ADDITIONAL_PLATES')}</p>
                        <input ref={adplRef} name="additional_plates" defaultValue='0' type='number' className='input' /> 
                    </div> }
                    <div className='flex flex-col gap-2'>
                        <p>{t('ADDITIONAL_PRICE')}</p>
                        <input ref={adprRef} name="additional_price" defaultValue='0' type='number' value={adp} onChange={e => setAdp(e.target.value)} className='input' /> 
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>{t('DISCOUNT')}</p>
                        <input ref={discRef} name="discount_amount" defaultValue='0' type='number' value={discount} onChange={e => setDiscount(e.target.value)} className='input' /> 
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>{t('REMARK')}</p>
                        <textarea ref={remRef} name='remark' className='p-3'></textarea>
                    </div>
                    <div className='flex gap-1 items-center flex-col'>
                        <div className='flex items-center gap-2'>
                            <p>{t('FULL_PAID')}</p>
                            <input ref={paidRef} name="paid_full" type='checkbox' className='w-[20px] h-[20px]' /> 
                        </div>
                        <div className='flex items-center gap-2'>
                            <p>{t('SMS_CONFIRMATION')}</p>
                            <input name="send_sms" type='checkbox' className='w-[20px] h-[20px]' /> 
                        </div>
                        <div className='flex items-center gap-2'>
                            <p>{t('SMS_SCHEDULE')} ({settings?.days_before || 0} {t('DAYS')})</p>
                            <input name="send_schedule" type='checkbox' defaultChecked={!showAddSchedule.data} className='w-[20px] h-[20px]' /> 
                        </div>
                    </div>
                    <div className='flex items-center gap-4'>
                        <p className='text-lg font-bold total'>{t('TOTAL')}: <span className='text-red'>{calculate_total()?.toLocaleString()} {t('BR')}</span></p>
                    </div>
                    <div className='flex gap-2 items-center'>
                        {idImage?.path && <>
                            <img onClick={e => window.open(e.target.src, '_blank')} style={{width: '40px', height: '40px', objectFit: 'contain'}} src={URL.createObjectURL(idImage.blob)} />
                            <button onClick={() => setIdImage(null)}><Trash color='#ff0000' size={15} /></button>
                        </>}
                        <button type='button' onClick={() => setImageControl({visible: true, setImage: setIdImage})} className='py-2 px-8 font-bold text-[16px] bg-black text-white self-center w-full'>{t('CLIENT_ID_IMAGE')}</button>
                    </div>
                    <div className='flex gap-2 items-center'>
                        {selfImage?.path && <>
                            <img onClick={e => window.open(e.target.src, '_blank')} style={{width: '40px', height: '40px', objectFit: 'contain'}} src={URL.createObjectURL(selfImage.blob)} />
                            <button onClick={() => setSelfImage(null)}><Trash color='#ff0000' size={15} /></button>
                        </>}
                        <button type='button' onClick={() => setImageControl({visible: true, setImage: setSelfImage})} className='py-2 px-8 font-bold text-[16px] bg-black text-white self-center w-full'>{t('CLIENT_FACIAL_IMAGE')}</button>
                    </div>
                    {/* <div className='flex flex-col gap-2'>
                        <p>HZ Range</p>
                        <Select options={moodHZRangeOptions} styles={customStyles} name="hz_range" required ref={sRef} />
                    </div> */}
                </div>
                <div className='flex justify-center gap-6 max-md:p-4 max-md:grid max-md:grid-cols-2'>
                    <button type="submit" className='py-2 px-8 max-md:px-4 font-bold text-[20px] bg-main text-white self-center max-md:text-[15px] white-space-nowrap' type='submit'>
                        <Loader text={showAddSchedule.data ? t('UPDATE') : t('SAVE_EVENT')} loading={loading} />
                    </button>
                    <button onClick={() => {
                        toggleASchedule()
                        setShowSelectItems({})
                    }} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center max-md:text-[15px]' type='submit'>{t('CLOSE')}</button>
                </div>

                {loading && <div className='fixed inset-0 bg-[#00000095] flex items-center justify-center'>
                    <div className='flex flex-col bg-black gap-4 p-4 rounded-md items-center'>
                        <p className='text-white text-lg font-bold'>{showAddSchedule.data ? t('UPDATING_EVENT') + '...' :  t('SCHEDULING_EVENT') + '...'}</p>
                        <Loader loading={true} color={MAIN_COLOR} /> 
                    </div>
                </div> }
            </form>
            {showETDate && <ETDatePicker selectETDate={selectETDate} setShowETDate={setShowETDate} />}
            {showETTime && <ETTimePicker selectETTime={selectETTime} setShowETTime={setShowETTime} />}
            {showPackageItems && <PackageItems setShowItems={setShowPackageItems} packageId={showPackageItems} />}
           
    </div>
    )
}

