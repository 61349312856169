import { Link } from "react-router-dom";
import {Plus, Edit, Delete} from 'react-feather'
import { useContext, useEffect, useState } from "react";
import { appContext } from "../Context/appContext";
import { api } from "../helpers";
import { MAIN_COLOR, path } from "../constants";
import Loader from "../Components/Loader";

export default function Packages(){
    const {toggleAPackage, setConfirm, showAddPackage, showMessage, setShowSelectItems} = useContext(appContext)
    const [loading, setLoading] = useState(true)
    const [packages, setPackages] = useState([])

    const fetchPackages = () => {
        api.get(path.package).then(response => {
            const data = response.data
            console.log(data)
            if(data.success){
                setPackages(data.packages)
            } else showMessage('Error occured while fetching data', true)
        }).catch(e => showMessage('Error occured while fetching data', true))
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        if(!showAddPackage.visible) fetchPackages()
    }, [showAddPackage.visible])

    const deletePackage = (id) => {
        return function(){
            return api.delete(path.package, {
                data: {id}}
            ).then(r => {
                setPackages(p => p.filter(i => i.id != id))
                showMessage('Package deleted', false)
            }).catch(e => showMessage('Something went wrong', true))
            .finally(() => {
                setLoading(false)
                setShowSelectItems([])
            })
        }
    }
    return (
        <div className='flex flex-col flex-1 overflow-auto p-6 gap-6'>
        <button onClick={() => toggleAPackage()} className='self-end bg-main px-4 py-2 rounded-sm font-bold  text-white flex items-center gap-2 hover:bg-darker transition-all border-none'>
            Add Package
            <Plus color="#ffffff" size={18} />
        </button>
        <p className='text-[16px] uppercase font-bold'>All Packages</p>
        <div className='overflow-y-auto flex-1 rounded-md bg-white p-6 flex flex-col'>
        <table className='w-full'>
            <thead>
                <tr>
                    <th>
                        Package Name
                    </th>
                    <th>
                        Items
                    </th>
                    <th>
                        Pricing
                    </th>
                    <th>
                        Date Created
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {packages.map(pkg => (
                    <tr className='hover:bg-[#00000040] cursor-pointer'>
                        <td className='uppercase'>{pkg.name}</td>
                        <td className='max-w-[200px] whitespace-normal'><span className='text-red font-bold'>({pkg.items.length})</span> {pkg.items.map(i => i.name).join(', ')}</td>
                        <td className='pre-wrap'>
                            {pkg.prices.map(p => <p className='bg-cover px-3 py-1 rounded-md mt-2'>{`${p.min.toLocaleString()} - ${p.max.toLocaleString()} = ${p.value.toLocaleString()} ETB (per person)`}</p>)}
                        </td>
                        <td>{pkg.createdAt?.split('.')[0]}</td>
                        <td className=''>
                            <button onClick={() => toggleAPackage(pkg)} className='border-none bg-none'>
                                <Edit color="#000000" size={20} />
                            </button>
                            <button onClick={() => setConfirm({
                                visible: true,
                                action: deletePackage(pkg.id)
                                }) } className='border-none bg-none ml-3'>
                                <Delete size={20} color='#ff0000' />
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        {(!packages.length && !loading) && <h4 className='text-center mt-4'>No Data.</h4>}
        <Loader text={null} loading={loading} color={MAIN_COLOR} style={{marginTop: 20, alignSelf: 'center'}} />
        </div>
    </div>
    )
}