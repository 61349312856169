import { useContext, useState } from "react"
import { appContext } from "../Context/appContext"

export default function AddClient(){
    const {toggleAClient, showAddClient, setImageControl} = useContext(appContext)
    if(!showAddClient) return null
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000080]'>
            <form method="post" onSubmit={() => ''} className='flex flex-col gap-4 p-4 bg-white p-6 rounded-md'>
                <h2 className="self-center text-[25px] font-bold ">{false ? 'Edit Client' : 'Add Client'}</h2>
                <div className='grid  grid-cols-2 gap-6 feel-c1 bg-[#00000010] p-4'>
                    <div className='flex flex-col gap-2'>
                        <p>Full Name</p>
                        <input name="name" className='input' equired/>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>Phone Number</p>
                        <input name="description" className='input' required /> 
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>Secondary Phone Number</p>
                        <input name="description" className='input' required /> 
                    </div>
                    <div className='flex flex-col gap-2'>
                        <button onClick={() => setImageControl({visible: true})} className='py-2 px-8 font-bold text-[16px] bg-black text-white self-center' type='submit'>Select Image</button>
                    </div>
                </div>
                <div className='flex justify-center gap-6'>
                    <button type="submit" className='py-2 px-8 font-bold text-[20px] bg-main text-black self-center' type='submit'>{false ? 'Update' : 'Add Client'}</button>
                    <button onClick={toggleAClient} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Cancel</button>
                </div>
            </form>
    </div>
    )
}