import { Outlet, useLocation, Link } from "react-router-dom";
import SideBar from "../Components/Layout/SideBar";
import { animated } from '@react-spring/web'
import { Menu, Calendar, BookOpen, List } from 'react-feather';
import {
    TransitionGroup,
    CSSTransition
  } from "react-transition-group";
import { useContext, useEffect, useRef, useState } from "react";
import { appContext } from "../Context/appContext";
import { access, COMPANY_NAME, MAIN_COLOR } from "../constants";
import { authContext } from "../Context/authContext";

export default function AdminLayout({style}){
    const location = useLocation()
    const {showSidebar, setShowSidebar, isMobileDevice} = useContext(appContext)
    const [pwaPrompter, setPwaPrompter] = useState(false)
    const {user} = useContext(authContext)

    useEffect(() => {
        if (isMobileDevice() && !window.matchMedia('(display-mode: standalone)').matches) {
            setPwaPrompter(true)
        }
    }, [])
    return (
        <animated.div style={{...style}} className='admin-layout flex max-md:flex-col flex-1 gap-6 max-md:gap-0 bg-cover absolute w-full h-full'>
            <SideBar />
            <div className={`grid grid-cols-3 p-2 hidden max-md:grid`}>
                <button onClick={() => setShowSidebar(S => !S)} className='shadow-md w-[35px] h-[35px] bg-main text-white flex items-center justify-center rounded-md'>
                    <Menu size={22}  />
                </button>
                <h2 className='text-center text-[16px] tracking-wide'>{user?.tenant?.short_name}</h2>
            </div>
            <TransitionGroup>
                <CSSTransition
                    key={location.key}
                    classNames="fade"
                    timeout={500}
                    exit={false}
                >
                     <div className='p-6 pl-0 pb-0 max-md:p-2 flex flex-1 overflow-hidden flex-col'>
                            <Outlet />
                            <p className='text-center py-2 text-xs max-md:hidden'>EastByte Technologies &#169;2024 all rights reserved</p>
                            <BottomNav />
                            <div className={'fixed inset-0 bg-[#00000060] ' + (showSidebar ? 'fixed' : 'hidden')} onClick={() => setShowSidebar(S => !S)}></div>
                    </div>
                </CSSTransition>
            </TransitionGroup>
           {pwaPrompter && (
               <div className='fixed inset-0 bg-[#00000090] flex items-center justify-center flex-col z-[999999]'>
                   <div className='p-4 bg-white rounded-md shadow-lg flex flex-col items-center'>
                       <h3>You have to add this site to Home Screen</h3>
                       <p>To be able to use it on Mobile Devices.</p>
                   </div>
               </div>
           )}
        </animated.div>
    )
}

function BottomNav(){
    const location = useLocation()
    const pathname = location.pathname
    const { isAllowed, user} = useContext(authContext)
    const {t} = useContext(appContext)

    return (
        <div className='items-center hidden max-md:flex justify-evenly m-4 mb-6 bg-white p-1 rounded-[30px] shadow-md'>
            {isAllowed(access.CALENDAR) && <Link to='/' className='flex flex-col items-center gap-1'>
                <Calendar size={22} color={pathname == '/' ? MAIN_COLOR :  'black'} />
                <p className='text-xs text-black'style={pathname == '/' ? {color: MAIN_COLOR, fontWeight: 'bold'} : {}}>{t('CALENDAR')}</p>
            </Link> }
            {user.access?.some(acc => acc.startsWith('Cashbook')) && <Link to='/cashbook' className='flex flex-col items-center bg-main w-[40px] h-[40px] rounded-full justify-center scale-[1.3] translate-y-[-8px] shadow-lg'>
                <BookOpen size={25} color='white' />
            </Link> }
            {isAllowed(access.VIEW_EVENTS) && <Link to='/events' className='flex flex-col items-center'>
                <List size={22} color={pathname == '/events' ? MAIN_COLOR :  'black'} />
                <p className='text-xs text-black' style={pathname == '/events' ? {color: MAIN_COLOR, fontWeight: 'bold'} : {}}>{t('EVENTS')}</p>
            </Link> }
        </div>
    )
}