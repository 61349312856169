import { useContext, useEffect, useState } from "react"
import { MAIN_COLOR, path } from "../constants"
import { appContext } from "../Context/appContext"
import { api } from "../helpers"
import Loader from "./Loader"
import {Trash} from 'react-feather'

export default function AdditionalItems(){
    const {showAdditionalItems,
        resetAItems, addRmvAItem, toggleAItems} = useContext(appContext)
    const [name, setName] = useState(null)
    console.log(name)


    if(!showAdditionalItems.visible) return null
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000090]'>
            <div className='flex flex-col gap-4 p-4 bg-white p-6 rounded-md'>
                <h2 className="self-center text-[25px] font-bold ">{false ? 'Edit Items' : 'Add Additional Items'}</h2>
                <div className='grid  grid-cols-6 max-md:grid-cols-3 gap-6 max-md:gap-2 feel-c1 bg-[#00000010] p-4'>
                    {showAdditionalItems?.items?.map(item => (
                        <div className='flex items-center gap-2'>
                            <p className='text-[14px] select-none'>{item}</p>
                            <button onClick={(e) => {e.target = e.currentTarget; addRmvAItem(e) }} value={item}><Trash color='red' size={20} /></button>
                        </div>
                    ))}
                </div>
                <div className='flex justify-center p-4 gap-4'>
                    <input type='text' className='input max-w-[300px]' value={name} onChange={(e) => setName(e.target.value)} />
                    <button className='bg-main text-white text-md px-4 py-2 boder-none' value={name} id="add" onClick={e => {addRmvAItem(e); setName('') }}>Add</button>
                </div>
                <div className='flex justify-center gap-6'>
                    <button onClick={toggleAItems} className='py-2 px-8 font-bold text-[20px] bg-main text-white self-center max-md:text-sm' type='submit'>{`Done (${showAdditionalItems.items?.length || 0}) Items`}</button>
                    <button onClick={resetAItems} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center max-md:text-sm' type='submit'>Reset All</button>
                </div>
            </div>
    </div>
    )
}