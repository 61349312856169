import { Link } from "react-router-dom";
import {Plus, Edit, Delete} from 'react-feather'
import { useContext, useState, useEffect  } from "react";
import { appContext } from "../Context/appContext";
import { api, convertEG, getByPage } from "../helpers";
import { MAIN_COLOR, path, baseURL, ETHIOPIAN_MONTHS, access } from "../constants";
import Loader from "../Components/Loader";
import { getEtDate } from "../Components/ScheduleEvent";
import axios from "axios";
import { authContext } from "../Context/authContext";
import InfiniteScroll from 'react-infinite-scroll-component';

export function convertTo12HourFormat(time) {
    const [hour, minute] = time.split(':').map(Number);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;
    return `${hour12}:${minute.toString().padStart(2, '0')} ${ampm}`;
}

export function getTotal(event, perPerson=false){
    let _package = event.package, numP = event.num_of_people,
    adp = event.additional_price, discount = event.discount_amount,
    price_range = _package?.prices  || [], total = 0
    if(!_package){
        if(perPerson) return event.per_person
        total = numP * event.per_person
    }
    for(let range of price_range){
        if(numP <= range.max && numP >= range.min){
            if(perPerson) return range.value
            total += numP * range.value
            break
        }
    }
    total += (parseFloat(adp) || 0)
    total -= (parseFloat(discount) || 0)
    return total
}

export default function Events(){
    const {toggleASchedule, setConfirm, showMessage, showAddSchedule, toggleSEmployees, toggleSVendors, showSelectEmployees, showSelectVendors} = useContext(appContext)
    const [events, setEvents] = useState([])
    const [loading, setLoading] = useState(true)
    const [agreePrmompt, setAgreePrmompt] = useState(null)
    const {isAllowed} = useContext(authContext)
    const [selectedBranch, setSelectedBranch] = useState(null)
    const [branches, setBranches] = useState([])
    const [page, setPage] = useState(0)
    const [hasMore, setHasMore] = useState(true)
    const limit = 10
    console.log(page)
    
    const fetchEvents = (p) => {
        setHasMore(true)
        p == 1 && setEvents([])
        p == 1 && setPage(1)
        api.get(getByPage(path.event + '?branch=' + selectedBranch.id, p || page, true)).then(response => {
            const data = response.data
            console.log(data)
            if(data.success){
                if(data.events?.length) setEvents([...(p ? [] : events), ...data.events])
                setPage(p => p + 1)
                setHasMore(!(data.events.length < limit))
                console.log(p, data.events, page)
            } else showMessage('Something went wrong', true)
        }).catch(e => showMessage('Something went wrong', true))
        .finally(() => setLoading(false))
    }

    const deleteEvent = (id) => {
        return function(){
            return api.delete(path.event, {
                data: {id}}
            ).then(r => {
                setEvents(m => m.filter(i => i.id != id))
                showMessage('Event deleted', false)
            }).catch(e => showMessage('Something went wrong', true))
            .finally(() => setLoading(false))
        }
    }

    const fetchBranches = () => {
        api.get(path.branch + '?forEvent=1').then(response => {
            let data = response.data
            if(data.success){
                let brOptions = data.branches
                setBranches(brOptions)
                setSelectedBranch(brOptions[0])
            }
        })
    }

    useEffect(() => {
        if(!showAddSchedule.visible && !showSelectEmployees.visible && selectedBranch) fetchEvents(1)
    }, [showAddSchedule, showSelectEmployees, showSelectVendors, selectedBranch])

    // useEffect(() => )

    useEffect(fetchBranches, [])
    
    return (
        <div className='flex flex-col flex-1 overflow-auto p-6 gap-6'>
        {isAllowed(access.CREATE_EVENT) && <button onClick={() => toggleASchedule()} className='self-end bg-main px-4 py-2 rounded-sm font-bold  text-white flex items-center gap-2 hover:bg-darker transition-all'>
            Schedule Event
            <Plus color="#ffffff" size={18} />
        </button> }
        <div className='flex items-center gap-4'>
            <p className='text-[16px] uppercase font-bold'>Event History</p>
            <div className='flex rounded-[30px] bg-[#00000030] overflow-hidden shadow-md'>
                {branches.map(branch => (
                    <div onClick={() => setSelectedBranch(branch)} className={'px-6 py-2 max-md:px-3 flex-1 items-center flex cursor-pointer hover:scale-[1.1] transition-all ' + (selectedBranch?.id == branch.id && 'bg-[#00000095]')}>
                        <h2 className={'text-black text-sm max-md:text-xs  whitespace-nowrap font-bold select-none ' + (selectedBranch?.id == branch.id && 'text-white')}>{branch.name}</h2>
                    </div>
                ))}
                </div>
        </div>
        <div id="scrollable" className='overflow-auto flex-1 rounded-md bg-white max-w-[100%] p-6 pt-0 pl-0 flex flex-col'>
        <InfiniteScroll
            dataLength={events.length}
            next={fetchEvents}
            hasMore={hasMore}
            className='overflow-auto'
            loader={<div className='w-full flex justify-center'><Loader text={null} loading={hasMore} color={MAIN_COLOR} style={{marginTop: 20, alignSelf: 'center'}} /></div>}
            scrollableTarget="scrollable"
            endMessage={<p className='text-center m-4 text-sm'>No more data to load.</p>}
        >
        <table className='w-full overflow-auto relative bg-white mt-6 ml-6'>
            <thead className='relative'>
                <tr>
                    <th>
                        Event Date
                    </th>
                    <th>
                        Time
                    </th>
                    <th>
                        Branch
                    </th>
                    <th>
                        Package
                    </th>
                    <th>
                        Agreement
                    </th>
                    <th>
                        Assigned Employees
                    </th>
                    <th>
                        Client
                    </th>
                    <th>
                        Primary Contact
                    </th>
                    <th>
                        Secondary Contact
                    </th>
                    <th>
                        Number of People
                    </th>
                    <th>
                        Vendors
                    </th>
                    <th>
                        Custom Items
                    </th>
                    <th>
                        Additional Items
                    </th>
                    <th>
                        Additional Plates
                    </th>
                    <th>
                        Additional Price
                    </th>
                    <th>
                        Advance Payment
                    </th>
                    <th>
                        Total Amount
                    </th>
                    <th>
                        Fixed Price
                    </th>
                    <th>
                        Paid Full
                    </th>
                    <th>
                        Remark
                    </th>
                    <th>
                        Event Created At
                    </th>
                    <th>
                        Action
                    </th>
                </tr>
            </thead>
            <tbody>
                {events.map(event => (
                    <tr>
                        <td className='flex flex-col gap-2'>
                            <span className='px-4 py-1 bg-cover'>
                                G.C {event.event_date?.split('T')[0]}
                            </span>
                            <span className='px-4 py-1 bg-cover'>
                                {ETHIOPIAN_MONTHS[event.et_date.split('-')[1] - 1]} {event.et_date.split('-')[2]}, {event.et_date.split('-')[0]}
                            </span>
                        </td>
                        <td>{convertTo12HourFormat(event.time)}</td>
                        <td>{event.branch?.name}</td>
                        <td>{event.package?.name || 'Custom'}</td>
                        <td>
                            {isAllowed(access.GENERATE_AGREEMENT) ? <button
                            onClick={() => setAgreePrmompt(event)}
                            className='px-4 py-2 bg-black border-none text-white text-xs rounded-md'>View Agreement <br />{event.agreed_by ? 'By ' + event.agreed_by : ''} </button>
                            : 'No Permission'}
                        </td>
                        <td>
                        {isAllowed(access.ASSIGN_EMPLOYEES) ?
                            <button onClick={() => {
                                toggleSEmployees(event)
                            }} className='px-4 py-2 border-[1px] border-black bg-[transparent] text-black text-sm rounded-md'>Assigned Employees ({event.employees.length})</button>
                            : 'No Permission'}
                        </td>
                        <td className='sticky left-0'>{event.client?.full_name}</td>
                        <td>{event.client?.phone_number}</td>
                        <td>{event.client?.secondary_phone}</td>
                        <td>{event.num_of_people} ({getTotal(event, true)?.toLocaleString()} per-person)</td>
                        <td>
                        {isAllowed(access.ASSIGN_VENDORS) ?
                            <button onClick={() => {
                                toggleSVendors(event)
                            }} className='px-4 py-2 border-[1px] border-black bg-[transparent] text-black text-sm rounded-md'>Assigned Vendors ({event.vendors.length})</button>
                            : 'No Permission'}
                        </td>
                        <td className='max-w-[150px]'>{event.items?.map(i => <span className='text-sm ml-2 font-bold bg-black text-white p-1'>{i.name}</span>)}</td>
                        <td className='max-w-[150px]'>{event.additionalitems?.map(i => <span className='text-sm ml-2 font-bold bg-black text-white p-1'>{i.name}</span>)}</td>
                        <td>{event.additional_plates}</td>
                        <td>{event.additional_price?.toLocaleString()}</td>
                        <td>{event.advance_amount?.toLocaleString()}</td>
                        <td>{getTotal(event)?.toLocaleString()}</td>
                        <td>{event.is_fixed ? 'Yes' : 'No'}</td>
                        <td>{event.paid_full ? 'Yes' : 'No (left: ' + (getTotal(event) - event.advance_amount)?.toLocaleString() + ')' }</td>
                        <td className='max-w-[200px]'>{event.remark}</td>
                        <td>{event.createdAt?.split('.')[0]}</td>
                        <td className=''>
                            <button onClick={() => toggleASchedule(event)} className='border-none bg-none'>
                                <Edit color='#000000' size={20} />
                            </button>
                            {isAllowed(access.DELETE_EVENT) && <button onClick={() => setConfirm({
                                visible: true,
                                action: deleteEvent(event.id)
                                }) } className='border-none bg-none ml-3'>
                                <Delete color="#ff0000" size={20} />
                            </button> }
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        </InfiniteScroll>
        {/* {(!loading && !events.length) && <h4 className='text-center mt-4 self-center'>No Data.</h4>} */}
        {/* <Loader text={null} loading={loading} color={MAIN_COLOR} style={{marginTop: 20, alignSelf: 'center'}} /> */}
        </div>
        {agreePrmompt && <AgreementPrompt event={agreePrmompt} setAgreePrmompt={setAgreePrmompt} />}
    </div>
    )
}

function AgreementPrompt({event, setAgreePrmompt}){
    const [loading, setLoading] = useState(false)
    const submit = e => {
        e.preventDefault()
        setLoading(true)    
        let formData = new FormData(e.target)
        let formValues = {eventId: event.id}
        formData.forEach((value, key) => {
            formValues[key] = value
        })
        api.post(baseURL + path.generateAgreement, formValues).then(response => {
            setLoading(false)
            let nw = window.open('', '_blank')
            nw.document.write(response.data)
            nw.document.close()
        })
    }
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000080] z-[99999]'>
            <form onSubmit={submit} method="post" className='flex flex-col gap-4 p-4 bg-white p-6 rounded-md'>
                <h2 className='text-center'>Agreement Information</h2>
                <div className='grid grid-cols-3 gap-4 feel-c1 bg-[#00000010] p-4'>
                    <div className='flex flex-col gap-2'>
                        <p>ቀን</p>
                        <input defaultValue={convertEG(new Date()).join('-')} name="date" type='text' className='input' required /> 
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>ውል ሰጪ</p>
                        <input name="behalf" type='text' className='input' required autoFocus /> 
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>ውል ተቀባይ</p>
                        <input value={event.client?.full_name} name="client" type='text' className='input' required/> 
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>ስልክ ቁጥር</p>
                        <input value={event.client?.phone_number} name="client_phone" type='text' className='input' required/> 
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>የፕሮግራሙ ቀን</p>
                        <input value={event.et_date} name="event_date" type='text' className='input' required/> 
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>ቀበድ</p>
                        <input value={event.advance_amount?.toLocaleString()} name="advance" type='text' className='input' required/> 
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>ቀሪ</p>
                        <input value={(getTotal(event) - event.advance_amount)?.toLocaleString()} name="left" type='text' className='input' required/> 
                    </div>
                </div>
                <div className='flex justify-center gap-6'>
                    <button type="submit" className='py-2 px-8 font-bold text-[17px] bg-main text-white self-center' type='submit'>
                        <Loader text={'Generate Agreement Pages'} loading={loading} /> 
                    </button>
                    <button onClick={() => setAgreePrmompt(null)} className='py-2 px-8 font-bold text-[17px] border-main border-[2px] text-black self-center' type='submit'>Cancel</button>
                </div>
            </form>
        </div>
    )
}