import { useContext, useRef, useState, useEffect } from "react"
import { path } from "../constants"
import { appContext } from "../Context/appContext"
import { api } from "../helpers"
import Loader from "./Loader"

export default function AddBranch(){
    const {toggleABranch, showAddBranch, showMessage} = useContext(appContext)
    const [loading, setLoading] = useState(false)
    const nameRef = useRef()
    const addRef = useRef()

    const addOrEditBranch = (e) => {
        e.preventDefault()
        setLoading(true)
        api[showAddBranch.data ? 'patch' : 'post'](path.branch, {
            name: e.target.name.value,
            address: e.target.address.value,
            id: showAddBranch.data?.id
        }).then(response => {
            if(response.data.success) {
                toggleABranch()
                showMessage(`Branch successfully ${showAddBranch.data ? 'updated' : 'added'}!`, false)
            }
            else showMessage('Error has been occured!', true)
        })
        .catch(e => showMessage('Error has been occured!', true))
        .finally(() => setLoading(false))

    }

    useEffect(() => {
        if(nameRef.current) nameRef.current.value = showAddBranch.data?.name || ''
        if(addRef.current) addRef.current.value = showAddBranch.data?.address || ''
    }, [showAddBranch])

    if(!showAddBranch.visible) return null
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000080]'>
            <form method="post" onSubmit={addOrEditBranch} className='flex flex-col gap-4 p-4 bg-white p-6 rounded-md'>
                <h2 className="self-center text-[25px] font-bold ">{showAddBranch?.data ? 'Edit Branch' : 'Add Branch'}</h2>
                <div className='grid  grid-cols-2 gap-6 feel-c1 bg-[#00000010] p-4'>
                    <div className='flex flex-col gap-2'>
                        <p>Branch Name</p>
                        <input ref={nameRef} name="name" className='input' type='text' required/>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>Address</p>
                        <input ref={addRef} name="address" className='input' type='text' required /> 
                    </div>
                </div>
                <div className='flex justify-center gap-6'>
                    <button type="submit" className='py-2 px-8 font-bold text-[20px] bg-main text-white self-center' type='submit'>
                        <Loader text={showAddBranch.data ? 'Update' : 'Add Branch'} loading={loading} />                        
                    </button>
                    <button onClick={toggleABranch} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Cancel</button>
                </div>
            </form>
    </div>
    )
}