import { useContext, useEffect, useRef, useState } from "react"
import { baseURL, MAIN_COLOR, path } from "../constants"
import { appContext } from "../Context/appContext"
import date from 'date-and-time';
import { api, getByPage } from "../helpers"
import Loader from "./Loader"
import { Trash } from "react-feather";
import { authContext } from "../Context/authContext";
import Select from 'react-select'
import InfiniteScroll from 'react-infinite-scroll-component';


export default function VendorLedger(){
    const {toggleShowVLedger, showVLedger, showMessage} = useContext(appContext)
    const [payableLoading, setPayableLoading] = useState(false)
    const [paymentLoading, setPaymentLoading] = useState(false)
    const [outstanding, setOutstanding] = useState(0)
    const [payable, setPayable] = useState([])
    const [payment, setPayment] = useState([])
    const [makePayment, setMakePayment] = useState(false)
    const {user} = useContext(authContext)
    const [branches, setBranches] = useState([])
    const [branch, setBranch] = useState(null)
    const [payablePage, setPayablePage] = useState(0)
    const [paymentPage, setPaymentPage] = useState(0)
    const [hasMorePayable, setHasMorePayable] = useState(true)
    const [hasMorePayment, setHasMorePayment] = useState(true)

    const brRef = useRef()
    const dateOptions = { month: 'short', day: '2-digit', year: 'numeric' };
    const limit = 10
    const fetchLegder = (type, p) => {
        if(user.role == 'admin' && !branch.value) return
        type == 'in' ? setPayableLoading(true) : setPaymentLoading(true)
        if(!p){
            type == 'in' ? setHasMorePayable(true) : setHasMorePayment(true)
        }
        api.get(getByPage(path.vendorLedger + `?type=${type}&id=${showVLedger.data?.id}&branchId=${branch?.value}`, p || (type == 'in' ? payablePage : paymentPage), true))
        .then(response => {
            let data = response.data
            const hasMore = data.ledger.length < limit
            console.log(hasMore, type)
            if(type == 'in'){
                setPayable(dt => !p ? [...dt, ...data.ledger] : data.ledger)
                !p ? setPayablePage(p => p + 1) : setPayablePage(2)
                setHasMorePayable(!hasMore)
            } else {
                setPayment(dt => !p ? [...dt, ...data.ledger] : data.ledger)
                !p ? setPaymentPage(p => p + 1) : setPaymentPage(2)
                setHasMorePayment(!hasMore)
            }
            setOutstanding(data.balance)
        }).finally(() => {
            type == 'in' ? setPayableLoading(false) : setPaymentLoading(false)
        })
    }

    const fetchBranches = () => {
        api.get(path.branch).then(response => {
            let data = response.data
            if(data.success){
                let brOptions = data.branches.map(b => ({label: b.name, value: b.id}))
                setBranches(brOptions)
                setBranch(brOptions[0])
            }
        })
    }

    useEffect(() => {
        if(!branch) fetchBranches()
        if(user.role == 'admin' && !branch?.value) return
        if(showVLedger.data) ['in', 'out'].forEach(d => fetchLegder(d, 1))
    }, [showVLedger.data, branch])

    useEffect(() => {
        if(!showVLedger.visible){
            setPayable([])
            setPayment([])
        }
    }, [showVLedger.visible])
   

    if(!showVLedger.visible) return null  
    return (
        <div className='fixed inset-0 flex items-center max-md:items-start overflow-auto justify-center bg-[#00000080]'>
            <div method="post" className='flex flex-col gap-4 p-4 bg-white p-6 rounded-md relative'>
                {user.role == 'admin' && <div className='absolute top-[20px] right-[20px] flex items-center gap-2 z-[99999]'>
                    <p className='text-sm'></p>
                    <Select onChange={setBranch} options={branches} value={branch} styles={{}} className='text-sm' name="branch" required ref={brRef} />
                </div> }
                <h2 className="text-[22px] font-bold ">{'Ledger for ' + showVLedger.data?.name}</h2>
                {user?.role == 'admin' &&<p className='text-center text-xs bg-[#ff000020] text-[ff000080] p-1 rounded-sm'><b>Attention</b>: Since you are Admin, Every payment will be assigned to the selected branch</p>}
                <div className='flex gap-10 max-md:flex-col'>
                    <div id="scrollable1" className='flex flex-col gap-2 items-center max-h-[350px] overflow-auto'>
                        <h4 className='text-[17px] text-center'>Payable History</h4>
                        <InfiniteScroll
                            dataLength={payable.length}
                            next={() => fetchLegder('in')}
                            hasMore={hasMorePayable}
                            loader={<div className='w-full flex justify-center'><Loader text={null} loading={hasMorePayable} color={MAIN_COLOR} style={{marginTop: 20, alignSelf: 'center'}} /></div>}
                            scrollableTarget="scrollable1"
                            endMessage={<p className='text-center m-4 text-sm'>No more data to load.</p>}
                        >
                        <table className='relative overflow-auto w-full'>
                            <thead>
                                <tr className='static top-0'>
                                    <th className='px-4'>Amount</th>
                                    <th className='px-4'>Generated Time</th>
                                    <th className='px-4'>Event</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payable.map(pay => (
                                    <tr>
                                        <td className={'font-bold text-md text-[#ff0000]'}>{pay.amount?.toLocaleString()} Br.</td>
                                        <td>{new Date(pay.createdAt).toLocaleDateString('en-US', dateOptions)} |<span className='max-md:block'> {date.format(new Date(pay.createdAt), 'hh:mm:ss A')}</span></td>
                                        <td className='text-xs'>{`${pay.event?.client?.full_name} | ${pay.event?.et_date}`}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        </InfiniteScroll>
                        {/* {(!payableLoading && !payable.length) && <h4 className='text-center mt-4'>No Data.</h4>}
                        <Loader text={null} loading={payableLoading} color={MAIN_COLOR} style={{marginTop: 20}} /> */}
                    </div>

                    <div id="scrollable2" className='flex flex-col gap-2 items-center max-h-[350px] overflow-auto'>
                        <h4 className='text-[17px] text-center'>Payment History</h4>
                        <InfiniteScroll
                            dataLength={payment.length}
                            next={() => fetchLegder('out')}
                            hasMore={hasMorePayment}
                            loader={<div className='w-full flex justify-center'><Loader text={null} loading={hasMorePayment} color={MAIN_COLOR} style={{marginTop: 20, alignSelf: 'center'}} /></div>}
                            scrollableTarget="scrollable2"
                            endMessage={<p className='text-center m-4 text-sm'>No more data to load.</p>}
                        >
                        <table className='relative overflow-auto w-full'>
                            <thead>
                                <tr className='static top-0'>
                                    <th className='px-4'>Amount</th>
                                    <th className='px-4'>Time</th>
                                    <th className='px-4'>Receipt</th>
                                </tr>
                            </thead>
                            <tbody>
                            {payment.map(pay => (
                                    <tr>
                                        <td className={'font-bold text-md text-[#009100]'}>{pay.amount?.toLocaleString()} Br.</td>
                                        <td>{new Date(pay.createdAt).toLocaleDateString('en-US', dateOptions)} |<span className='max-md:block'> {date.format(new Date(pay.createdAt), 'hh:mm:ss A')}</span></td>
                                        <td>{pay.proof && <a className='text-[#0000ff] underline' href={baseURL + path.uploads + pay.proof} target='_blank'>View</a>}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </InfiniteScroll>
                        {/* {(!paymentLoading && !payment.length) && <h4 className='text-center mt-4'>No Data.</h4>}
                        <Loader text={null} loading={paymentLoading} color={MAIN_COLOR} style={{marginTop: 20}} /> */}
                    </div>
                </div>
                <div className='border-t-[1px] border-b-[1px] border-solid border-black py-4 flex justify-center'>
                    <h2>Outstanding Balance: <span className='text-red font-bold'>{parseFloat(outstanding)?.toLocaleString()} Br.</span></h2>
                </div>
                <div className='flex justify-center gap-6'>
                    <button onClick={() => setMakePayment(true)} className='py-2 px-8 font-bold text-[20px] bg-main text-white self-center'>
                        Make Payment
                    </button>
                    <button onClick={() => toggleShowVLedger()} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Cancel</button>
                </div>
            </div>
            {makePayment && <MakePayment data={{id: showVLedger.data?.id, balance: outstanding}} setMakePayment={setMakePayment} fetchLegder={fetchLegder} branch={branch} user={user} />}
        </div>
    )
}

function MakePayment({data = {}, setMakePayment, fetchLegder, user, branch}){
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState(data.balance || 0)
    const {setImageControl, showMessage} = useContext(appContext)
    const [proof, setProof] = useState({})
    const [remark, setRemark] = useState('')

    const processPayment = (e) =>  {
        e.preventDefault()
        if(user.role == 'admin' && !branch.value) return
        setLoading(true)
        api.patch(baseURL + path.vendorLedger + '?branchId=' + branch?.value, {
            amount,
            proof: proof.path,
            vendorId: data.id,
            remark
        }).then(response => {
            let data = response.data
            if(data.success){
                showMessage('Success!')
                fetchLegder('out', 1)
            }
        }).catch(e => showMessage('Error has been occured!', true))
        .finally(() => {
            setLoading(false)
            setMakePayment()
        })
    }

    // const takeAction = {
    //     pending: acceptPendingOrSettle
    // }

    return (
        <div className='fixed inset-0 flex items-center justify-center bg-[#00000080] z-[9999]'>
            <form method='post' onSubmit={processPayment} className='flex flex-col gap-4 p-4 bg-white p-6 max-md:p-2 max-md:py-4 rounded-md'>
                <h2 className="self-center text-[25px] font-bold max-md:text-[20px]">Make Payment</h2>
                <div className='grid grid-cols-3 max-md:grid-cols-1 gap-6 max-md:gap-4 feel-c1 bg-[#00000010] p-4'>
                    <div className='flex flex-col gap-2'>
                        <p>Amount</p>
                        <input value={amount} onChange={e => setAmount(e.target.value)} placeholder='Amount' name="amount" className='input' type='text' required/>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>Remark (Optional)</p>
                        <input value={remark} onChange={e => setRemark(e.target.value)} placeholder='Remark' name="remark" className='input' type='text' />
                    </div>

                     
                    <div className='flex gap-2 items-center '>
                        {proof?.path && <>
                            <img onClick={e => window.open(e.target.src, '_blank')} style={{width: '40px', height: '40px', objectFit: 'contain'}} src={URL.createObjectURL(proof.blob)} />
                            <button onClick={() => setProof(null)}><Trash color='#ff0000' size={15} /></button>
                        </>}
                        <div className='flex flex-col gap-2 justify-center w-full'>
                            <p>Proof / Receipt</p>
                            <button type='button' onClick={() => setImageControl({visible: true, setImage: setProof})} className='py-2 px-8 max-md:px-2 font-bold text-[16px] bg-black text-white self-center w-full'>Take Image</button>
                        </div>
                    </div>
                    
                </div>
                <div className='flex justify-center gap-6'>
                    <button type="submit" className='py-2 px-8 font-bold text-[20px] bg-main text-white self-center' type='submit'>
                        <Loader text={'Pay'} loading={loading} />                        
                    </button>
                    <button onClick={() => setMakePayment()} className='py-2 px-8 font-bold text-[20px] border-main border-[2px] text-black self-center' type='submit'>Cancel</button>
                </div>
            </form>
        </div>
    )
}